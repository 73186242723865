export const publisher = {
    state: () => ({
        config: null
    }),
    actions: {
        // silence is golden
    },
    mutations: {
        /**
         *
         * @param state
         * @param config
         */
        setConfig: (state,config) => {
            state.config = config;
        }
    },
    getters: {
        /**
         *
         * @param state
         * @returns {*}
         */
        getConfig: state => {
            return state.config;
        }
    }
}