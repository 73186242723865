/**
 *
 * @param targetOrigin
 * @param integration
 */
function postMessageHeight(targetOrigin = null,integration = null) {
    if(targetOrigin && integration === "iframe") {
        setTimeout(function () {
            const body = document.body,
                html = document.documentElement;

            const height = Math.max(
                body.scrollHeight,
                body.offsetHeight,
                html.clientHeight,
                html.scrollHeight,
                html.offsetHeight
            );

            window.parent.postMessage(
                { height },
                targetOrigin
            );
        },100);
    }
}

export { postMessageHeight }