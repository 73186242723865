export default {
    data() {
        return {
            topScore: "🔥",
            mandatory: {
                "A": "Ⓐ",
                "B": "Ⓑ",
                "C": "Ⓒ",
                "D": "Ⓓ",
                "E": "Ⓔ",
                "F": "Ⓕ",
                "G": "Ⓖ",
                "H": "Ⓗ",
                "I": "Ⓘ",
                "J": "Ⓙ",
                "K": "Ⓚ",
                "L": "Ⓛ",
                "M": "Ⓜ",
                "N": "Ⓝ",
                "O": "Ⓞ",
                "P": "Ⓟ",
                "Q": "Ⓠ",
                "R": "Ⓡ",
                "S": "Ⓢ",
                "T": "Ⓣ",
                "U": "Ⓤ",
                "V": "Ⓥ",
                "W": "Ⓦ",
                "X": "Ⓧ",
                "Y": "Ⓨ",
                "Z": "Ⓩ",
            },
            letters: {
                "A": "🅐",
                "B": "🅑",
                "C": "🅒",
                "D": "🅓",
                "E": "🅔",
                "F": "🅕",
                "G": "🅖",
                "H": "🅗",
                "I": "🅘",
                "J": "🅙",
                "K": "🅚",
                "L": "🅛",
                "M": "🅜",
                "N": "🅝",
                "O": "🅞",
                "P": "🅟",
                "Q": "🅠",
                "R": "🅡",
                "S": "🅢",
                "T": "🅣",
                "U": "🅤",
                "V": "🅥",
                "W": "🅦",
                "X": "🅧",
                "Y": "🅨",
                "Z": "🅩",
            }
        }
    },
    methods: {
        /**
         *
         * @param nr
         * @param words
         * @param points
         * @param mandatory
         * @param letter
         * @param isograms
         * @param topScore
         * @returns {string}
         */
        buildShareText(nr,words,points,mandatory,letter,isograms,topScore) {
            return "Rätsel #"
                + nr
                + ": " //...
                + points + " Punkte mit "
                + words + " Wörtern erreicht"
                + (topScore ? " (" + this.topScore + " Top-Score)" : "")
                + (isograms > 0 ? " und " + isograms + " Isogramm" + (isograms === 1 ? "" : "e") + " entdeckt" : "")
                + "\n\n"
                + this.buildEmojiSquares(mandatory,letter) + "\n"
                + this.$config.common.domain + " - " + this.$config.name
        },
        /**
         *
         * @param mandatory
         * @param letter
         * @returns {string}
         */
        buildEmojiSquares(mandatory,letter) {
            const copy = [...mandatory];
            const shuffled = [...this.shuffle(copy)];
            return "   " + this.emoji(shuffled[0]) + "  " + this.emoji(shuffled[1]) + "\n"
                + this.emoji(shuffled[2]) + "  " + this.emoji(letter,"letter") + "  " + this.emoji(shuffled[3]) + "\n"
                + "   " + this.emoji(shuffled[4]) + "  " + this.emoji(shuffled[5]) + "\n";
        },
        /**
         *
         * @param code
         * @param type
         * @returns {*}
         */
        emoji(code,type = "mandatory") {
            const letter = String.fromCharCode(code);
            return type === "mandatory" ? this.mandatory[letter] : this.letters[letter];
        },
        /**
         *
         * @param nr
         * @param words
         * @param points
         * @param mandatory
         * @param letter
         * @param isograms
         * @param topScore
         */
        shareResult(nr,words,points,mandatory,letter,isograms = 0,topScore = false) {
            const data = {
                title: this.$config.name,
                text: this.buildShareText(nr,words,points,mandatory,letter,isograms,topScore)
            };

            if(this.$store.getters.canShare) {
                navigator.share(data)
                    .then(() => {
                        // Success share
                        this.event("ShareResult",{
                            game_nr: nr,
                            count_words: words,
                            top_score: topScore,
                            count_isograms: isograms
                        });
                    })
                    .catch(error => {
                        // console.log(error);
                        this.$store.dispatch("showToast",{
                            msg: "Ergebnis kopiert!",
                            type: "success"
                        });
                    });

                this.copyToClipboard(data.text,false);
            }
            else {
                this.copyToClipboard(data.text);
            }
        },
        /**
         *
         * @param text
         * @param toast
         */
        copyToClipboard(text,toast = true) {
            navigator.clipboard.writeText(text).then(() => {
                if(toast === true) {
                    this.$store.dispatch("showToast",{
                        msg: "Ergebnis kopiert!",
                        type: "success"
                    });
                    this.event("CopyResult");
                }
            }, function(err) {
                // console.error('Async: Could not copy text: ', err);
            });
        },
    }
}
