<template>
    <PaywallIcon v-if="paywall.enabled && paywall.icon" :classes="svgClasses"></PaywallIcon>
    <icon v-else icon="lock-closed" :classes="iconClasses"></icon>
</template>

<script>
import Icon from "./Icon.vue";
import PaywallIcon from "./PaywallIcon.vue";

export default {
    components: {
        PaywallIcon,
        Icon
    },
    data() {
        return {
            paywall: this.$config.paywall,
        }
    },
    props: {
        svgClasses: {
            type: Array,
            default: ["w-4","h-4","fill-current"]
        },
        iconClasses: {
            type: String,
            default: "w-4 h-4"
        }
    }
}
</script>