<template>
    <template v-if="withAuthentication">
        <router-link v-if="isAuth && !isSynced" :to="{name:'Sync'}" title="Spieldaten synchronisieren" class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:text-gray-500 dark:hover:bg-gray-700">
            <icon icon="exclamation-triangle" classes="h-6 w-6 text-orange-500"></icon>
        </router-link>
        <a v-if="useSSO && !isAuth" :href="login_url" @click="handleLogin($event,$refs.modal)" title="Login" :class="icnClass" id="pub-sso-login">
            <AuthIconSvg :is-auth="isAuth"></AuthIconSvg>
        </a>
        <router-link v-else :to="{name: !isAuth ? 'Login' : 'Account'}" :title="!isAuth ? 'Login' : 'Zum Profil'" :class="icnClass">
            <AuthIconSvg :is-auth="isAuth"></AuthIconSvg>
        </router-link>
        <modal-sso v-if="modal.use" :modalId="modal.id" :loginUrl="login_url" ref="modal"></modal-sso>
    </template>
</template>

<script>
import ModalSso from "./modal-sso.vue"
import AuthIconSvg from "./AuthIconSvg.vue";
import Icon from "./Icon";

export default {
    name: "AuthIcon",
    components: {
        Icon,
        AuthIconSvg,
        ModalSso
    },
    computed: {
        /**
         *
         * @returns {*}
         */
        isAuth() {
            return this.$store.getters["auth/isAuth"];
        },
        /**
         *
         * @returns {*}
         */
        isSynced() {
            return this.$store.getters["auth/isSynced"];
        }
    },
    data() {
        return {
            withAuthentication: this.$config.common["with_authentication"],
            useSSO: this.$config.sso.custom,
            modal: {
                use: this.$config.sso.iframe,
                id: "sso-authentication"
            },
            login_url: this.getSSOLoginUrl(),
            icnClass: "text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center relative dark:text-gray-500 dark:hover:bg-gray-700"
        }
    },
    mounted() {
        window.addEventListener("login:modal", (event) => {
            this.handleLogin(event,this.$refs.modal);
        });
    },
    methods: {
        handleLogin(e,$ref = null) {
            if(!this.isAuth && this.modal.use) {
                e.preventDefault();
                $ref.openModal();
            }
        }
    },
    unmounted() {
        window.removeEventListener("login:modal", (event) => {
            this.handleLogin(event,this.$refs.modal);
        });
    }
}
</script>
