<template>
    <ul v-if="enabled" class="space-y-6 pt-6 border-l border-slate-100 dark:border-slate-600">
        <li>
            <router-link v-if="!requiresAuth || store.getters['auth/isAuth']" :class="$route.name === 'Archive' || $route.query.date ? 'active' : null" :to="{name:'Archive'}" @click.native="handleCallback" class="item">Archiv</router-link>
            <span v-else class="item !inline-flex cursor-pointer" @click="handleBlocked">
                Archiv
                <LockedIcon :svg-classes="['w-5','h-5','fill-current','ml-2']" icon-classes="h-5 w-5 ml-2 text-gray-400"></LockedIcon>
            </span>
        </li>
    </ul>
</template>

<script>
import LockedIcon from "./LockedIcon.vue";

export default {
    name: "ArchiveNav",
    components: { LockedIcon },
    emits: ["callback"],
    data() {
        return {
            store: this.$store,
            enabled: this.$config.setup.features["archive"] === true,
            requiresAuth: !!this.$config.common["with_authentication"]
        }
    },
    methods: {
        handleCallback() {
            window.dispatchEvent(new CustomEvent("feature:blocked",{
                detail: {
                    blocked: false
                }
            }));
            this.$emit("callback");
        },
        handleBlocked() {
            window.dispatchEvent(new CustomEvent("feature:blocked",{
                detail: {
                    blocked: true
                }
            }));
        }
    }
}
</script>