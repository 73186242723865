import { debug } from "../debug";

export default {
    data() {
        return {
            setupFuncString: process.env.PRIVACY_SETUP_FUNC !== "" ? process.env.PRIVACY_SETUP_FUNC : null,
            setupFunc: () => {
                if(this.setupFuncString !== null) {
                    try {
                        eval(this.setupFuncString);
                    } catch (error) {
                        debug(error,"warn");
                    }
                }
                else {
                    debug("Privacy function is empty!","info");
                }
            }
        }
    },
    methods: {
        showPrivacySetup() {
            this.setupFunc();
        },
        /**
         *
         * @returns {boolean}
         */
        hasPrivacyFunc() {
            return this.setupFuncString !== null;
        }
    }
}