import axios from "axios"
import merge from "lodash/merge"
import { debug } from "../debug"
import { buildRequest, buildURLWithParams, getCookie, removeCookie, setCookie } from "../connector-request"

export const auth = {
    namespaced: true,
    state: () => ({
        auth: false,
        online: true,
        pending: false,
        schema: null,
        synced: true,
        token: null,
        user: null,
        storage: null,
        verified: false,
    }),
    actions: {
        /**
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @param rootGetters
         * @returns {Promise<unknown>}
         */
        checkAuth: ({commit,dispatch,getters,rootGetters}) => {
            return new Promise(async (resolve, reject) => {
                const config = rootGetters.getConfig;

                if (!config.common.with_authentication || !config.hasOwnProperty("identifier")) {
                    resolve(null);
                }

                /**
                 * TODO: Can delete after a while.
                 */
                await dispatch("migrateTokenLocalStorageToCookie",config.identifier + "-user-token");

                const key = !config.sso.custom ? config.identifier + "-token" : config.sso.auth_token_key;
                const token = getCookie(key);

                /**
                 * Check if sso auth type is token and then if token exists.
                 * Otherwise, de-register user.
                 * The user must log in on the parent page.
                 * After reload or comeback the cookie should exist.
                 */
                if (config.sso.auth_method !== "session" && !token) {
                    dispatch("unregister");
                    resolve("Unauthenticated");
                }
                /**
                 * Else if user isn't authenticated
                 */
                else if (!getters.isAuth && !getters.isPending) {
                    commit("setPending",true);

                    try {
                        await axios.post(
                            buildURLWithParams(config.sso.auth_url),
                            {
                                stats: localStorage.getItem(config.identifier + "-user-stats"),
                                games: localStorage.getItem(config.identifier + "-user-games")
                            },
                            buildRequest(token,config.sso.auth_scheme,!config.sso.custom && config.sso.auth_method !== "jwt")
                        )
                            .then(async (response) => {
                                await dispatch("setSynced",!config.sso.sync_custom_url ? response.data?.data?.synced : true);

                                /**
                                 * Wenn auth_status_key_val null ist, benötigen wir keine weitere Prüfung.
                                 * Die Authentifizierung ist erfolgreich.
                                 *
                                 */
                                if(!config.sso.auth_status_key_val) {
                                    await dispatch("processingRawResponse",response.data)
                                        .then(data => {
                                            data.token = token;
                                            dispatch("register",data);
                                        })
                                    resolve(getters.isSynced ? true : "Not synchronous");
                                }
                                /**
                                 * Die Konditionen für eine Authentifizierung müssen geprüft werden.
                                 */
                                else {
                                    let conditions = [];

                                    for (let key in config.sso.auth_status_key_val) {
                                        const value = (key || "").split(".").reduce((a, prop) => a[prop], response.data);
                                        const isObject = (typeof value === 'object');

                                        if (!isObject) {
                                            conditions.push({
                                                value: value.toString() ?? null,
                                                check: config.sso.auth_status_key_val[key],
                                                mandatory: true
                                            });
                                        } else {
                                            for (let objKey in value) {
                                                conditions.push({
                                                    value: value[objKey].toString() ?? null,
                                                    check: config.sso.auth_status_key_val[key],
                                                    mandatory: false
                                                });
                                            }
                                        }
                                    }

                                    await dispatch("processingConditions", conditions)
                                        .then(() => {
                                            dispatch("processingRawResponse", response.data)
                                                .then(data => {
                                                    data.token = token;
                                                    dispatch("register",data)
                                                })
                                            resolve(getters.isSynced ? true : "Not synchronous");
                                        })
                                        .catch(error => {
                                            debug(error,"warn");
                                            dispatch("unregister");
                                            resolve("Unauthenticated!");
                                        });
                                }
                            })
                            .catch(error => {
                                debug(error,"warn");
                                dispatch("unregister");
                                reject(config.debug ? error : false);
                            })
                            .finally(() => {
                                commit("setPending",false);
                            });
                    } catch (error) {
                        debug(error,"warn");
                        commit("setPending", false);
                        resolve("Timeout");
                    }
                } else {
                    resolve(getters.isSynced ? true : "Not synchronous");
                }
            });
        },
        /**
         *
         * @param rootGetters
         * @param key
         * @returns {boolean|void}
         */
        migrateTokenLocalStorageToCookie: ({rootGetters},key) => {
            const config = rootGetters.getConfig;

            if (!config.hasOwnProperty("identifier")) {
                return false;
            }

            const value = localStorage.getItem(key);

            if(value) {
                setCookie(config.identifier + "-token",value);
                localStorage.removeItem(key);
            }
        },
        /**
         *
         * @param dispatch
         * @param conditions
         * @returns {Promise<unknown>}
         */
        processingConditions: ({dispatch},conditions) => {
            return new Promise(async (resolve,reject) => {

                let matches = 0;

                const notMandatory = conditions.filter((obj) => obj.mandatory === false).length;

                for(const condition of conditions) {

                    /**
                     * Check if the value to be checked must end with the actual value
                     * Wildcard (*) at the beginning
                     */
                    const ends_with = Array.from(condition.check)[0] === '*';

                    /**
                     * Check if the value to be checked must starts with the actual value
                     * Wildcard (*) at the end
                     */
                    const starts_with = condition.check.slice(-1) === '*';

                    await dispatch("processingCondition",{
                        ends_with: ends_with,
                        starts_with: starts_with,
                        value: condition.value,
                        check: condition.check
                    })
                        .then(() => {
                            if(!condition.mandatory) {
                                matches++
                            }
                        })
                        .catch(error => {
                            if(condition.mandatory) {
                                reject(error);
                            }
                        });
                }

                if(matches === 0 && notMandatory > 0) {
                    reject("No condition has matched!");
                }
                else {
                    resolve(true);
                }

            });
        },
        /**
         *
         * @param dispatch
         * @param condition
         * @returns {Promise<unknown>}
         */
        processingCondition: ({dispatch},condition) => {
            return new Promise((resolve,reject) => {
                if(condition.ends_with && !condition.starts_with) {
                    if(!condition.value.endsWith(condition.check.slice(1,condition.check.length))) {
                        reject("Don't end with value!");
                    }
                }
                else if(condition.ends_with && condition.starts_with) {
                    if(!condition.value.endsWith(condition.check.slice(2,condition.check.length-1))) {
                        reject("Don't include value!");
                    }
                }
                else if(!condition.ends_with && condition.starts_with) {
                    if(!condition.value.startsWith(condition.check.slice(0,condition.length-1))) {
                        reject("Don't start with value!");
                    }
                }
                else if(condition.value !== condition.check) {
                    reject("Don't match with value!");
                }

                resolve(true);

            });
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param response
         * @param data
         * @returns {Promise<unknown>}
         */
        signup: ({commit,dispatch},response) => {
            return new Promise((resolve,reject) => {
                dispatch("processingRawResponse", response)
                    .then(data => {
                        dispatch("register",data).then(() => resolve(true));
                    })
                    .catch(error => reject(error));
            });
        },
        /**
         *
         * @param dispatch
         * @param getters
         * @param rootGetters
         * @param response
         * @returns {Promise<unknown>}
         */
        login: ({dispatch,getters,rootGetters},response) => {
            return new Promise(async (resolve,reject) => {
                const config = rootGetters.getConfig;
                const next = new URLSearchParams(window.location.search).get("next");

                if(!config.hasOwnProperty("identifier")) {
                    reject("Object 'config' has no property 'identifier'!");
                }

                await dispatch("setSynced",!config.sso.sync_custom_url ? response.data.synced : true);

                await dispatch("saveToLocalStorage",{
                    key: "user-synced",
                    val: getters.isSynced ? "true" : "false"
                });

                await dispatch("processingRawResponse",response)
                    .then(data => dispatch("register",data))
                    .catch(() => reject("Response could not be processed!"));

                dispatch("showToast",{
                    msg: response.message,
                    type: "success"
                },{root: true});
                dispatch("subscription/afterLogin",{root:true});

                if (next && getters.isSynced) {
                    resolve(next);
                } else if (!getters.isSynced) {
                    resolve({name: "Sync"});
                } else {
                    resolve({name: "Home"});
                }
            });
        },
        /**
         *
         * @param rootGetters
         * @param rawResponse
         * @returns {Promise<unknown>}
         */
        processingRawResponse: ({rootGetters},rawResponse) => {
            return new Promise((resolve) => {
                const config = rootGetters.getConfig;

                const props = {
                    id: (config.sso?.auth_id_key || "").split("."),
                    email: (config.sso?.auth_email_key || "").split("."),
                    username: (config.sso?.auth_username_key || "").split("."),
                };

                resolve({
                    user: {
                        id: props.id.reduce((a, prop) => a[prop], rawResponse) ?? null,
                        email: props.email.reduce((a, prop) => a[prop], rawResponse) ?? null,
                        username: props.username.reduce((a, prop) => a[prop], rawResponse) ?? null,
                        verified: !config.sso?.custom ? rawResponse.data?.user["email_verified_at"] : true,
                        notification: !config.sso?.custom ? rawResponse.data?.user["notification"] : false,
                    },
                    // storage: !config.sso?.custom ? rawResponse.data?.user?.storage : null,
                    storage: !config.sso?.sync_custom_url ? rawResponse.data?.user?.storage : null,
                    token: !config.sso?.custom && config.sso?.auth_method !== "session" ? rawResponse.data?.token : null,
                });
            });
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param rootGetters
         * @param user
         * @param storage {null|object}
         * @param token {null|string}
         * @returns {Promise<unknown>}
         */
        register: ({commit, dispatch, rootGetters}, {user, storage = null, token = null}) => {
            return new Promise((resolve,reject) => {
                const config = rootGetters.getConfig;

                if(!config.hasOwnProperty("identifier")) {
                    reject("Object 'config' has no property 'identifier'!");
                }

                commit("setAuth",true);
                commit("verify",user?.verified !== null);
                commit("setToken",config.sso.auth_method !== "session" ? token : null);

                if(config.sso.auth_method !== "session" && token && !config.sso.custom) {
                    setCookie(config.identifier + "-token",token);
                }

                commit("setUser",user);
                dispatch("processStorage",storage)
                    .then(ignore => {
                        window.dispatchEvent(new CustomEvent("user:auth"));
                        resolve(true)
                    })
                    .catch(error => reject(error))
            });
        },
        /**
         *
         * @param commit
         * @param rootGetters
         */
        unregister: ({commit,rootGetters}) => {
            return new Promise((resolve,reject) => {
                const config = rootGetters.getConfig;

                if(!config.hasOwnProperty("identifier")) {
                    reject("Object 'config' has no property 'identifier'!");
                }

                commit("setAuth",false);
                commit("setToken",null);
                commit("setUser",null);
                removeCookie(config.identifier + "-token");

                resolve(true);
            });
        },
        /**
         *
         * @param commit
         * @param dispatch
         */
        logout: ({dispatch}) => {
            return new Promise((resolve,reject) => {
                dispatch("unregister").then(() => {
                    dispatch("subscription/afterLogout",{root: true});
                    resolve(dispatch("showToast", {
                        msg: "Erfolgreich ausgeloggt.",
                        type: "success"
                    },{root: true}))
                }).catch(error => reject(error));
            });
        },
        /**
         *
         * @param dispatch
         * @param getters
         * @param rootGetters
         */
        delete: ({dispatch,getters,rootGetters}) => {
            const config = rootGetters.getConfig;

            axios.delete(buildURLWithParams(config.sso.delete_account_url),buildRequest(getters.getToken,config.sso.auth_scheme))
                .then(response => {
                    dispatch("showToast",{
                        msg: response.data.message,
                        type:"success"
                    },{root: true});
                })
                .catch(() => {
                    dispatch("showToast",{
                        msg: this.$config.content.messages.error,
                        type: "warn"
                    },{root: true});
                })
                .finally(()=> {
                    dispatch("unregister");
                    router.push({
                        name: "Home"
                    });
                });
        },
        /**
         *
         * @param commit
         * @param dispatch
         */
        verify: ({commit,dispatch}) => {
            commit("verify",1);
            dispatch("update",{
                key: "verify",
                val: 1
            });
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @param key
         * @param val
         */
        update: ({commit,dispatch,getters},{key,val}) => {
            commit("updateUser",{
                key: key,
                val: val
            });
        },
        /**
         * @param commit
         * @param dispatch
         * @param status
         * @returns {Promise<void>}
         */
        setSynced: async ({commit,dispatch},status= true) => {
            if(status === true) {
                await dispatch("saveToLocalStorage",{
                    key: "user-synced",
                    val: "true"
                });
            }
            commit("setSynced",status);
        },
        /**
         *
         * @param getters
         * @param dispatch
         * @param rootGetters
         * @param payload {null|object}
         * @returns {Promise<unknown>}
         */
        sync: ({getters,dispatch,rootGetters},payload = null) => {
            return new Promise((resolve,reject) => {
                const config = rootGetters.getConfig;

                /**
                 * Wenn keine URL zur Synchronisation vorhanden ist,
                 * dann geht's irgendwie nicht.
                 */
                if(!config.sso.sync_url && !config.sso.sync_custom_url) {
                    reject("No storage URL available!");
                }
                /**
                 * der Nutzer ist eingeloggt
                 * und es besteht eine Internetverbindung.
                 */
                else if(/*getters.isSynced === true && */getters.isAuth && getters.hasNetworkConnection) {
                    const url = config.sso.sync_custom_url ?? (config.sso.sync_url + (config.sso.auth_method === "jwt" ? "/jwt" : ""));
                    const scheme = config.sso.sync_custom_url || !getters.getUser?.id ? config.sso.auth_scheme : "";
                    const header = buildRequest(getters.getToken,scheme);

                    /**
                     * If no data is passed,
                     * the data will retrieve by a get request.
                     */
                    if(!payload) { /** GET **/
                        axios.get(buildURLWithParams(url),header)
                            .then(async response => {
                                dispatch("processStorage",response.data)
                                    .then(storage => resolve(storage))
                                    .catch(error => reject(error))
                            })
                            .catch(error => reject(error));
                    }
                    /**
                     * If data is passed,
                     * the data will be sent by a post request.
                     */
                    else if(getters.isSynced === true) { /** POST **/
                        axios.post(buildURLWithParams(url),{
                            storage: JSON.stringify({
                                stats: (typeof payload.stats === "string" ? JSON.parse(payload.stats) : payload.stats),
                                games: (typeof payload.games === "string" ? JSON.parse(payload.games) : payload.games),
                            })
                        },header)
                            .then(response => resolve(response))
                            .catch(error => reject(error));
                    }
                    else {
                        reject("Synchronization of new data not possible because previous data was not synchronized!")
                    }
                }
            });
        },
        /**
         *
         * @param getters
         * @param commit
         * @param dispatch
         * @param rootGetters
         * @param storage
         * @returns {Promise<unknown>}
         */
        processStorage: ({getters,commit,dispatch,rootGetters},storage = null) => {
            return new Promise(async (resolve, reject) => {
                if (storage) {
                    storage = (typeof storage === "object" ? storage : JSON.parse(storage));

                    if (storage.hasOwnProperty("stats") && storage.hasOwnProperty("games")) {
                        if(getters.isSynced) {
                            await dispatch("saveToLocalStorage", {
                                key: "user-stats",
                                val: JSON.stringify(storage.stats)
                            });

                            await dispatch("saveToLocalStorage", {
                                key: "user-games",
                                val: JSON.stringify(merge(storage.games,rootGetters.getAllGames))
                            });

                            dispatch("setUser",null,{
                                root: true
                            });
                        }

                        commit("setStorage",storage);
                        resolve(storage)
                    }

                    reject("The storage does not contain the required fields!")
                }

                resolve("Data is not synchronized or the storage is empty!")
            });
        },
        /**
         *
         * @param commit
         * @param rootGetters
         * @param key
         * @param val
         * @returns {Promise<unknown>}
         */
        saveToLocalStorage: ({commit,rootGetters},{key,val}) => {
            return new Promise((resolve,reject) => {
                const config = rootGetters.getConfig;

                if(!config.hasOwnProperty("identifier")) {
                    reject("Object 'config' has no property 'identifier'!");
                }

                localStorage.setItem(config.identifier + "-" + key,val)
                resolve(true);
            });
        },
    },
    mutations: {
        /**
         *
         * @param state
         * @param status
         */
        setPending: (state,status) => {
            state.pending = status;
        },
        /**
         *
         * @param state
         * @param token
         */
        setToken: (state,token) => {
            state.token = token;
        },
        /**
         *
         * @param state
         * @param status
         */
        setAuth: (state,status) => {
            state.auth = status;
        },
        /**
         *
         * @param state
         * @param status
         */
        setSynced: (state,status) => {
            state.synced = status;
        },
        /**
         *
         * @param state
         * @param user
         */
        setUser: (state,user = null) => {
            state.user = user;
        },
        /**
         *
         * @param state
         * @param key
         * @param val
         */
        updateUser: (state, {key,val}) => {
            state.user[key] = val;
        },
        /**
         *
         * @param state
         * @param status
         */
        setNetwork: (state,status) => {
            state.online = status;
        },
        /**
         *
         * @param state
         * @param storage
         */
        setStorage: (state,storage) => {
            state.storage = storage;
        },
        /**
         *
         * @param state
         * @param status
         */
        verify: (state,status) => {
            state.verified = status;
        },
    },
    getters: {
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isSynced: state => {
            return state.synced;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        isAuth: state => {
            return state.auth;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        isVerified: state => {
            return state.verified;
        },
        /**
         *
         * @param state
         */
        isPending: state => state.pending,
        /**
         *
         * @param state
         * @returns {null}
         */
        getUser: state => {
            return state.user;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        getToken: state => {
            return state.token;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        getStorage: state => {
            return state.storage;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        hasNetworkConnection: state => {
            return state.online;
        },
    }
}
