<template>
    <div id="confirm-modal" tabindex="-1" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-md max-h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button @click="cancel()" type="button" class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Schließen</span>
                </button>
                <div class="p-4 md:p-5 text-center">
                    <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                    <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{{ msg }}</h3>
                    <button @click="confirmation()" type="button" class="btn-primary mr-2">
                        Bestätigen
                    </button>
                    <button @click="cancel()" type="button" class="btn-default">
                        Abbrechen
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "./Icon.vue"
import { Modal } from "flowbite"

export default {
    name: "Confirm",
    components: {
        Icon
    },
    props: {
        msg: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            modal: null
        }
    },
    emits: ["callback"],
    methods: {
        cancel() {
            this.modal.hide();
        },
        confirmation() {
            this.modal.hide();
            this.$emit("callback");
        },
        show() {
            this.modal.show();
        }
    },
    mounted() {
        const $targetEl = document.getElementById("confirm-modal");

        this.modal = new Modal($targetEl,{
            placement: "center-center",
            backdrop: "dynamic",
            backdropClasses: "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
            closable: true,
            onHide: () => {
                this.confirm = false;
            },
        });
    }
}
</script>