<template>
    <div class="relative">
        <span class="absolute block right-4 top-4">
            <PaywallIcon :classes="['w-6','h-6','fill-current']"></PaywallIcon>
        </span>
        <div class="mb-5" v-html="paywall.text"></div>
        <a :href="paywall.url" class="btn btn-medium block btn-primary mb-5">{{ paywall.cta }}</a>
        <p class="text-center text-sm text-gray-700 dark:text-gray-400">oder <a :href="paywall.login_url" @click="$handleLogin" class="link-intern">anmelden</a></p>
    </div>
</template>
<script>
import LockedIcon from "./LockedIcon.vue";
import PaywallIcon from "./PaywallIcon.vue";

export default {
    name: "ExtendedNav",
    components: { PaywallIcon, LockedIcon },
    data() {
        return {
            paywall: {
                text: this.markdownToHtml(this.$config.paywall.text),
                cta: this.$config.paywall.cta,
                url: this.getSubscribeUrl(),
                login_url: this.getSSOLoginUrl()
            }
        }
    }
}
</script>