<template>
    <h2 id="accordion-change-email-heading" class="text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-400">
        <button data-accordion-target="#accordion-change-email-body" aria-controls="accordion-change-email-body" type="button" class="flex justify-between items-center p-5 w-full font-medium text-left border-b border-gray-200 dark:border-gray-700">
            <span>Email-Adresse ändern</span>
            <icon icon="pencil-square" classes="h-5 w-5"></icon>
        </button>
    </h2>
    <div id="accordion-change-email-body" class="hidden">
        <div class="p-5 border-b border-gray-200 dark:border-gray-700">
            <form class="space-y-6" action="#" method="POST" @submit="updateEmail" v-if="!custom">
                <div>
                    <label for="current-email-address" class="sr-only">Aktuelle Email-Adresse</label>
                    <div class="relative">
                        <div class="flex absolute z-10 inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <icon icon="envelope" classes="w-5 h-5 text-gray-500"></icon>
                        </div>
                        <input id="current-email-address" v-on:change="validateEmail" v-on:keyup="validateEmail" name="current_email" type="email" v-model="currentEmail" autofocus autocomplete="email" required class="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Aktuelle Email-Adresse">
                    </div>
                    <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">Bitte {{ informal ? "gib" : "geben Sie" }} zur Überprüfung {{ informal ? "deine" : "Ihre" }} aktuelle Email-Adresse ein.</p>
                </div>
                <div>
                    <label for="email-address" class="sr-only">Neue Email-Adresse</label>
                    <div class="relative">
                        <div class="flex absolute z-10 inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <icon icon="envelope" classes="w-5 h-5 text-gray-500"></icon>
                        </div>
                        <input id="email-address" name="email" type="email" v-model="email" autocomplete="off" required class="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Neue Email-Adresse">
                    </div>
                    <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">An die neue Email-Adresse wird ein Bestätigungs-Link gesendet, um {{ $addressingUser('deine','Ihre') }} Email-Adresse zu verifizieren.</p>
                </div>
                <button type="submit" class="group relative w-full flex btn-primary">
                    <span v-if="processing" class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <Spinner classes="h-5 w-5"></Spinner>
                    </span>
                    Email-Adresse ändern
                </button>
            </form>
            <p v-else class="text-sm text-gray-500 dark:text-gray-400">Um {{ $addressingUser('deine','Ihre') }} Email-Addresse zu ändern, klicke{{ $addressingUser('','n Sie') }} bitte auf diesen <a class="link-intern" :href="url">Link</a>.</p>
        </div>
    </div>
</template>

<script>

import { buildRequest } from "../connector-request";
import Icon from "./Icon";
import Spinner from "./Spinner.vue";

export default {
    name: "ChangeEmail",
    components: {
        Spinner,
        Icon
    },
    props: ["custom","url"],
    data() {
        return {
            informal: this.$config.content.addressing_user === "informal",
            processing: false,
            currentEmail: "",
            email: ""
        }
    },
    methods: {
        validateEmail(e) {
            if(this.currentEmail !== this.$store.getters["auth/getUser"]?.email) {
                e.target.setCustomValidity(this.$config.content.messages.error_email);
            } else {
                e.target.setCustomValidity("");
            }
            e.preventDefault();
        },
        updateEmail(e) {
            e.preventDefault();

            if(this.email.length > 0 && this.currentEmail.length > 0 && this.currentEmail === this.$store.getters["auth/getUser"]?.email) {
                this.processing = true;
                axios.post(this.$config.sso.edit_email_url, {
                    email: this.email,
                    current_email: this.currentEmail
                },buildRequest(this.$store.getters["auth/getToken"],this.$config.sso.auth_scheme))
                    .then(response => {
                        this.$store.dispatch("auth/update",{
                            key: "email",
                            val: this.email
                        });

                        this.$store.commit("auth/verify",false);
                        this.$store.dispatch("showToast",{msg:response.data.message,type:"success",timeout:6000});

                        this.email = "";
                        this.currentEmail = "";

                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        });
                    })
                    .finally(() => {
                        this.processing = false;
                    })
                    .catch(error => {
                        if (error.response) {
                            this.error = error.response.data.message;
                        }
                        else if (error.request) {
                            this.error = error.request;
                        }
                        else {
                            this.error = error.message;
                        }

                        this.$store.dispatch("showToast",{
                            msg: this.error
                        });
                    });
            }
        },
    }
}
</script>