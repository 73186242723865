<template>
    <div class="@container container max-w-4xl mx-auto">
        <div id="game">
            <!-- Puzzle -->
            <div class="pt-4 px-4 grid grid-cols-1 @md:grid-cols-2 @md:gap-4">
                <div id="game-result" class="@md:order-last">
                    <div class="flex items-center mb-4">
                        <strong class="font-bold text-sm mr-2 tracking-tight w-16 text-black dark:text-gray-300">{{ game.input.length }} {{ game.input.length === 1 ? 'Wort' : 'Wörter' }}</strong>
                        <ol class="flex justify-between relative items-center grow after:absolute after:z-10 after:content-[''] after:w-full after:h-0.5 after:border-b after:border-gray-200 after:border-1 after:inline-block dark:after:border-gray-600">
                            <li v-for="(ignore,index) in steps" class="flex items-center relative z-20">
                                <div :class="index === currentStep || (index+1) === steps.length ? 'w-7 h-7 bg-transparent cell' : ('w-3 h-3 rounded-full ' + (currentStep > index ? 'bg-brand-500' : 'bg-gray-200 dark:bg-gray-300'))" class="shrink-0">
                                    <Hexagon v-if="index === currentStep || (index+1) === steps.length" :text="index === currentStep ? game.points.toString() : ((index+1) === steps.length ? maxPoints.toString() : null)" :classText="'cell-inner text-xs' + ((index+1) !== steps.length || currentStep === index ? ' font-bold fill-brand-contrast' : '')" :size="28" :static="true" class-svg="inline" stroke="white" :stroke-width="0" :class-polygon="(currentStep+1) === steps.length || (index+1) < steps.length ? 'fill-brand-500': 'fill-gray-200 dark:fill-gray-300'"></Hexagon>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div :style="fullWordList ? 'height: ' + html.wordListHeight  + 'px' : null" :class="fullWordList ? 'h-full' : 'h-12 @md:!h-full'" class="border transition-all @md:transition-none border-gray-300 w-full rounded-lg px-4 relative overflow-hidden">
                        <span v-if="game.input.length <= 0" class="text-gray-400 text-base leading-10 py-1 inline-block">{{ $addressingUser('Deine','Ihre') }} Wörter...</span>
                        <div v-else class="flex flex-wrap">
                            <span v-for="input in game.input" class="mr-1 relative text-gray-800 dark:text-gray-300 text-base leading-10 py-1 inline-block after:content-[','] last:after:content-['']">
                                <span :class="input.term === isogram ? 'underline decoration-4 decoration-brand-500 font-bold' : null" class="inline-block mr-1">{{ input.term }}</span>
                                <sup class="right-0.5">{{ input.points }}</sup>
                            </span>
                        </div>
                        <button class="@md:hidden focus:ring-0 outline-none absolute cursor-pointer h-12 w-full left-0 top-0 bg-transparent flex items-center justify-end" @click="showWordList">
                            <icon icon="chevron-up-down" classes="w-6 h-6 mr-2 bg-white rounded-full"></icon>
                        </button>
                    </div>
                </div>
                <div id="game-grid" :class="fullWordList ? 'invisible h-0 @md:visible @md:h-auto' : null">
                    <!-- Insert -->
                    <div class="insert text-center py-5 h-18 w-full relative">
                        <div role="tooltip" :class="[!tooltip.show ? 'opacity-0 invisible' : 'opacity-100',tooltip.classes]" class="absolute flex items-center justify-center left-0 right-0 z-10 transition-all">
                            <span :class="tooltip.points ? 'text-gray-900 bg-white border border-gray-200' : 'text-white shadow-sm ' + (tooltip.final ? 'bg-green-700 dark:bg-green-500' : 'bg-gray-900 dark:bg-gray-700')" class="relative inline-flex items-center px-3 py-2 text-sm font-medium rounded-lg">
                                <icon v-if="tooltip.final" icon="check-circle" classes="w-5 h-5 text-color-white mr-1"></icon>
                                {{ tooltip.content }}
                                <i v-if="tooltip.points" class="absolute inline-flex items-center justify-center w-8 h-6 text-xs font-bold text-white bg-brand-500 border-2 border-white rounded-full -top-2 -right-4 dark:border-gray-900 not-italic">+{{ game.input[0].points }}</i></span>
                        </div>
                        <div :class="[game.current.length > 0 ? 'after:-right-1' : null,animations.shake ? 'animate-shake' : null,game.end ? 'hidden' : null]" class="insert-inner h-full relative inline-block after:content-[''] after:block after:absolute after:top-0 after:w-0.5 after:bottom-0 after:bg-brand-500 after:animate-blink">
                            <span v-if="game.current.length < 1" class="hidden md:inline placeholder text-3xl leading-8 text-gray-400 cursor-text">Tippe oder klicke</span>
                            <div class="input tracking-wide whitespace-nowrap leading-8 text-3xl">
                                <span v-for="obj in game.current" :class="obj.classname">{{ obj.letter }}</span>
                            </div>
                        </div>
                        <div v-if="game.end && !tooltip.show" class="input tracking-wide whitespace-nowrap leading-8 text-3xl font-bold flex justify-center items-center">
                            <span v-for="letter in game.isogram" :class="letter === game.letter ? 'text-brand-500' : 'text-black dark:text-gray-200'">{{ String.fromCharCode(letter).toUpperCase() }}</span>
                            <span class="bg-gray-100 inline-flex items-center text-gray-800 text-xs font-medium ml-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
                                Isogramm<icon v-if="isogramFound()" icon="check-circle" classes="h-3 w-3 ml-1"></icon>
                            </span>
                        </div>
                    </div>
                    <!-- /Insert -->
                    <!-- Letters -->
                    <div class="aspect-square max-w-sm honeycomb relative select-none mx-auto" id="game-square">
                        <Hexagon stroke="white" :feedback="!game.end" :class-touch="'fill-brand-light'" :argument="game.letter" @callback="simKeyboardEnter" :text="String.fromCharCode(game.letter)" :size="html.polygon" class-text="cell-inner select-none fill-brand-contrast text-gray-600 uppercase font-bold text-xl pointer-events-none" :class-svg="['cell select-none absolute top-1/3 h-1/3 left-0 right-0 mx-auto transition-transform duration-150 ease-in-out',!game.end ? 'active:p-0.5' : null]" :stroke-width="7.5" :class-polygon="'transition-colors select-none fill-brand-500 ' + (!game.end ? 'cursor-pointer active:fill-brand-light' : 'cursor-default')"></Hexagon>
                        <Hexagon v-for="code in game.mandatory" stroke="white" :feedback="!game.end" :class-touch="'fill-stone-300'" :argument="code" @callback="simKeyboardEnter" :text="String.fromCharCode(code)" :size="html.polygon" :class-text="['select-none cell-inner text-gray-600 uppercase font-bold text-xl pointer-events-none transition-opacity ease-in-out duration-500',this.animations.fade ? 'opacity-0' : 'opacity-100']" :class-svg="['cell select-none absolute top-1/3 h-1/3 left-0 right-0 mx-auto transition-transform duration-150 ease-in-out',!game.end ? 'active:p-0.5' : null]" :stroke-width="7.5" :class-polygon="'transition-colors select-none fill-stone-200 ' + (!game.end ? 'cursor-pointer active:fill-stone-300' : 'cursor-default')"></Hexagon>
                    </div>
                    <!-- /Letters -->
                    <!-- Controls -->
                    <div :class="!game.end ? 'px-4 py-5' : null" id="game-controls" class="flex justify-center items-center h-28 md:h-auto w-full space-x-3">
                        <template v-if="!game.end">
                            <button @click="simKeyboardEnter(8,$event)" class="shrink-0 focus:ring-0 select-none outline-none border-2 border-gray-300 px-5 py-2.5 rounded-full block text-center w-24">
                                <icon icon="backspace" classes="w-6 h-6 inline-block text-gray-800 dark:text-gray-300"></icon>
                            </button>
                            <button @click="simKeyboardEnter(32,$event)" class="shrink-0 focus:ring-0 select-none outline-none border-2 border-gray-300 p-2.5 rounded-full">
                                <icon icon="arrow-path" classes="w-6 h-6 text-gray-800 dark:text-gray-300"></icon>
                            </button>
                            <button @click="simKeyboardEnter(13,$event)" class="shrink-0 focus:ring-0 select-none outline-none border-2 border-gray-300 px-5 py-2.5 rounded-full font-mono font-bold text-center w-24 text-gray-800 dark:text-gray-300">Enter</button>
                        </template>
                        <div v-else>
                            <Actions :game="game" :overlay="false"></Actions>
                        </div>
                    </div>
                    <!-- /Controls -->
                </div>
            </div>
            <!-- /Puzzle -->
        </div>
    </div>
    <!-- Stats -->
    <div v-if="showModal" aria-modal="true" class="overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-40 justify-center items-center h-modal flex transition-all duration-300" id="stats">
        <router-view :game="game"></router-view>
    </div>
    <div v-if="showModal" class="bg-gray-900 bg-opacity-50 fixed inset-0 z-30 transition-all"></div>
    <!-- /Stats -->
</template>

<script>
import { useHead } from "@unhead/vue"
import { computed, reactive } from "vue"
import { useRoute } from "vue-router/dist/vue-router"
import Hexagon from "./Hexagon.vue"
import Icon from "../vendor/publisher/components/Icon"
import Actions from "./Actions.vue"

export default {
    name: "Puzzle",
    components: {
        Actions, Icon, Hexagon
    },
    props: ["date"],
    setup() {
        const route = useRoute(), meta = reactive({
            title: route.meta["title"]
        });

        useHead({
            title: computed(() => meta.title)
        });

        return {
            meta
        }
    },
    data: function() {
        return {
            game: {
                archive: !!(this.$route["query"]?.date),
                current: [],
                end: false,
                input: [],
                date: this.date,
                letter: null,
                mandatory: null,
                min: null,
                nr: null,
                isogram: null,
                isogramsFound: 0,
                points: 0
            },
            fullWordList: false,
            steps: Object.values(this.$config.setup.game.achievements),
            maxPoints: Object.values(this.$config.setup.game.achievements).slice(-1)[0],
            animations: {
                shake: false,
                blink: false,
                fade: false
            },
            inputObserver: null,
            showModal: this.$route["meta"].showModal ?? false,
            html: {
                grid: null,
                result: 92 + 16, // +32 padding top & bottom from .grid
                insert: null,
                input: null,
                header: 56, // Set header height
                polygon: 80, // Set Hexagon width
                controls: null,
                maxHeight: 0,
                wordListHeight: 0,
                //...
            },
            tooltip: {
                content: "",
                show: false,
                points: false,
                isogram: false,
                classes: null,
                final: false
            }
            //...
        }
    },
    watch: {
        $route ({meta}) {
            this.showModal = meta.showModal;
        },
        'game.current': {
            deep: true,
            handler() {
                this.calcFontsize();
            }
        }
    },
    computed: {
        puzzle: function() {
            return this.$store.getters.getPuzzleById(this.date);
        },
        currentStep: function () {
            return this.step();
        },
        isogram: function () {
            return this.arrayCharKeyToString(this.game.isogram);
        }
    },
    /*created() {
        // ...
    },*/
    async mounted() {
        await this.$nextTick(function() {
            this.setPlayground();
        });

        new Promise((resolve, reject) => {
            this.$store.dispatch("getGames")
                .then(() => {
                    resolve(this.fetchData = true)
                })
                .catch(error => reject(error));
        })
            .then(async () => {
                // Games loaded
                await this.setGame();
                this.initGame();
            })
            .catch(error => {
                if(!this.online) { // Keine Spiele vorhanden und Nutzer ist offline
                    this.goOffline();
                }
                else { // Da ist irgendwas anderes im argen
                    this.$store.dispatch("showToast",{
                        msg: this.$config.debug ? error : "Ein Fehler ist aufgetreten.",
                        type: "warn",
                        timeout: 5000
                    });
                }
            })
            .finally(() => {
                this.setGameSize();
                this.$Progress.finish();
                this.setTitle();
            });
    },
    methods: {
        /**
         * Setzt den Browser-Titel
         */
        setTitle() {
            if(this.game.archive) {
                this.meta.title = "Archiv #" + this.game.nr;
            }
        },
        setPlayground() {
            this.html.grid = document.getElementById("game-grid");
            this.html.square = document.getElementById("game-square");
            this.html.controls = document.getElementById("game-controls");
            this.html.insert = this.html.grid.querySelector(".insert");
            this.html.input = this.html.grid.querySelector(".input");
        },
        setGameSize() {
            const vh = document.documentElement.clientHeight,
                gameSize = vh-this.html.header,
                controlsSize = this.html.controls.offsetHeight,
                insertSize = this.html.insert.offsetHeight;

            this.html.maxHeight = gameSize-controlsSize-this.html.result-insertSize;
            this.html.wordListHeight = this.html.maxHeight + insertSize + controlsSize;

            if(document.body.style.aspectRatio === undefined) {
                this.setRatio();
            }
            else if(document.documentElement.clientWidth < 640) {
                this.html.square.style.maxHeight = this.html.maxHeight + "px";
            }
        },
        setRatio() {
            const vh = window.innerHeight, gameSize = vh-this.html.header,
                ratio = 1, // Aspect Ratio
                px = 16*2, maxWidth = 384;

            let height = gameSize, width = ratio*gameSize; // Breite errechnet mit der Ratio anhand der verfügbaren Höhe

            if(width > (window.innerWidth-px)) { // Breite ist größer als Display
                width = window.innerWidth-px;
                height = width/ratio;
            }
            else if(width > maxWidth) {
                width = maxWidth;
                height = width/ratio;
            }

            this.html.square.style.height = height.toFixed(0) + "px";
            this.html.square.style.width = width.toFixed(0) + "px";
        },
        async setGame() {
            this.game = {...this.game,...this.puzzle};
            this.game.isogram = this.decrypt(this.game.isogram.toString());
            this.game.mandatory = this.shuffle(this.mandatoryLetters(this.game.isogram,this.game.letter));

            this.game = {
                ...this.game,...this.$store.getters.getGameById(this.date) || {
                    nr: this.game.nr,
                    end: false,
                }
            };
            this.$store.commit("setCurrentGame", this.game);

            if(this.game.input.length > 0) {
                await this.validateInput();
            }

            this.game.isogramsFound = this.isogramsFound();
        },
        initGame() {
            if(this.game.end !== true) {
                this.enableKeyboard();
            }
        },
        /**
         * End of the game; set the user stats
         */
        async setStats() {
            const stats = this.$store.getters.getStats;
            const deviation = this.game.input.length-this.game.min;

            stats.games++;
            stats.words += this.game.input.length;
            stats.isograms = stats.isograms+this.isogramsFound();

            stats.deviations[deviation <= 5 ? deviation : "5+"]++;

            this.event("GameFinished", {
                game_nr: this.game.nr,
                count_words: stats.words,
                deviation: deviation,
                count_isograms: stats.isograms
            });

            // Set stats
            await this.$store.commit("setStats",stats);
            await this.$store.dispatch("writeData",true);

        },
        openStats() {
            router.push({
                name: "Stats",
            });
        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        updateGame() {
            return new Promise(async (resolve) => {
                //...
                await this.$store.commit("setCurrentGame",this.game);
                await this.$store.commit("setGame",{
                    date: this.game.date,
                    end: this.game.end,
                    input: this.game.input,
                    nr: this.game.nr,
                    points: this.totalPoints(),
                });
                this.$store.dispatch("writeData");
                resolve(true);
            });
        },
        async verify() {
            const encrypt = this.encrypt(this.game.current,"letter");

            if(this.game.current.length <= 0) { // Empty
                this.shake();
            }
            else if(!await this.validate('minLength', encrypt)) { // Too short
                this.shake();
                this.showTooltip("Zu kurz",1000);
            }
            else if(!await this.validate('validLetters', encrypt)) { // Nicht erlaubte Buchstaben
                this.shake(this.clearInput);
                this.showTooltip("Unerlaubte Buchstaben",1000);
            }
            else if(!await this.validate('hasLetter', encrypt)) { // Der mittlere Buchstabe muss verwendet werden
                this.shake(this.clearInput);
                this.showTooltip("Der Buchstabe \"" + String.fromCharCode(this.game.letter).toUpperCase() + "\" fehlt.",1500);
            }
            else if(!await this.validate('wordExists',encrypt)) { // Not exists
                this.shake(this.clearInput);
                this.showTooltip("Nicht in Wortliste enthalten",1500);
            }
            else if(!await this.validate('notUsed', encrypt)) { // Wurde schon eingegeben
                this.shake(this.clearInput);
                this.showTooltip("Wort bereits gefunden",1500);
            }
            else { //... Erfolg!
                this.found(this.arrayCharKeyToString(encrypt));
            }
        },
        /**
         *
         * @param checkFor
         * @param encrypt
         * @returns {Promise<boolean>}
         */
        async validate(checkFor,encrypt = null) {
            let validate = [];

            if(!Array.isArray(checkFor)) {
                checkFor = [checkFor];
            }

            const rules = {
                'minLength': () => {
                    return this.arrayCharKeyToString(encrypt).length >= this.$config.setup.game.min_letters
                },
                'validLetters': () => {
                    return encrypt && encrypt.filter(obj => this.game.isogram.indexOf(obj) === -1).length < 1
                },
                'hasLetter': () => {
                    return encrypt && encrypt.includes(this.game.letter)
                },
                'wordExists': () => {
                    return encrypt && this.isWord(encrypt.join("")) === true
                },
                'notUsed': () => {
                    return encrypt && !this.inputExists(this.arrayCharKeyToString(encrypt))
                }
            };

            for (const check of checkFor) {
                if(typeof rules[check] === "function") {
                    validate.push(new Promise(resolve => resolve(rules[check]())));
                }
            }

            return (await Promise.all(validate)).every(valid => valid);
        },
        /**
         *
         * @param word
         * @returns {boolean}
         */
        inputExists(word) {
            return !!Object.values(this.game.input).find(item => item["term"] === word);
        },
        /**
         *
         * @param word
         */
        found(word) {
            const points = this.points(word);
            this.game.input.unshift({
                term:word.toUpperCase(),
                points:points
            });
            this.game.points = this.totalPoints();

            if(this.game.points >= this.maxPoints) {
                this.final();
            }
            else {
                const messages = this.$config.content.messages.game.found;
                const isogram = this.isIsogram(this.game.isogram.length,word);
                this.showTooltip(isogram ? "Isogramm!" : this.getRandomItem(messages.hasOwnProperty(points) ? messages[points] : messages[Object.keys(messages)[Object.keys(messages).length - 1]]),1000,null,true);

                this.event("WordFound",{
                    game_nr: this.game.nr,
                    word: word.toUpperCase(),
                    is_isogram: isogram
                });
            }

            this.updateGame();
            this.clearInput();
        },
        final() {
            this.game.end = true;
            this.game.isogramsFound = this.isogramsFound();
            this.disableKeyboard();
            this.showTooltip(this.getRandomItem(this.$config.content.messages.game.success),2000,this.openStats,false,false,true);
            this.setStats();
        },
        /**
         *
         * @param word
         * @returns {number}
         */
        points(word) {
            if(this.isIsogram(this.game.isogram.length,word)) {
                return this.$config.setup.game.points_isogram ?? 10;
            }
            else if(word.length >= (this.$config.setup.game.points_letters ?? 5)) {
                return word.length;
            }

            return this.$config.setup.game.points_min ?? 1;
        },
        /**
         *
         * @returns {number}
         */
        isogramsFound() {
            return this.game.input.filter((solution) => {
                return this.isIsogram(this.game.isogram.length,solution.term);
            }).length;
        },
        /**
         *
         * @returns {boolean}
         */
        isogramFound() {
            return this.game.input.filter((solution) => {
                return solution.term === this.isogram;
            }).length > 0;
        },
        /**
         *
         * @returns {*}
         */
        totalPoints() {
            return Object.values(this.game.input).reduce((a, b) => a+b.points, 0);
        },
        /**
         *
         * @returns {number}
         */
        step() {
            let step = 0;

            for(let i = step;i<this.steps.length;i++) {
                if(this.game.points >= this.steps[i] || this.steps[i] >= this.steps[this.steps.slice(-1)]) {
                    step = i;
                }
            }

            return step;
        },
        /**
         *
         * @param content
         * @param timeout
         * @param callback
         * @param points
         * @param isogram
         * @param final
         */
        showTooltip(content = "",timeout = 2000,callback = null,points = false,isogram = false,final = false) {
            this.tooltip.content = content;
            this.tooltip.points = points;
            this.tooltip.isogram = isogram;
            this.tooltip.final = final;

            if(points === true || final === true) {
                this.tooltip.classes = '-top-1 duration-0'
                setTimeout(() => this.tooltip.classes = '-top-6 duration-700',150);
            }
            else {
                this.tooltip.classes = '-top-6 duration-150'
            }

            this.tooltip.show = true;

            setTimeout(() => {
                this.tooltip.show = false;
                if(callback instanceof Function) {
                    callback();
                }
            }, timeout);
        },
        resetInput() {
            this.game.input = [];
        },
        /**
         *
         * @returns {Promise<void>}
         */
        async validateInput() {
            const input = this.game.input;

            this.resetInput();

            const asyncFilter = async (arr, condition) => {
                const results = await Promise.all(arr.map(condition));
                return arr.filter((_v, index) => results[index]);
            }

            this.game.input = await asyncFilter(input,async (solution) => {
                const encrypt = this.encrypt(solution.term.split(""));
                return await this.validate(['minLength', 'validLetters', 'hasLetter', 'wordExists', 'notUsed'], encrypt);
            });

            this.game.input.map(solution => solution.points = this.points(solution.term));
            this.game.points = this.totalPoints();

            if(this.game.end === false && this.game.points >= this.maxPoints) {
                this.final();
                await this.updateGame();
            }
        },
        /**
         *
         * @param word
         * @returns {*}
         */
        isWord(word) {
            return this.$store.getters.isWord(word);
        },
        clearInput() {
            this.game.current = [];
        },
        /**
         * Init, disable & reset keyboard eventListener
         */
        enableKeyboard() {
            window.addEventListener("keydown",this.enterKeyboard);
        },
        disableKeyboard() {
            window.removeEventListener("keydown",this.enterKeyboard);
        },
        /**
         *
         * @param code
         * @param e
         */
        simKeyboardEnter(code,e) {
            window.dispatchEvent(new KeyboardEvent("keydown", {
                "keyCode": code
            }));

            if(e) {
                e.preventDefault();
            }
        },
        /**
         *
         * @param e
         * @returns {Promise<void>}
         */
        enterKeyboard: async function(e) {
            const charCode = e.which ? e.which : e.keyCode;

            if(charCode >= 65 && charCode <= 90) { // a -z
                if(this.game.current.length >= this.$config.setup.game.max_letters) {
                    this.shake();
                }
                else {
                    this.game.current.push({
                        letter: String.fromCharCode(charCode),
                        classname: this.getClassnameForLetter(charCode)
                    });
                }
            }
            else if (charCode === 13) { // Enter
                await this.verify();
            }
            else if (charCode === 32) { // Space
                this.event("ShuffleLetters",{
                    game_nr: this.game.nr
                });
                // Shuffle letters
                this.animations.fade = true;
                setTimeout(() => {
                    this.animations.fade = false;
                    this.game.mandatory = this.shuffle(this.game.mandatory);
                }, 400);
            }
            /**
             * 46: delete
             * 8: backspace
             */
            else if(charCode === 46 || charCode === 8) { // Delete
                this.game.current.pop();
            }

            e.preventDefault();

        },
        /**
         *
         * @param code
         * @returns {string}
         */
        getClassnameForLetter(code) {
            let classes = ['font-bold'];

            if(code === this.game.letter) {
                classes.push('text-brand-500');
            }
            else if(!this.game.isogram.includes(code)) {
                classes.push('text-gray-400 dark:text-gray-500');
            }
            else {
                classes.push('text-black dark:text-gray-200')
            }

            return classes.join(' ')
        },
        calcFontsize() {
            const max = 1.875; //rem
            const current = parseFloat(this.html.input.style.fontSize !== "" ? this.html.input.style.fontSize : max);
            let fontSize = max;
            const min = 0.75; // rem

            const maxWidth = this.html.insert.offsetWidth - 100;

            setTimeout(() => {
                let overflow = maxWidth - this.html.input.offsetWidth;
                const percentage = Math.abs(overflow)/maxWidth.toFixed(3);

                if(overflow < 1) {
                    fontSize = (current - (current * percentage)).toFixed(3);
                    this.html.input.style.fontSize = (fontSize < min ? min : fontSize) + "rem";
                }
                else if(current < max) {
                    fontSize = (current + (current * percentage)).toFixed(3);
                    this.html.input.style.fontSize = (fontSize < min ? min : fontSize > max ? max : fontSize) + "rem";
                }
            }, 1)
        },
        showWordList(e) {
            if(!this.fullWordList) {
                this.event("ExpandWordList",{
                    game_nr: this.game.nr
                });
            }

            e.preventDefault();
            this.fullWordList = !this.fullWordList;
        },
        /**
         * Animations
         * @param callback
         */
        shake(callback = null) {
            this.animations.shake = true;
            setTimeout(() => {
                this.animations.shake = false
                if(callback) {
                    callback();
                }
            }, 900);
        },
    },
    beforeUnmount() {
        this.disableKeyboard();
    },
}
</script>
