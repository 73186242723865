<template>
    <div id="alert-border-2" class="flex p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800" role="alert">
        <icon icon="information-circle" classes="flex-shrink-0 w-5 h-5"></icon>
        <div class="ml-3 text-sm font-medium" v-html="msg"></div>
    </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
    name: "Alert",
    components: {Icon},
    props: ["msg"]
}
</script>