// noinspection JSUnusedGlobalSymbols

/*
|--------------------------------------------------------------------------
| Use with condition
|--------------------------------------------------------------------------
|   hasConnection: function(connection) {
|       if(!connection && condition) {
|           this.goOffline();
|       }
|   }
|
*/
export default {
    data() {
        return {
            connected: navigator.onLine
        }
    },
    mounted() {
        window.addEventListener("online",this.updateNetworkStatus);
        window.addEventListener("offline",this.updateNetworkStatus);
    },
    computed: {
        /**
         *
         * @returns {boolean}
         */
        hasConnection() {
            return this.connected;
        }
    },
    methods: {
        /**
         *
         * @param e
         */
        updateNetworkStatus(e) {
            this.connected = e.type === "online";
            this.$store.commit("setNetwork",this.connected);
        },
        /**
         *
         * @param page
         */
        goOffline(page = "Offline") {
            router.push({
                name: page
            });
        },
        /**
         *
         * @param page
         */
        goOnline(page = "Home") {
            router.push({
                name: page,
                replace: true
            });
        },
        /**
         *
         * @param error
         * @param next
         * @returns {Promise<unknown>}
         */
        offlineToast(error,next = null) {
            return new Promise(async (resolve, reject) => {
                if(error.code === "ERR_NETWORK") {
                    this.$store.dispatch("showToast",{
                        msg: this.$store.content.messages.offline[this.$config.content["addressing_users"] ?? "informal"],
                        type: "offline",
                        timeout: 5000
                    });

                    if(next) {
                        router.push({
                            name: next
                        });
                    }
                    resolve();
                }
                else {
                    reject(error);
                }
            });
        }
    },
    beforeDestroy() {
        window.removeEventListener("online",this.updateNetworkStatus);
        window.removeEventListener("offline",this.updateNetworkStatus);
    }
}
