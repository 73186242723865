<template>
    <div class="container max-w-4xl mx-auto">
        <legal type="privacy"></legal>
    </div>
</template>

<script>
import Breadcrumb from "../components/Breadcrumb"
import Legal from "../components/Legal.vue"

export default {
    name: "Privacy",
    components: {
        Legal,
        Breadcrumb,
    },
}
</script>
