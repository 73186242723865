/**
 *
 * @returns {*|string}
 */
const getDomain = () => {
    if(process.env.hasOwnProperty("MIX_APP_CONNECTOR_URL")) {
        return process.env["MIX_APP_CONNECTOR_URL"];
    }
    else {
        return "https://" + (process.env["MIX_APP_ENV"] === "dev" ? "staging." : "") + "publisher.oliwol." + (process.env["MIX_APP_ENV"] === "local" ? "local:8890" : "de")
    }
};

/**
 *
 * @param identifier
 * @returns {boolean|null}
 */
const displayAchievementsInAccount = (identifier = null) => {
    if(!identifier) {
        return null;
    }

    const products = ["wrdl"];
    return products.includes(identifier);
}

/**
 *
 * @returns {object}
 */
const publisherConfig = (/*debug = true*/) => {
    // const domain = "https://" + (process.env.MIX_APP_ENV === "dev" ? 'staging.' : '') + "publisher.oliwol." + (process.env.MIX_APP_ENV === "local" ? 'local:8890' : 'de');
    const domain = getDomain();

    return {
        sso: {
            account_url: null,
            auth_method: "token",
            auth_scheme: "Bearer",
            auth_url: domain + '/api/app/auth',
            auth_status_key_val: null,
            auth_token_key: null,
            auth_id_key: null,
            auth_email_key: "data.user.email",
            auth_username_key: null,
            custom: false,
            delete_account_url: domain + '/api/app/user/delete',
            edit_email_url: domain + '/api/app/user/edit/email',
            edit_notification_url: domain + '/api/app/user/edit/notification',
            edit_password_url: domain + '/api/app/user/edit/password',
            iframe: false,
            iframe_actions: null,
            iframe_width: 450,
            iframe_height: 460,
            login_url: domain + '/api/app/login',
            logout_url: domain + '/api/app/logout',
            logout_method: 'get',
            redirect_key: "ref",
            sync_custom_url: null,

            // only publisher sso
            csrf_cookie_url: domain + '/sanctum/csrf-cookie',
            forgot_password_url: domain + '/api/app/forgot-password',
            register_url: domain + '/api/app/register',
            reset_password_url: domain + '/api/app/reset-password',
            sync_url: domain + '/api/app/sync',
            verification_email_url: domain + '/api/app/email/verification-notification',
            verify_url: domain,
        },

        content: {
            messages: {
                error: "Ein Fehler ist aufgetreten!",
                reset_stats: {
                    informal: "Bist du dir sicher, dass du deinen gesamten Fortschritt unwiderruflich löschen möchtest?",
                    formal: "Sind Sie sich sicher, dass Sie ihren gesamten Fortschritt unwiderruflich löschen möchten?"
                },
                error_email: "Die eingegebene Email-Adresse ist nicht richtig.",
                passwords_not_equal: "Die Eingaben stimmen nicht überein.",
                confirm_email: {
                    informal: "Bitte gib zur Bestätigung deine Email-Adresse ein.",
                    formal: "Bitte geben Sie zur Bestätigung Ihre Email-Adresse ein."
                },
                offline: {
                    informal: "Du bist aktuell nicht mit dem Internet verbunden!",
                    formal: "Sie sind aktuell nicht mit dem Internet verbunden!"
                }
            },
        },
    }
};

export { publisherConfig, displayAchievementsInAccount }