export default {
    data() {
        return {}
    },
    created() {
        document.addEventListener("serviceWorkerInstallPrompt",this.installPrompt,{ once: true });
    },
    methods: {
        /**
         *
         * @param EventAction
         * @param EventValue
         */
        event(EventAction,EventValue = {}) {
            window.dispatchEvent(new CustomEvent(EventAction, {
                detail: EventValue,
            }));
        },
        /**
         *
         * @param event
         */
        installPrompt(event) {
            this.event(event.detail.action,{
                platform: event.detail.platform
            })
        }
    }
}
