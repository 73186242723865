<template>
    <div class="container max-w-4xl mx-auto">
        <div class="flex items-center justify-center pt-12 p-4 sm:px-6 lg:px-8">
            <div class="max-w-md w-full space-y-8">
                <div>
                    <h1 class="text-center text-3xl font-extrabold text-gray-900 dark:text-white">
                        Erstelle{{ $addressingUser('','n Sie') }} ein neues Passwort
                    </h1>
                    <p class="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
                        Bitte {{ $addressingUser('gib deine','geben Sie Ihre') }} Email-Adresse sowie ein neues Passwort ein und wiederhole{{ $addressingUser('','n Sie') }} anschließend {{ $addressingUser('dein','Ihr') }} neues Passwort.
                    </p>
                </div>
                <form class="mt-8 space-y-6" action="#" autocomplete="off" method="POST" @submit="handleSubmit">
                    <div>
                        <label for="email-address" class="sr-only">Email-Adresse</label>
                        <input id="email-address" name="email" type="email" v-model="email" autofocus required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Email-Adresse">
                    </div>
                    <div>
                        <label for="new-password" class="sr-only">Neues Passwort</label>
                        <input id="new-password" minlength="5" name="password_new" type="password" v-model="password" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Neues Passwort">
                    </div>
                    <div>
                        <label for="check-password" class="sr-only">Passwort wiederholen</label>
                        <input id="check-password" minlength="5" v-on:change="validatePassword" v-on:keyup="validatePassword" name="password_confirmation" type="password" v-model="passwordConfirmation" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Passwort wiederholen">
                    </div>
                    <button type="submit" class="group relative w-full flex btn-primary">
                        <span v-if="!processing" class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <icon icon="lock-closed" classes="h-5 w-5 text-brand-contrast group-hover:text-brand-500 contrast:text-orange-300 contrast:group-hover:text-orange-500"></icon>
                        </span>
                        <span v-else class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <Spinner classes="h-5 w-5"></Spinner>
                        </span>
                        Neues Passwort erstellen
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "../components/Icon";
import Spinner from "../components/Spinner.vue";

export default {
    name: "NewPassword",
    components: {Spinner, Icon},
    data() {
        return {
            token: null,
            email: "",
            password: "",
            passwordConfirmation: "",
            processing: false
        }
    },
    created() {
        const query = window.location.search,
            params = new URLSearchParams(query);

        this.token = params.get("token");
    },
    methods: {
        /**
         *
         * @param e
         */
        handleSubmit(e) {
            e.preventDefault()
            if(this.password.length > 0 && this.passwordConfirmation.length > 0 && this.password === this.passwordConfirmation) {
                this.processing = true;
                axios.post(this.$config.sso.reset_password_url,{
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                    token: this.token
                })
                    .then(response => {
                        this.$store.dispatch("showToast",{msg:response.data.message,type:"success"});
                        router.push({name:"Login"});
                    })
                    .finally(() => {
                        this.email = "";
                        this.password = "";
                        this.passwordConfirmation = "";
                        this.processing = false;
                    })
                    .catch(error => {
                        if (error.response) {
                            this.error = error.response.data.message;
                        }
                        else if (error.request) {
                            this.error = error.request;
                        }
                        else {
                            this.error = error.message;
                        }

                        this.$store.dispatch("showToast",{msg:this.error,type:"warn"});
                    });
            }
        },
        /**
         *
         * @param e
         */
        validatePassword(e) {
            if(this.password !== this.passwordConfirmation) {
                e.target.setCustomValidity("Die Eingaben stimmen nicht überein.");
            } else {
                e.target.setCustomValidity("");
            }
            e.preventDefault();
        }
    },
}
</script>
