<template>
    <div class="static" v-html="text"></div>
</template>

<script>

export default {
    name: "Legal",
    props: ["type"],
    data() {
        return {
            text: this.markdownToHtml(this.$config.content[this.type],{
                strip_html: false,
                classes: {
                    h1: "my-5 mt-4 text-center text-3xl font-extrabold text-gray-900 dark:text-white px-4 sm:px-6 lg:px-8",
                    h3: "mb-3 font-semibold dark:text-gray-400",
                    p: "mb-3 text-justify dark:text-gray-400",
                    ul: "list-disc list-inside mb-3 space-y-2 dark:text-gray-400",
                    ol: "list-decimal list-inside mb-3 space-y-2 dark:text-gray-400"
                }
            })
        }
    },
    mounted() {
        this.activeScripts();
    }
}
</script>