class Markdown {

    /**
     *
     * @type {{strip_html: boolean}}
     */
    options = {
        strip_html: true,
        classes: null
    };

    /**
     *
     * @type {string}
     */
    html = "";

    /**
     *
     * @type {{paragraph: RegExp, blockquote: RegExp, code: RegExp, heading: RegExp, thead: RegExp, lt: RegExp, link: RegExp, hr: RegExp, cell: RegExp, list: RegExp, gt: RegExp, space: RegExp, highlight: RegExp, listJoin: RegExp, row: RegExp, escape: RegExp, table: RegExp, stash: RegExp}}
     */
    regex = {
        blockquote: /\n *&gt; *([^]*?)(?=(\n|$){2})/g,
        cell: /\||(.*?[^\\])\|/g,
        escape: /\\([\\\|`*_{}\[\]()#+\-~])/g,
        gt: />/g,
        heading: /(?=^|>|\n)([>\s]*?)(#{1,6}) (.*?)( #*)? *(?=\n|$)/g,
        highlight: /(^|[^A-Za-z\d\\])(([*_])|(~)|(\^)|(--)|(\+\+)|`)(\2?)([^<]*?)\2\8(?!\2)(?=\W|_|$)/g,
        hr: /^([*\-=_] *){3,}$/gm,
        list: /\n( *)(?:[*\-+]|((\d+)|([a-z])|[A-Z])[.)]) +([^]*?)(?=(\s+?\n|$){2})/g,
        listJoin: /<\/(ol|ul)>\n\n<\1>/g,
        lt: /</g,
        code: /((```|~~~)([\s\S]*?)(```|~~~))/g,
        link: /((!?)\[(.*?)\]\((.*?)( ".*")?\)|\\([\\`*_{}\[\]()#+\-.!~]))/g,
        // paragraph: /(?=^|>|\n)\s*\n+([^<]+?)\n+\s*(?=\n|<|$)/g,
        // paragraph: /(?=^|>|\n)\s*\n+(.+?)\n+\s*(?=\n|<|$)/g,
        // paragraph: /^([A-Za-z0-9].*(?:\n[A-Za-z0-9].*)*)/gm,
        // paragraph: /^([^<\n].*(?:\n[A-Za-z0-9].*)*)/gm,
        paragraph: /^([^<\n\s].*(?:\n[A-Za-z0-9].*)*[^>])$/gm,
        row: /.*\n/g,
        table: /\n(( *\|.*?\| *\n)+)/g,
        thead: /^.*\n( *\|( *\:?-+\:?-+\:? *\|)* *\n|)/,
        space: /\t|\r|\uf8ff/g,
        stash: /-\d+\uf8ff/g,
        breakLine: /(.*\s)\n(?!\n)/g,
        script: /<script.*?src="(.*?)"[\s\S]*?>[\s\S]*?<\/script>/gi,
    };

    /**
     *
     * @param html
     * @param options
     */
    constructor(html,options = {}) {
        this.html = html;
        this.options = {...this.options,...options};
    }

    /**
     *
     * @returns {string}
     */
    render() {
        this.html = '\n' + this.html + '\n';

        if(this.options.strip_html) {
            this.#replace(this.regex.lt,'&lt;');
            this.#replace(this.regex.gt,'&gt;');
        }

        this.#replace(this.regex.space,"  ");

        const stash = [];
        let si = 0;

        this.html = this.#blockquote(this.html);

        // horizontal rule
        this.#replace(this.regex.hr,'<hr/>');

        // list
        this.html = this.#list(this.html);
        this.#replace(this.regex.listJoin,"");

        // code
        this.#replace(this.regex.code, (all, p1, p2, p3, p4) => {
            stash[--si] = this.#element("pre", this.#element("code",p3 || p4.replace(/^    /gm,'')));
            return si + '\uf8ff';
        });

        // link or image
        this.#replace(this.regex.link, (all, p1, p2, p3, p4, p5, p6) => {
            stash[--si] = p4
                ? p2
                    ? '<img src="' + p4 + '" alt="' + p3 + '"/>'
                    : '<a href="' + p4 + '" class="link-intern md">' + this.#unesc(this.#highlight(p3)) + '</a>'
                : p6;

            return si + '\uf8ff';
        });

        // table
        this.#replace(this.regex.table, (all, table) => {
            const sep = table.match(this.regex.thead)[1];

            return '\n' + this.#element("table",
                table.replace(this.regex.row, (row, ri) => {
                    return row === sep ? '' : this.#element("tr", row.replace(this.regex.cell, (all, cell, ci) => {
                        return ci ? this.#element(sep && !ri ? "th" : "td", this.#unesc(this.#highlight(cell || ""))) : ""
                    }))
                })
            )
        });

        // heading
        this.#replace(this.regex.heading,(all, _, p1, p2) => _ + this.#element("h" + p1.length, this.#unesc(this.#highlight(p2))));

        // paragraph
        this.#replace(this.regex.paragraph,(all, content) => this.#element("p", this.#unesc(this.#highlight(content))));

        // stash
        this.#replace(this.regex.stash,all => stash[parseInt(all)]);

        // classes
        this.#class();

        this.html = this.#html(this.html.trim());

        // scripts
        this.#replace(this.regex.script,(all,src) => '<span class="md-script" data-src="' + src + '"><!-- Script: ' + src + ' --></span>');

        // break line
        // this.html = this.#breakLine(this.html);

        return this.html;
    }

    /**
     *
     * @param str
     * @returns {*|string}
     */
    #html(str) {
        if(this.options.strip_html === true) {
            return str;
        }

        const textarea= document.createElement("textarea");
        textarea.innerHTML = str;
        str = textarea.value;

        return str;
    }

    /**
     *
     * @param str
     * @returns {*}
     */
    #blockquote(str) {
        return str.replace(this.regex.blockquote, (all, content) => {
            return this.#element("blockquote", this.#blockquote(this.#highlight(content.replace(/^ *&gt; */gm, ''))));
        });
    }

    /**
     *
     * @param str
     * @returns {*}
     */
    #breakLine(str) {
        return str.replace(this.regex.breakLine, (all, content) => {
            return content + '<br />';
        });
    }

    /**
     *
     * @param str
     * @returns {*}
     */
    #list(str) {
        return str.replace(this.regex.list, (all, ind, ol, num, low, content) => {
            const entry = this.#element('li', this.#highlight(content.split(
                RegExp('\n ?' + ind + '(?:(?:\\d+|[a-zA-Z])[.)]|[*\\-+]) +', 'g')).map(str => this.#list(str)).join('</li><li>')));

            return '\n' + (ol
                ? '<ol' + (this.options.classes?.hasOwnProperty("ol") ? ' class="' + this.options.classes["ol"] + '"' : '') + ' start="' + (num ? ol + '">' : parseInt(ol,36) - 9 + '" style="list-style-type:' + (low ? 'low' : 'upp') + 'er-alpha">') + entry + '</ol>'
                : this.#element('ul', entry));
        });
    }

    /**
     *
     * @param regex
     * @param fn
     */
    #replace(regex,fn) {
        this.html = this.html.replace(regex,fn)
    }

    /**
     *
     * @param tag
     * @param content
     * @returns {string}
     */
    #element(tag, content) {
        return '<' + tag + (this.options.classes?.hasOwnProperty(tag) ? ' class="' + this.options.classes[tag] + '"' : '') + '>' + content + '</' + tag + '>';
    }

    /**
     *
     * @param str
     * @returns {*}
     */
    #unesc(str) {
        return str.replace(this.regex.escape,'$1')
    }

    /**
     *
     * @param str
     * @returns {*}
     */
    #highlight(str) {
        return str.toString().replace(this.regex.highlight, (all, _, p1, emp, sub, sup, small, big, p2, content) => {
            return _ + this.#element(
                emp ? (p2 ? 'strong' : 'em')
                    : sub ? (p2 ? 's' : 'sub')
                        : sup ? 'sup'
                            : small ? 'small'
                                : big ? 'big'
                                    : 'code',
                this.#highlight(content));
        });
    }

    #class() {
        this.html = this.html
            // ol/ul list
            .replace(/<(ul|ol[.*]?)><li>\{\.([a-z0-9A-Z:\-\s]+)\}/g,(all,tag,classes) => this.#classes(tag,classes))
            .replace(/<([a-z0-9]+)>\{\.([a-z0-9A-Z:\-\s]+)\}/g,(all,tag,classes) => this.#classes(tag,classes))
            .replace(/<img(.*?)>\s\{\.([a-z0-9A-Z:\-\s]+)\}/g,(all,attr,classes) => {
                classes = classes.split(" ");
                return '<img' + attr + ' class="' + classes.join(" ") + '">';
            })
    }

    /**
     *
     * @param tag
     * @param classes
     * @returns {string}
     */
    #classes(tag,classes) {
        classes = classes.split(" ");
        return "<" + tag + ' class="' + classes.join(" ") + '">' + (tag.match(/^ul|ol$/) ? '<li>' : '');
    }
}

export { Markdown }
