<template>
    <h2 id="accordion-change-password-heading" class="text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-400">
        <button data-accordion-target="#accordion-change-password-body" aria-controls="accordion-change-password-body" type="button" class="flex justify-between items-center p-5 w-full font-medium text-left border-b border-gray-200 dark:border-gray-700">
            <span>Passwort ändern</span>
            <icon icon="pencil-square" classes="h-5 w-5"></icon>
        </button>
    </h2>
    <div id="accordion-change-password-body" class="hidden">
        <div class="p-5 border-b border-gray-200 dark:border-gray-700">
            <form class="space-y-6" action="#" autocomplete="off" method="POST" @submit="updatePassword" v-if="!custom">
                <div>
                    <label for="email-address" class="sr-only">Altes Passwort</label>
                    <input tabindex="100" autocomplete="" id="current-password" minlength="5" name="password_current" type="password" v-model="currentPassword" autofocus required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Aktuelles Passwort">
                    <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">Bitte {{ $addressingUser('gib','geben Sie') }} zur Überprüfung {{ $addressingUser('dein','Ihr') }} aktuelles Passwort ein. <router-link class="link-intern" :to="{name:'ForgotPassword'}">Passwort vergessen?</router-link></p>
                </div>
                <label for="new-password" class="sr-only">Neues Passwort</label>
                <input tabindex="101" autocomplete="" id="new-password" minlength="5" name="password_new" type="password" v-model="password" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Neues Passwort">
                <label for="check-password" class="sr-only">Passwort wiederholen</label>
                <input tabindex="102" autocomplete="" id="check-password" minlength="5" v-on:change="validatePassword" v-on:keyup="validatePassword" name="password_confirmation" type="password" v-model="passwordConfirmation" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Passwort wiederholen">
                <button type="submit" class="group relative w-full flex btn-primary">
                    <span v-if="!processing" class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <icon icon="lock-closed" classes="h-5 w-5 text-brand-contrast group-hover:text-brand-500 contrast:text-orange-300 contrast:group-hover:text-orange-500"></icon>
                    </span>
                    <span v-if="processing" class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <Spinner classes="h-5 w-5"></Spinner>
                    </span>
                    Neues Passwort erstellen
                </button>
            </form>
            <p v-else class="text-sm text-gray-500 dark:text-gray-400">Um {{ $addressingUser('dein','Ihr') }} Passwort zu ändern, klicke{{ $addressingUser('','n Sie') }} bitte auf diesen <a class="link-intern" :href="url">Link</a>.</p>
        </div>
    </div>
</template>
<script>
import {buildRequest} from "../connector-request";
import Icon from "./Icon";
import Spinner from "./Spinner.vue";

export default {
    name: "ChangePassword",
    components: {
        Spinner,
        Icon
    },
    props: ["custom","url"],
    data() {
        return {
            processing: false,
            password: "",
            currentPassword: "",
            passwordConfirmation: "",
        }
    },
    methods: {
        /**
         *
         * @param e
         */
        updatePassword(e) {
            e.preventDefault();

            if(this.password.length > 0 && this.currentPassword.length > 0 && this.passwordConfirmation.length > 0) {
                this.processing = true;
                axios.post(this.$config.sso.edit_password_url, {
                    current_password: this.currentPassword,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation
                },buildRequest(this.$store.getters["auth/getToken"],this.$config.sso.auth_scheme))
                    .then(response => {
                        this.$store.dispatch("showToast",{msg:response.data.message,type:"success",timeout:4000});

                        this.password = "";
                        this.currentPassword = "";
                        this.passwordConfirmation = "";
                        this.processing = false;

                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        });
                    })
                    .finally(() => {
                        this.processing = false;
                    })
                    .catch(error => {
                        if (error.response) {
                            this.error = error.response.data.message;
                        }
                        else if (error.request) {
                            this.error = error.request;
                        }
                        else {
                            this.error = error.message;
                        }

                        this.$store.dispatch("showToast",{
                            msg: this.error
                        });
                    });
            }
        },
        /**
         *
         * @param e
         */
        validatePassword(e) {
            if(this.password !== this.passwordConfirmation) {
                e.target.setCustomValidity(this.$config.content.messages.passwords_not_equal);
            } else {
                e.target.setCustomValidity("");
            }
            e.preventDefault();
        }
    }
}
</script>