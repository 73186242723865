<script>
import { h } from "vue"

export default {
    props: ["svg","classes"],
    /**
     *
     * @returns {VNode}
     */
    render() {
        const classList = this.$props.hasOwnProperty("classes") ? this.$props.classes : null;
        let fragment = document.createDocumentFragment();
        let span = document.createElement("span");
        span.innerHTML = this.$props.svg;
        fragment.appendChild(span);
        if (classList) {
            const svg = fragment.querySelector("svg");
            if(svg) {
                svg.classList.add(...classList);
            }
        }

        return h("span", {
            innerHTML: span.innerHTML,
        })
    },
}
</script>
