<template>
    <div class="container max-w-4xl mx-auto">
        <div class="static">
            <div class="flex p-4 mb-4 bg-gray-100 border-t-4 border-gray-500 dark:bg-gray-700" role="alert">
                <icon icon="information-circle" classes="flex-shrink-0 w-5 h-5 text-gray-700 dark:text-gray-300"></icon>
                <div class="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Diese Seite existiert leider nicht mehr.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "../components/Icon";

export default {
    name: "PageNotFound",
    components: {Icon},
    /*created: function() {
        // TODO: check if there is a ssr solution
        window.location.href = "/404";
    }*/
}
</script>
