const { openDB, deleteDB } = require("idb")
const databaseName = process.env.IDENTIFIER;
const debug = process.env.DEBUG ?? false;

const dbPromise = new Promise((resolve,reject) => {
    if(debug === true) {
        return reject("Connection to IndexedDB interrupted: debug mode is active!");
    }

    return resolve(openDB(databaseName, 1, {
        upgrade(db) {
            const STORE = db.createObjectStore("puzzles",{autoIncrement:false});
            STORE.createIndex("date", "date", {unique:false});
        }
    }));
});

async function get(key) {
    return (await dbPromise).get("puzzles", key);
}

async function set(key, val) {
    return (await dbPromise).put("puzzles", val, key);
}

async function del(key) {
    return (await dbPromise).delete("puzzles", key);
}

async function _keys() {
    return (await dbPromise).getAllKeys("puzzles");
}

async function getAll() {
    return (await dbPromise).getAll("puzzles");
}

async function clear() {
    return (await dbPromise).clear("puzzles");
}

function truncate() {
    return new Promise((resolve) => {
        resolve(deleteDB(databaseName));
    })
}

export { get, set, del, _keys, getAll, clear, truncate }
