<template>
    <svg :width="static ? size : null" :height="static ? size : null" :viewBox="'0 0 ' + size + ' ' + radius() * Math.sqrt(3)" :class="[classSvg,onTouch ? 'p-1.5' : null]">
        <polygon @click="!isTouchDevice() ? press(argument,$event) : null" @touchstart="isTouchDevice() ? touchstart(argument,$event) : null" @touchend="isTouchDevice() ? touchend() : null" :class="['polygon',classPolygon,onTouch ? classTouch : null]" :points="points()" :stroke="stroke" :stroke-width="strokeWidth"/>
        <text alignment-baseline="central" v-if="text" x="50%" y="50%" :class="classText">{{ text }}</text>
    </svg>
</template>
<script setup>
defineEmits(["callback"])
</script>
<script>

export default {
    name: "Hexagon",
    props: {
        size: {
            type: Number,
            required: true,
            default: 80,
        },
        argument: {
            type: Number,
            required: false,
        },
        text: {
            type: String,
            required: false,
            default: null,
        },
        static: {
            type: Boolean,
            required: false,
            default: false,
        },
        feedback: {
            type: Boolean,
            required: false,
            default: true,
        },
        stroke: {
            type: String,
            required: false,
            default: "white"
        },
        strokeWidth: {
            type: Number,
            required: false,
            default: 7.5
        },
        classSvg: {
            type: [String,Array],
            required: false,
            default: null
        },
        classPolygon: {
            type: [String,Array],
            required: false,
            default: null
        },
        classText: {
            type: [String,Array],
            required: false,
            default: null
        },
        classTouch: {
            type: [String,Array],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isTouchDevice() {
                return (('ontouchstart' in window) ||
                    (navigator.maxTouchPoints > 0) ||
                    (navigator.hasOwnProperty("msMaxTouchPoints") && navigator.msMaxTouchPoints > 0));
            },
            onTouch: false,
        }
    },
    methods: {
        /**
         *
         * @param argument
         * @param event
         */
        press(argument,event) {
            this.$emit("callback",argument,event)
        },
        /**
         *
         * @param argument
         * @param event
         */
        touchstart(argument,event) {
            this.onTouch = this.feedback;
            this.press(argument,event);
        },
        touchend() {
            this.onTouch = false;
        },
        points() {
            return [
                [0,this.radius() / 2 * Math.sqrt(3)],
                [this.radius() / 2,0],
                [this.radius() / 2 * Math.sqrt(3) * Math.sqrt(3),0],
                [this.size,this.radius() / 2 * Math.sqrt(3)],
                [this.radius() / 2 * Math.sqrt(3) * Math.sqrt(3),this.radius() * Math.sqrt(3)],
                [this.radius() / 2,this.radius() * Math.sqrt(3)]
            ]
                .map(p => p.join(','))
                .join(' ');

            // size = 120, radius = 60
            // 0,51.96152422706631 = 0, radius / 2 * sqrt 3
            // 30,0 = radius / 2, 0
            // 90,0 = radius / 2 * sqrt 3 * sqrt 3, 0
            // 120,51.96152422706631 = size, radius / 2 * sqrt 3
            // 90,103.92304845413263 = radius / 2 * sqrt 3 * sqrt 3, radius * sqrt 3
            // 30,103.92304845413263 = radius / 2, radius * sqrt 3
        },
        radius() {
            return this.size/2;
        },
        /**
         *
         * @param n
         * @returns {number}
         */
        longitude(n) {
            return this.radius() * Math.sqrt(3) / 2 * n / Math.sqrt(3);
        }
    },
}
</script>
