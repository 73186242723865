<template>
    <div class="static">
        <ol v-if="fetchData" class="relative border-l border-gray-200 dark:border-gray-700" data-accordion="collapse" data-active-classes="bg-gray-50 dark:bg-gray-700 rounded-b-none active" data-inactive-classes="bg-white dark:bg-gray-800 inactive">
            <li class="mb-10 ml-4" v-for="day in entries">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{ day.dateToString }}</time>
                <ol class="w-full sm:w-96 text-sm font-medium text-gray-900 border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-600">
                    <li class="w-full">
                        <button :data-accordion-target="'#accordion-collapse-body-' + day.date + '-' + day.nr" :aria-controls="'accordion-collapse-body-' + day.date + '-' + day.nr" class="group rounded-lg w-full px-4 py-2 flex cursor-pointer inactive hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700" v-bind:id="'accordion-collapse-heading-' + day.date + '-' + day.nr">
                            <icon v-if="day.game.end && !day.game.isogramFound" icon="check-circle" classes="h-5 w-5 mr-2 text-green-700 dark:text-green-400 contrast:!text-orange-500"></icon>
                            <icon v-else-if="day.game.end && day.game.isogramFound" icon="check-badge" type="solid" classes="h-5 w-5 mr-2 text-green-700 dark:text-green-400 contrast:!text-orange-500"></icon>
                            <icon v-else icon="exclamation-circle" classes="h-5 w-5 mr-2 text-yellow-600 dark:text-yellow-300 contrast:!text-sky-500"></icon>
                            <span class="text-left grow text-gray-500 dark:text-gray-300">Isogramm #{{ day.nr }}</span>
                            <!-- Solution hide -->
                            <span v-if="day.game.end" class="group-[.active]:hidden text-gray-500 text-xl leading-none relative -bottom-1 dark:text-gray-300">
                                <span class="mr-1" v-for="ignore in day.game.isogram.length">*</span>
                            </span>
                            <!-- /Solution hide -->
                            <!-- Solution visible -->
                            <span v-if="day.game.end"
                                  class="group-[.inactive]:hidden text-gray-500 mr-2 dark:text-gray-300">
                                {{ day.game.isogram }}
                            </span>
                            <!-- /Solution visible -->
                            <!-- Eye closed -->
                            <icon v-if="day.game.end" icon="eye-slash"
                                  classes="group-[.active]:hidden w-5 h-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Eye closed -->
                            <!-- Eye open -->
                            <icon v-if="day.game.end" icon="eye"
                                  classes="group-[.inactive]:hidden w-5 h-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Eye open -->
                            <!-- Arrow down -->
                            <icon v-if="!day.game.end" icon="chevron-down"
                                  classes="group-[.active]:hidden h-5 w-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Arrow down -->
                            <!-- Arrow up -->
                            <icon v-if="!day.game.end" icon="chevron-up"
                                  classes="group-[.inactive]:hidden h-5 w-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Arrow up -->
                        </button>
                        <div v-bind:id="'accordion-collapse-body-' + day.date + '-' + day.nr" class="hidden">
                            <div v-if="!day.game.end" class="p-5 border-gray-200 dark:border-gray-600 border-t">
                                <p class="text-gray-500 dark:text-gray-300" v-if="!day.game.end && day.game.points > 0">
                                    Isogramm #{{ day.nr }} {{ $addressingUser('hast du', 'haben Sie') }} angefangen aber
                                    nicht zu Ende gespielt. Spiele{{ $addressingUser('', 'n Sie') }} jetzt weiter.
                                </p>
                                <p class="text-gray-500 dark:text-gray-300" v-else-if="day.game.points === 0">
                                    Isogramm #{{ day.nr }} {{ $addressingUser('hast du', 'haben Sie') }} noch nicht
                                    gespielt.{{
                                        today !== day.date ? (" " + $addressingUser('Du kannst', 'Sie können') + ' dies jetzt nachholen.') : ''
                                    }}
                                </p>
                                <router-link :to="routeArchive(day.date)" custom v-slot="{ navigate }">
                                    <div class="grid justify-items-end">
                                        <button @click="navigate"
                                                class="btn-default btn-medium mt-3 inline-flex items-center justify-self-end">
                                            {{
                                                day.game.points === 0 ? (today !== day.date ? 'Spielen' : 'Zur Startseite') : 'Weiterspielen'
                                            }}
                                            <icon icon="chevron-right" classes="h-4 w-4 ml-1 -mr-1"></icon>
                                        </button>
                                    </div>
                                </router-link>
                            </div>
                            <div v-else class="border-gray-200 dark:border-gray-600 border-t">
                                <div class="flex p-5 border-b dark:border-gray-600 bg-gray-100 dark:bg-gray-800"
                                     v-if="day.game.end">
                                    <icon icon="rocket-launch"
                                          :classes="'shrink-0 h-9 w-9 inline-flex items-center p-1.5 rounded ' + (day.game.hasTopScore ? 'bg-brand-500 text-gray-800 dark:text-gray-700' : 'bg-gray-600 text-gray-200 dark:bg-gray-400 dark:text-gray-700')"></icon>
                                    <div class="ml-2 grow pr-12">
                                        <p class="text-sm text-gray-900 dark:text-white flex items-center">
                                            <strong class="mr-1">Top-Score:</strong>
                                            <span class="inline-flex items-center">
                                                <span class="mr-1">{{ day.game.topScore }}</span> Wörter
                                            </span>
                                        </p>
                                        <p v-if="day.game.hasTopScore"
                                           class="text-sm text-gray-900 dark:text-white underline decoration-brand-500 decoration-2">
                                            {{ $addressingUser('Du hast', 'Sie haben') }} den Top-Score erreicht</p>
                                        <p v-else class="text-sm text-gray-900 dark:text-white">Mit
                                            {{ day.game.countWords }} gefundenen Wörtern {{
                                                $addressingUser('lagst du', 'lagen Sie')
                                            }}{{ day.game.aboveTopScore <= 5 ? ' nur' : '' }} <strong
                                                class="underline decoration-brand-500 decoration-2">{{
                                                    day.game.aboveTopScore
                                                }} {{ day.game.aboveTopScore === 1 ? "Wort" : "Wörter" }} über dem
                                                Top-Score</strong></p>
                                    </div>
                                </div>
                                <div class="flex justify-between dark:bg-gray-900 space-x-4 p-5" v-if="day.game.end">
                                    <div class="whitespace-pre-line tracking-wide shrink">
                                        <div class="aspect-square h-24 max-w-sm honeycomb relative select-none mx-auto"
                                             id="game-square">
                                            <Hexagon stroke="white" :feedback="false"
                                                     :text="String.fromCharCode(day.game.letter)" :size="30"
                                                     class-text="cell-inner select-none fill-brand-contrast text-gray-600 uppercase text-xxs pointer-events-none"
                                                     :class-svg="['cell select-none absolute top-1/3 h-1/3 left-0 right-0 mx-auto']"
                                                     :stroke-width="3.5"
                                                     :class-polygon="'select-none fill-brand-500 cursor-default'"></Hexagon>
                                            <Hexagon v-for="letter in day.game.mandatory" stroke="white"
                                                     :feedback="false" :text="String.fromCharCode(letter)" :size="30"
                                                     :class-text="['select-none cell-inner text-gray-600 uppercase text-xxs']"
                                                     :class-svg="['cell select-none absolute top-1/3 h-1/3 left-0 right-0 mx-auto']"
                                                     :stroke-width="3.5"
                                                     :class-polygon="'select-none fill-stone-200 cursor-default'"></Hexagon>
                                        </div>
                                    </div>
                                    <div class="flex-1">
                                        <button
                                            class="btn btn-default btn-medium w-full btn-default inline-flex items-center flex-none"
                                            @click="copyToClipboard(buildShareText(day.nr,day.game.countWords,day.game.points,day.game.mandatory,day.game.letter,day.game.isogramsFound,day.game.hasTopScore))">
                                            <icon icon="document-duplicate" classes="h-4 w-4 mr-1 -ml-1"></icon>
                                            Ergebnis kopieren
                                        </button>
                                        <button
                                            class="btn btn-default btn-medium w-full btn-default inline-flex items-center mt-3 flex-none"
                                            v-if="$store.getters.canShare"
                                            @click="shareResult(day.nr,day.game.countWords,day.game.points,day.game.mandatory,day.game.letter,day.game.isogramsFound,day.game.hasTopScore)">
                                            <icon icon="share" classes="h-4 w-4 mr-1 -ml-1"></icon>
                                            Ergebnis teilen
                                        </button>
                                    </div>
                                </div>
                                <div class="p-5 relative border-t space-y-1.5 dark:border-gray-600" v-if="day.game.end">
                                    <span
                                        class="absolute -top-3 border dark:border-gray-600 right-5 bg-white dark:bg-gray-700 text-gray-800 text-xs font-medium ml-2 px-2.5 py-0.5 rounded-full dark:text-gray-300 flex items-center">
                                        <icon icon="arrow-down"
                                              classes="h-3 w-3 mr-1"></icon>{{ $addressingUser('Deine', 'Ihre') }} Wörter
                                    </span>
                                    <span v-for="word in day.game.words"
                                          :class="word.term === day.game.isogram ? 'fill-brand-contrast bg-brand-500' : 'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-300'"
                                          class="mr-1 inline-block relative text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">
                                        {{ word.term }}
                                        <sup>{{ word.points }}</sup>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </li>
        </ol>
        <Spinner v-else classes="absolute left-1/2 top-30 -ml-4 w-8 h-8 contrast:fill-orange-500"></Spinner>
    </div>
</template>

<script>
import Share from "@mixins/share.mix.js"
import Icon from "../components/Icon"
import {initAccordions} from "flowbite"
import Spinner from "../components/Spinner.vue"
import Hexagon from "../../../components/Hexagon.vue";

export default {
    name: "Archive",
    components: {
        Hexagon,
        Spinner,
        Icon
    },
    mixins: [
        Share
    ],
    data() {
        return {
            fetchData: false,
            countDays: 1,
            entries: [],
            accordionItems: [],
        }
    },
    computed: {
        puzzle: function () {
            return this.$store.getters.getPuzzleById(this.today);
        },
        today: function () {
            return this.$store.getters.today;
        }
    },
    created() {
        this.countDays += this.$config.setup.game["max_archive_days"];
    },
    mounted() {
        new Promise((resolve, reject) => {
            this.$store.dispatch("getGames")
                .then(() => {
                    resolve(this.fetchData = true)
                })
                .catch(error => reject(error));
        })
            .then(async () => {
                await this.setTimeline();
                initAccordions();
            })
            .finally(() => {
                this.fetchData = true;
            });
    },
    methods: {
        setTimeline() {
            const today = new Date(new Date(this.today).setHours(0, 0, 0, 0));

            for (let i = 0; i < this.countDays; i++) {
                let date = new Date(new Date(this.today).setHours(0, 0, 0, 0));
                date.setDate(today.getDate() - i);
                let dateKey = this.dateKey(date);
                let game = this.getGame(dateKey);

                if (!game) {
                    continue;
                }

                this.entries[i] = {
                    nr: this.puzzle.nr - i,
                    date: dateKey,
                    day: date.getDay(),
                    dateToString: this.dateToString(i, date),
                    game: game,
                };
            }
        },
        /**
         *
         * @param date
         * @returns {{query: {}, name: string}}
         */
        routeArchive(date) {
            const query = {};

            if (this.today !== date) {
                query["date"] = date;
            }

            return {
                name: "Home",
                query: query
            }
        },
        /**
         *
         * @param date
         * @returns {string}
         */
        dateKey(date) {
            const dd = String(date.getDate()).padStart(2, '0'),
                mm = String(date.getMonth() + 1).padStart(2, '0'),
                yyyy = date.getFullYear();

            return yyyy + '-' + mm + '-' + dd;
        },
        /**
         *
         * @param index
         * @param date
         * @returns {string}
         */
        dateToString(index, date) {
            let string = "";

            if (index === 0) {
                string += "Heute";
            } else if (index === 1) {
                string += "Gestern";
            } else {
                string += date.toLocaleString("de-de", {
                    weekday: "long"
                });
            }

            string += ", ";
            string += String(date.getDate()).padStart(2, "0");
            string += ". ";
            string += date.toLocaleString("de-de", {
                month: "long"
            });

            return string;
        },
        /**
         *
         * @param date
         * @returns {{countWords: (*|number), isogram: (string|*|string), isogramsFound: (*|number), aboveTopScore: number, topScore: number, letter, words: (*|null), hasTopScore: boolean, end: (boolean|boolean), isogramFound: (boolean|boolean), mandatory: *, points: (*|number)}|null}
         */
        getGame(date) {
            const puzzle = this.$store.getters.getPuzzleById(date);

            if (!puzzle) {
                return null;
            }

            const game = this.$store.getters.getGameById(date);
            const isogram = this.decrypt(puzzle.isogram.toString());

            return {
                end: game?.end === true ?? false,
                isogram: this.arrayCharKeyToString(isogram),
                isogramFound: game ? game.input.filter((solution) => solution.term === this.arrayCharKeyToString(isogram)).length > 0 : false,
                isogramsFound: game ? game.input.filter((solution) => this.isIsogram(isogram.length, solution.term)).length : 0,
                letter: puzzle.letter,
                mandatory: this.shuffle(this.mandatoryLetters(isogram, puzzle.letter)),
                points: game?.points ?? 0,
                countWords: game?.input.length ?? 0,
                words: game?.input ?? null,
                topScore: puzzle.min ?? 0,
                hasTopScore: game?.input.length <= puzzle.min,
                aboveTopScore: game?.input.length - puzzle.min
            };
        }
    },
    beforeRouteEnter() {
        if (!store.getters.getConfig.setup?.features?.archive || store.getters.getConfig.setup?.features?.archive !== true) {
            router.replace({
                name: "Home"
            });
        }
    }
}
</script>
