<template>
    <div class="container max-w-4xl mx-auto">
        <div class="static">
            <div id="alert-border-2" class="flex p-4 mb-4 bg-red-100 border-t-4 border-red-500 dark:bg-red-200" role="alert">
                <icon icon="information-circle" classes="flex-shrink-0 w-5 h-5 text-red-700"></icon>
                <div class="ml-2 text-sm font-medium text-red-700">
                    <strong>Achtung:</strong> {{ $addressingUser('Dein','Ihr') }} bisheriger Fortschritt könnte verloren gehen.
                </div>
            </div>
            <p class="my-2 text-sm text-gray-500 dark:text-gray-400">Es existieren zwei verschiedene Spielstände.
                Bitte wähle{{ $addressingUser('','n Sie') }} aus, <strong>welcher der Spielstände erhalten bleiben</strong> soll:</p>
            <div @click="selectScore('cloud')" :class="selectedScore === 'cloud' ? 'active border-brand-500 dark:border-brand-300' : 'dark:border-gray-700'" class="group cursor-pointer mt-4 py-4 max-w-sm mx-auto bg-white rounded-lg border shadow-md dark:bg-gray-800">
                <div class="flex items-center px-4">
                    <input id="sync-cloud" type="radio" autofocus v-model="selectedScore" value="cloud" name="sync" class="-mt-1 w-5 h-5 text-brand-500 contrast:text-orange-400 bg-gray-100 border-gray-300 focus:ring-brand-300 dark:focus:ring-brand-500 contrast:focus:!ring-orange-400 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label for="sync-cloud" class="cursor-pointer grow ml-3 text-xl font-medium text-gray-900 dark:text-white">Cloud</label>
                    <icon icon="cloud-arrow-down" classes="h-8 w-8 text-gray-400 group-[.active]:text-brand-500 dark:group-[.active]:text-brand-300 dark:text-gray-200"></icon>
                </div>
                <div class="flex justify-center gap-4 dark:text-gray-300 my-5 p-4 border-t border-b border-gray-200 dark:border-gray-700">
                    <div class="text-center" v-if="cloud">
                        <div class="flex-none font-bold text-2xl">{{ cloud.games }}</div>
                        <label class="block text-sm break-words">x gespielt</label>
                    </div>
                    <div class="text-center" v-if="cloud">
                        <div class="flex-none font-bold text-2xl" v-if="cloud.daysAgo === null || cloud.daysAgo > 0"><span class="text-sm font-normal">vor{{ cloud.daysAgo === null ? " mehr als" : "" }}</span> {{ cloud.daysAgo ?? maxArchivedDays }}</div>
                        <div class="flex-none font-bold text-2xl" v-else>Heute</div>
                        <label class="block text-sm break-words">{{ cloud.daysAgo > 0 || cloud.daysAgo === null ? "Tagen " : ""}}zuletzt gespielt</label>
                    </div>
                </div>
                <div class="px-4">
                    <p class="text-sm text-gray-500 dark:text-gray-400">{{ $addressingUser('Du hast','Sie haben') }} an {{ cloud?.lastArchiveDays > 0 ? cloud.lastArchiveDays : "keinem" }} der letzten {{ maxArchivedDays }} Tage gespielt.</p>
                </div>
            </div>
            <div @click="selectScore('local')" :class="selectedScore === 'local' ? 'active border-brand-500 dark:border-brand-300' : 'dark:border-gray-700'" class="group cursor-pointer mt-4 py-4 max-w-sm mx-auto bg-white rounded-lg border shadow-md dark:bg-gray-800">
                <div class="flex items-center px-4">
                    <input id="sync-local" type="radio" autofocus v-model="selectedScore" value="local" name="sync" class="-mt-1 w-5 h-5 text-brand-500 contrast:text-orange-400 bg-gray-100 border-gray-300 focus:ring-brand-300 dark:focus:ring-brand-500 contrast:focus:!ring-orange-400 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label for="sync-local" class="cursor-pointer grow ml-3 text-xl font-medium text-gray-900 dark:text-white">Lokale Spieldaten</label>
                    <icon icon="circle-stack" classes="h-8 w-8 text-gray-400 group-[.active]:text-brand-500 dark:group-[.active]:text-brand-300 dark:text-gray-200"></icon>
                </div>
                <div class="flex justify-center gap-4 dark:text-gray-300 my-5 p-4 border-t border-b border-gray-200 dark:border-gray-700">
                    <div class="text-center">
                        <div class="flex-none font-bold text-2xl">{{ local.games }}</div>
                        <label class="block text-sm break-words">x gespielt</label>
                    </div>
                    <div class="text-center">
                        <div class="flex-none font-bold text-2xl" v-if="local.daysAgo === null || local.daysAgo > 0"><span class="text-sm font-normal">vor{{ local.daysAgo === null ? " mehr als" : "" }}</span> {{ local.daysAgo ?? maxArchivedDays }}</div>
                        <div class="flex-none font-bold text-2xl" v-else>Heute</div>
                        <label class="block text-sm break-words">{{ local.daysAgo > 0 || local.daysAgo === null ? "Tagen " : ""}}zuletzt gespielt</label>
                    </div>
                </div>
                <div class="px-4">
                    <p class="text-sm text-gray-500 dark:text-gray-400">{{ $addressingUser('Du hast','Sie haben') }} an {{ local.lastArchiveDays > 0 ? local.lastArchiveDays : "keinem" }} der letzten {{ maxArchivedDays }} Tage gespielt.</p>
                </div>
            </div>
            <p class="mt-5 text-sm text-gray-500 dark:text-gray-400">{{ $addressingUser('Du hast','Sie haben') }} den Spielstand "{{ selectedScore === "local" ? 'Lokal' : 'Cloud' }}" ausgewählt.
                Wenn {{ $addressingUser('du','Sie') }} mit diesem Spielstand fortfahren möchte{{ $addressingUser('st','n') }},
                dann bestätige{{ $addressingUser('','n Sie') }} nachfolgend {{ $addressingUser('deine','Ihre') }} Auswahl. Der andere Spielstand wird unwiderruflich gelöscht.</p>
            <button class="group relative btn btn-primary w-full my-5" @click="handleSubmit">
                <span v-if="!processing" class="absolute left-0 inset-y-0 flex items-center pl-3">
                    <icon icon="cloud-arrow-up" classes="h-5 w-5 text-brand-contrast group-hover:text-brand-500 contrast:text-orange-300 contrast:group-hover:text-orange-500"></icon>
                </span>
                <span v-else class="absolute left-0 inset-y-0 flex items-center pl-3">
                    <Spinner classes="h-5 w-5"></Spinner>
                </span>
                Spielstand fortsetzen
            </button>
        </div>
    </div>
    <Confirm @callback="sync" ref="confirm" :msg="'Möchte' + $addressingUser('st du','n Sie') + ' den Spielstand ' + (selectedScore === 'cloud' ? 'aus der Cloud' : 'von ' + $addressingUser('deinem','Ihrem') + ' lokalen System') + ' behalten?'"></Confirm>
</template>
<script>
import Icon from "../components/Icon";
import Spinner from "../components/Spinner.vue";
import Confirm from "../components/Confirm.vue";

export default {
    name: "Sync",
    components: {
        Confirm,
        Spinner,
        Icon
    },
    computed: {
        today() {
            return this.$store.getters.today;
        },
        storage() {
            return this.$store.getters["auth/getStorage"];
        },
        minDate() {
            const date = new Date(new Date(this.today).setHours(24, 0, 0, 0));
            return date.setDate(date.getDate() - 1);
        },
        maxDate() {
            const date = new Date(new Date(this.today).setHours(0, 0, 0, 0));
            return date.setDate(date.getDate() - this.maxArchivedDays)
        },
        local() {
            return {
                games: this.$store.getters.getStat("games"),
                daysAgo: this.daysAgo(this.$store.getters.getAllGames),
                lastArchiveDays: this.gamesPlayedLastDays(this.minDate, this.maxDate, this.$store.getters.getAllGames)
            }
        },
        cloud() {
            return {
                games: this.storage?.stats?.games ?? 0,
                daysAgo: this.daysAgo(this.storage?.games),
                lastArchiveDays: this.gamesPlayedLastDays(this.minDate, this.maxDate, this.storage?.games)
            }
        }
    },
    data() {
        return {
            confirm: false,
            maxArchivedDays: this.$config.setup?.game["max_archive_days"] ?? 7,
            config: this.$config,
            processing: false,
            selectedScore: "cloud", // TODO: Pre-Select the score with "best" data
            scores: {
                cloud: {
                    games: this.storage?.stats?.games ?? 0,
                    daysAgo: this.daysAgo(this.storage?.games),
                    lastArchiveDays: this.gamesPlayedLastDays(this.minDate, this.maxDate,this.storage?.games)
                },
                local: {
                    games: this.$store.getters.getStat("games"),
                    daysAgo: this.daysAgo(this.$store.getters.getAllGames),
                    lastArchiveDays: this.gamesPlayedLastDays(this.minDate, this.maxDate, this.$store.getters.getAllGames)
                }
            }
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.$refs.confirm.show();
            /*if(window.confirm("Möchte" + this.$addressingUser("st du","n Sie") + " den Spielstand " + (this.selectedScore === "cloud" ? "aus der Cloud" : "von " + this.$addressingUser("deinem","Ihrem") + " lokalen System") + " behalten?") && this.selectedScore && this.storage) {
            }*/
        },
        /**
         *
         * @returns {Promise<void>}
         */
        async sync() {
            this.processing = true;
            /**
             * Cloud:
             *  Wenn aus der Cloud, dann muss nur der localStorage aktualisiert werden.
             *  Es ist kein Server-Request notwendig.
             *
             * Local:
             *  Wenn Local, dann muss ein Server-Request erfolgen.
             *  Der localStorage muss nicht aktualisiert werden.
             */

            if (this.selectedScore === "cloud") {
                await this.$store.dispatch("auth/saveToLocalStorage", {
                    key: "user-stats",
                    val: JSON.stringify(this.storage?.stats ?? [])
                });

                await this.$store.dispatch("auth/saveToLocalStorage", {
                    key: "user-games",
                    val: JSON.stringify(this.storage?.games ?? [])
                });

                await this.$store.dispatch("setUser");
                await this.$store.dispatch("auth/setSynced");

                this.processing = false;
                this.$store.dispatch("showToast", {
                    msg: "Spieldaten wurden erfolgreich synchronisiert.",
                    type: "success"
                });
                router.push({
                    name: "Home"
                });
            } else {
                await this.$store.dispatch("auth/setSynced");

                this.$store.dispatch("auth/sync", {
                    stats: this.$store.getters.getStats,
                    games: this.$store.getters.getAllGames,
                })
                    .then(response => {
                        this.$store.dispatch("showToast", {msg: response.data.message, type: "success"});
                        router.push({name: "Home"});
                    })
                    .finally(() => {
                        this.processing = false
                    })
                    .catch(error => {
                        this.offlineToast(error).catch(() => this.$store.dispatch("showToast", {msg: this.config.content.messages.error}));
                    });
            }
        },
        /**
         *
         * @param select
         */
        selectScore(select) {
            this.selectedScore = select;
        },
        /**
         *
         * @param start
         * @param end
         * @param games
         * @returns {number}
         */
        gamesPlayedLastDays(start,end,games) {
            if(!games || Object.keys(games).length < 1) {
                return 0;
            }

            let count = 0;

            for(const date in games) {
                const currentDate = new Date(date);
                if(currentDate <= start && currentDate >= end) {
                    count++;
                }
            }

            return count;
        },
        /**
         *
         * @returns {number|null}
         */
        daysAgo(games) {
            if(!games || Object.keys(games).length < 1) {
                return null;
            }

            const dates = Object.keys(games).map(date => new Date(date));
            const minDate = new Date(Math.max.apply(null, dates));
            const today = new Date();
            const diffInTime = today.getTime() - minDate.getTime();
            const diffInDays = diffInTime / (1000 * 3600 * 24);

            return Math.floor(diffInDays);
        },
    },
}
</script>
