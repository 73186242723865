import { config } from "../config"

export const user = {
    state: () => ({
        localStorageOrDataExists: false,
        canShare: window.navigator && window.navigator.share !== undefined,
        stats: {
            games: 0,
            isograms: 0,
            words: 0,
            deviations: {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "5+": 0
            }
            //...
        },
        setup: {
            darkMode: false,
            //...
        },
    }),
    actions: {
        /**
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @returns {Promise<unknown>}
         */
        setUser: ({commit,dispatch,getters}) => {
            return new Promise(async (resolve) => {
                if (typeof getters.getConfig === "function") {
                    config = getters.getConfig;
                }

                /**
                 * Es wird sich ausschließlich aus dem localStorage bedient.
                 * Nur bei der Registrierung, beim Login und beim Abschluss eines Spiels gibt es eine Synchronisierung mit der DB
                 */
                const data = {
                    stats: JSON.parse(localStorage.getItem(config.identifier + "-user-stats")) || {},
                    games: JSON.parse(localStorage.getItem(config.identifier + "-user-games")) || {}
                };

                if (Object.keys(data.stats).length > 0 || Object.keys(data.games).length > 0) {
                    /**
                     * setExistingData === true verhindert,
                     * dass der Init-Layer erneut angezeigt wird.
                     */
                    commit("setExistingData",Object.keys(data.games).length > 0 || (Object.keys(data.stats).length > 0 && data.stats.games > 0));

                    /**
                     * Die Daten werden explizit gesetzt,
                     * um zu verhindern, dass die Daten manipuliert werden.
                     */
                    commit("setStats", {
                        games: data.stats.games,
                        isograms: data.stats.isograms,
                        words: data.stats.words,
                        deviations: data.stats.deviations
                        //...
                    });

                    dispatch("setGames", data.games);
                    commit("sortGames");
                }
                /**
                 * No data exist; init localStorage.
                 */
                else {
                    dispatch("writeData");
                }

                /**
                 * Die Einstellungen können jederzeit abgefragt und gespeichert werden
                 */
                commit("setSetup", {
                    key: "darkMode",
                    _class: "dark",
                    status: JSON.parse(localStorage.getItem(config.identifier + "-pref-" + "darkMode".toLowerCase())) ?? getters.isDarkMode
                });

                commit("auth/setSynced", localStorage.getItem(config.identifier + "-user-synced") !== "false");

                resolve(true);
            });
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @param sync
         * @returns {Promise<void>}
         */
        writeData: async ({dispatch,getters},sync = false) => {
            const stats = JSON.stringify(getters.getStats || {}),
                games = JSON.stringify(getters.getAllGames || {});

            if(sync === true) {
                dispatch("auth/sync",{
                    stats: JSON.parse(stats),
                    games: JSON.parse(games)
                });
            }

            await dispatch("writeToLocalStorage",{
                key: "user-stats",
                val: stats
            });
            await dispatch("writeToLocalStorage",{
                key: "user-games",
                val: games
            });
        },
        /**
         *
         * @param {*} param0
         * @param {*} param1
         */
        writeToLocalStorage: ({commit},{key,val}) => {
            localStorage.setItem(config.identifier + "-" + key,val);
        },
    },
    mutations: {
        /**
         *
         * @param state
         * @param games
         * @param isograms
         * @param words
         * @param deviations
         */
        setStats: (state,{
            games,isograms,words,deviations
        }) => {
            state.stats.games = parseInt(games) || 0;
            state.stats.isograms = parseInt(isograms) || 0;
            state.stats.words = parseInt(words) || 0;
            state.stats.deviations = deviations || {
                "0": 0,
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "5+": 0
            };
            //...
        },
        /**
         *
         * @param state
         * @param status
         */
        setExistingData: (state,status) => {
            state.localStorageOrDataExists = status;
        },
        /**
         *
         * @param state
         * @param key
         * @param _class
         * @param status
         */
        setSetup: (state,{key,_class,status = false}) => {
            state.setup[key] = status;

            if(status && _class) {
                document.querySelector("html").classList.add(_class);
            }
            else if (_class) {
                document.querySelector("html").classList.remove(_class);
            }
        }
    },
    getters: {
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isDarkMode: state => {
            return state.setup.darkMode;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        canShare: state => {
            return state.canShare;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        getStats: state => {
            return state.stats;
        },
        /**
         *
         * @param state
         * @returns {(function(*): (*|null))|*}
         */
        getStat: state => (key) =>  {
            if(state.stats.hasOwnProperty(key)) {
                return state.stats[key];
            }

            return null;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        dataExists: state => {
            return state.localStorageOrDataExists;
        },
    }
}
