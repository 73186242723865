<template>
    <SVG :svg="paywall.icon" :classes="classes"></SVG>
</template>

<script>
import SVG from "./SVG.vue";

export default {
    components: {
        SVG
    },
    data() {
        return {
            paywall: this.$config.paywall,
        }
    },
    props: {
        classes: {
            type: Array,
            default: ["w-4","h-4","fill-current"]
        }
    }
}
</script>