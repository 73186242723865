export default {
    methods: {
        /**
         *
         * @returns {string|null}
         */
        getSSOLoginUrl() {
            if(!this.$config.sso.custom) {
                return router.resolve({name: "Login"}).href;
            }

            let url = this.$config.sso.login_url;

            if(this.$config.sso.redirect_key) {
                url += (this.$config.sso.login_url.indexOf("?") > -1 ? "&" : "?")
                    + this.$config.sso.redirect_key
                    + "="
                    + this.getRedirectUrl()
            }

            return url;
        },
        /**
         *
         * @returns {string|null}
         */
        getSubscribeUrl() {
            if(!this.$config.paywall.enabled) {
                return null;
            }

            return this.$config.paywall.url
                + (this.$config.paywall.url.indexOf("?") > -1 ? "&" : "?")
                + this.$config.paywall.redirect_key
                + "="
                + this.getRedirectUrl()
        },
        /**
         *
         * @returns {string}
         */
        getRedirectUrl() {
            return location.protocol
                + "//"
                + this.$config.common.domain
                + (this.$config.common.path ? this.$config.common.path : "")
            // TODO: Remove this.$config.common.path
            // TODO: check if in iframe, then use parent url
        }
    }
}