<template>
    <component :is="renderBrandTag" v-if="!iframe">
        <router-link :to="{name:'Home'}">
            <template v-if="logo">
                <SVG :svg="logo" :classes="['w-40','h-9','dark:text-white','fill-current']"></SVG>
                <em class="brand sr-only">{{ name }}</em>
            </template>
            <slot v-else></slot>
        </router-link>
    </component>
    <router-link v-else-if="!isHome" :to="{name:'Home'}" class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:text-gray-500 dark:hover:bg-gray-700">
        <icon icon="arrow-uturn-left"></icon>
    </router-link>
</template>

<script>
import SVG from "./SVG.vue";
import Icon from "./Icon.vue";

export default {
    name: "Logo",
    props: {
        isHome: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            logo: this.$config.layout.logo,
            name: this.$config.name
        }
    },
    components: {
        Icon,
        SVG
    },
    computed: {
        /**
         *
         * @returns {string}
         */
        renderBrandTag: function () {
            return this.isHome ? "h1" : "div";
        },
        iframe() {
            return this.$integration() !== "dns" || this.inIframe()
        }
    },
    methods: {
        /**
         *
         * @returns {boolean}
         */
        inIframe() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return false;
            }
        }
    }
}
</script>
