<template>
    <div class="flex" v-if="enabled && $config.common?.with_authentication && !$store.getters['auth/isAuth']">
        <icon icon="user" classes="h-6 w-6 flex-none text-gray-600 dark:text-gray-500 mr-1.5"></icon>
        <p class="text-gray-900 text-sm dark:text-gray-300">
            <a v-if="$config.sso.custom" :href="$config.sso.login_url" @click="$handleLogin" class="link-intern">Logge{{ $addressingUser(' dich','n Sie sich') }} jetzt ein</a>
            <router-link v-else :to="{name:'Login'}" class="link-intern">Logge{{ $addressingUser(' dich','n Sie sich') }} jetzt ein</router-link>, um <strong>verpasste Rätsel</strong> der letzten {{ $config.setup.game.max_archive_days }} Tage im <strong>Archiv</strong> zu spielen.
        </p>
    </div>
    <div class="flex" v-else-if="enabled && (!$config.common?.with_authentication || $store.getters['auth/isAuth'])">
        <icon icon="calendar-days" classes="h-6 w-6 flex-none text-gray-600 dark:text-gray-500 mr-1.5"></icon>
        <p class="text-gray-900 text-sm dark:text-gray-300">
            <router-link :to="{name:'Archive'}" class="link-intern">Besuche{{ $addressingUser('','n Sie') }} das Archiv</router-link>, um <strong>verpasste Rätsel</strong> der letzten {{ $config.setup.game.max_archive_days }} Tage zu spielen.
        </p>
    </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
    name: "ActionPromoteArchive",
    data() {
        return {
            enabled: this.$archive.enabled()
        }
    },
    components: {
        Icon
    },
}
</script>