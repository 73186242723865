<template>
    <div v-show="show" :class="classes['toast'][type]" class="fixed z-50 top-4 right-16 flex items-center w-full max-w-xs p-4 rounded-lg shadow" role="alert">
        <div :class="classes['badge'][type]" class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg">
            <icon :icon="icons[type]" classes="w-5 h-5"></icon>
        </div>
        <div class="ml-3 text-sm font-normal">
            <span v-html="message"></span>
        </div>
        <button v-if="!timeout" @click="close()" :class="classes['close'][type]" type="button" class="ms-auto border border-transparent -mx-1.5 -my-1.5 justify-center items-center flex-shrink-0 text-gray-400 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 inline-flex h-8 w-8 dark:bg-gray-800" data-dismiss-target="#toast-message-cta" aria-label="Close">
            <span class="sr-only">Schließen</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
        </button>
    </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
    name: "Toast",
    components: {
        Icon
    },
    computed: {
        /**
         *
         * @returns {*}
         */
        show() {
            return this.$store.state.toast.show;
        },
        /**
         *
         * @returns {*}
         */
        type() {
            return this.$store.getters.getType;
        },
        /**
         *
         * @returns {(function(*): string)|*}
         */
        message() {
            return this.$store.getters.getMessage;
        },
        /**
         *
         * @returns {(function(*): string)|*}
         */
        timeout() {
            return this.$store.getters.getTimeout;
        }
    },
    data() {
        return {
            classes: {
                toast: {
                    success: "text-green-700 border-t-4 border-green-600 bg-green-50 dark:text-green-400 dark:bg-gray-800 dark:border-green-800\" role=\"alert\">",
                    warn: "text-orange-700 border-t-4 border-orange-600 bg-orange-50 dark:text-orange-300 dark:bg-gray-800 dark:border-orange-800",
                    offline: "text-gray-700 border-t-4 border-gray-600 bg-gray-50 dark:bg-gray-800 dark:border-gray-600"
                },
                badge: {
                    success: "text-green-50 bg-green-600 dark:bg-green-600 dark:text-green-400 contrast:!text-orange-100 contrast:!bg-orange-500",
                    warn: "text-orange-50 bg-orange-600 dark:bg-orange-600 dark:text-orange-300 contrast:!bg-sky-500 contrast:!text-sky-100",
                    offline: "text-grey-50 bg-grey-600 dark:text-grey-100 dark:bg-grey-800"
                },
                close: {
                    success: "text-green-700 hover:border-green-700 dark:text-green-400 dark:hover:border-green-400",
                    warn: "text-orange-600 hover:border-orange-600 dark:text-orange-300 dark:hover:border-orange-300",
                    offline: "text-grey-600 hover:border-grey-600 dark:text-grey-100 dark:hover:border-grey-800"
                }
            },
            icons: {
                success: "check",
                warn: "exclamation-triangle",
                offline: "signal-slash"
            }
        }
    },
    methods: {
        close() {
            this.$store.dispatch("hideToast", {
                timeout: 0
            })
        }
    }
}
</script>
