<template>
    <div :id="modalId" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative w-full max-w-md max-h-full" :style="'width: ' + width + 'px'">
            <div class="relative">
                <button type="button" class="absolute -top-[1.875rem] right-0 text-white bg-transparent text-sm p-1.5 ml-auto inline-flex items-center" @click="modal.hide()">
                    <span>Schließen</span>
                    <icon icon="x-mark" classes="w-5 h-5"></icon>
                </button>
                <div class="bg-white relative rounded-lg shadow transition-[height] overflow-y-scroll" :style="'min-height: ' + minHeight + 'px; max-height: ' + height + 'px; height: ' + height + 'px'" id="sso-iframe-wrapper">
                    <div role="status" v-if="onload" class="absolute left-1/2 top-1/2 -ml-5 -mt-5">
                        <Spinner classes="inline w-10 h-10"></Spinner>
                        <span class="sr-only">Wird geladen...</span>
                    </div>
                    <iframe v-show="!onload" class="h-full w-full" scrolling="no" title="SSO iframe" id="sso-iframe" src="about:blank"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "flowbite"
import Icon from "./Icon";
import { debug } from "../debug"
import Spinner from "./Spinner.vue";

export default {
    name: "ModalSso",
    components: {
        Spinner,
        Icon
    },
    props: ["modalId","loginUrl"],
    computed: {
        token() {
            return sessionStorage.getItem(this.$config.identifier + "-token");
        }
    },
    data() {
        return {
            onload: true,
            modal: null,
            iframe: null,
            actions: this.$config.sso?.iframe_actions ?? {},
            wrapper: null,
            width: this.$config.sso.iframe_width,
            minHeight: this.$config.sso.iframe_height,
            height: this.$config.sso.iframe_height,
        }
    },
    mounted() {
        if(!this.token) {
            sessionStorage.setItem(this.$config.identifier + "-token",self.crypto.randomUUID())
        }
        else {
            this.actionListener();
        }

        const $targetEl = document.getElementById(this.modalId);

        this.iframe = document.getElementById("sso-iframe");
        this.wrapper = document.getElementById("sso-iframe-wrapper");

        const options = {
            placement: "center-center",
            backdrop: "dynamic",
            backdropClasses: "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
            closable: true,
            onHide: () => {
                window.removeEventListener("message",this.receiveMessage);
            },
            onShow: () => {
                this.onload = true;
                this.iframe.src = this.loginUrl;
                this.iframe.onload = () => this.loaded()
            }
        };

        this.modal = new Modal($targetEl,options);
    },
    methods: {
        openModal() {
            this.modal.show();
        },
        /**
         *
         * @param height
         */
        resizeModal(height) {
            this.height = height;
        },
        loaded() {
            this.iframe.style.display = "block";
            this.onload = false;
            setTimeout(() => window.addEventListener("message",this.receiveMessage),500)
        },
        /**
         *
         * @param event
         */
        receiveMessage(event) {
            debug("Receive postMessage:" + event.data);

            try {
                const data = JSON.parse(event.data ?? "[]");
                this.handlePostMessage(data);
            }
            catch(error) {
                debug("Can't handle postMessage: " + error + "!","warn");
            }
        },
        /**
         *
         * @param data
         */
        handlePostMessage(data = []) {
            for (const [action,events] of Object.entries(this.actions)) {
                events.forEach(attr => {
                    this.handleAction(action,attr,data)
                });
            }
        },
        /**
         *
         * @param action
         * @param attr
         * @param data
         */
        handleAction(action = null,attr = [],data = []) {
            const value = (attr["key"] || "").split(".").reduce((a, prop) => a[prop],data)?.toString();

            const actions = {
                "resize": () => {
                    if(value) {
                        this.resizeModal(value);
                        this.showMessage(attr["message"],attr["message_duration"]);
                    }
                },
                "close": () => {
                    this.modal.hide();
                    this.showMessage(attr["message"],attr["message_duration"]);
                },
                "reload": () => {
                    const href = window.location.href;
                    const url = href + (href.indexOf("?") > -1 ? "&" : "?");

                    window.location.href = url + "callback=" + value + "&token=" + this.token;
                },
            };

            if(action && (!attr["val"] || value === attr["val"])) {
                actions[action]();
            }
        },
        /**
         *
         * @param message
         * @param duration
         */
        showMessage(message = "",duration) {
            if(message) {
                this.$store.dispatch("showToast",{
                    msg: message,
                    type: "success",
                    timeout: duration
                });
            }
        },
        actionListener() {
            if(!this.token) {
                sessionStorage.setItem(this.$config.identifier + "-token",self.crypto.randomUUID())
                return;
            }

            const query = window.location.search;
            const params = new URLSearchParams(query);
            const callback = params.get("callback");
            const token = params.get("token");

            if (this.token !== token) {
                return;
            }

            for (const [ignore,events] of Object.entries(this.actions)) {
                events.forEach(attr => {
                    if (attr["val"] === callback) {
                        this.showMessage(attr["message"],attr["message_duration"])
                        this.renewToken();
                    }
                });
            }
        },
        renewToken() {
            sessionStorage.removeItem(this.$config.identifier + "-token")
            sessionStorage.setItem(this.$config.identifier + "-token",self.crypto.randomUUID())
        }
    },
    beforeDestroy() {
        window.removeEventListener("message", this.receiveMessage);
    }
}
</script>