<template>
    <div v-if="config.publications" class="mt-4 pt-4 border-t border-slate-100 dark:border-slate-600">
        <span class="block font-semibold text-slate-900 dark:text-slate-200">Weitere Rätsel</span>
        <a v-for="publication in config.publications" :href="publication.href" class="flex items-center mt-4 lg:mt-3">
            <img v-if="publication.icon" :src="publication.icon" class="w-5 h-5 mr-3" :alt="publication.name">
            <span class="text-sm text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">{{ publication.name }}</span>
        </a>
    </div>
</template>

<script>

export default {
    name: "RelatedPublications",
    data() {
        return {
            config: this.$config
        }
    }
}
</script>