<template>
    <div class="container max-w-4xl mx-auto">
        <div class="flex items-center justify-center pt-8 py-4">
            <div class="max-w-md w-full space-y-8">
                <div>
                    <h1 class="text-center text-3xl font-extrabold text-gray-900 dark:text-white px-4 sm:px-6 lg:px-8">Einloggen</h1>
                    <Advantages></Advantages>
                </div>
                <form class="mt-8 space-y-6 px-4 sm:px-6 lg:px-8" action="#" method="POST" @submit="handleSubmit">
                    <Alert v-if="error" :msg="error"></Alert>
                    <div class="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="email-address" class="sr-only">Email-Adresse</label>
                            <input id="email-address" name="email" type="email" v-model="email" autofocus autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Email-Adresse">
                        </div>
                        <div>
                            <label for="password" class="sr-only">Passwort</label>
                            <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Passwort">
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <label for="remember_me" class="flex relative items-center mr-5 cursor-pointer">
                            <input type="checkbox" name="remember_me" id="remember_me" v-model="remember" class="sr-only peer">
                            <span class="flex-none relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brand-500 contrast:peer-checked:bg-orange-400"></span>
                            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Eingeloggt bleiben</span>
                        </label>
                        <div class="text-sm">
                            <router-link :to="{name:'ForgotPassword'}" class="font-medium link-intern">Passwort vergessen?</router-link>
                        </div>
                    </div>
                    <div>
                        <button type="submit" class="group relative w-full btn-primary">
                            <span v-if="!processing" class="absolute left-0 inset-y-0 flex items-center pl-3">
                                <icon icon="lock-closed" classes="h-5 w-5 text-brand-contrast group-hover:text-brand-500 contrast:text-orange-300 contrast:group-hover:text-orange-500"></icon>
                            </span>
                            <span v-else class="absolute left-0 inset-y-0 flex items-center pl-3">
                                <Spinner classes="h-5 w-5 contrast:fill-orange-500"></Spinner>
                            </span>
                            Einloggen
                        </button>
                    </div>
                    <div>
                        <div v-if="hasSharedPublications()">
                            <p class="relative pl-6 text-sm text-gray-600 dark:text-gray-500">
                                <icon icon="finger-print" classes="h-5 w-5 absolute top-0 left-0 text-gray-600 dark:text-gray-500"></icon>
                                {{ $addressingUser('Du bist','Sie sind') }} bereits bei <span v-html="sharedPublications()"></span> angemeldet? Verwende{{ $addressingUser('','n Sie') }} einfach dieselben Anmeldedaten.
                            </p>
                            <div class="inline-flex items-center justify-center w-full">
                                <hr class="w-64 h-px my-6 bg-gray-200 border-0 dark:bg-gray-700">
                                <span class="absolute px-3 text-sm text-gray-600 -translate-x-1/2 bg-white left-1/2 dark:text-gray-500 dark:bg-gray-900">oder</span>
                            </div>
                        </div>
                        <p class="text-center text-sm text-gray-600 dark:text-gray-500">Noch nicht angemeldet?
                            <router-link :to="{name: 'Register'}" class="font-medium link-intern">
                                Jetzt kostenlos registrieren
                            </router-link>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import axios from "axios"
import Advantages from "../components/Advantages"
import Alert from "../components/Alert"
import { buildRequest, buildURLWithParams } from "../connector-request";
import Icon from "../components/Icon";
import Spinner from "../components/Spinner.vue";

export default {
    name: "Login",
    components: {
        Spinner,
        Icon,
        Advantages,
        Alert
    },
    data() {
        return {
            email: "",
            error: null,
            password: "",
            remember: false,
            processing: false
        }
    },
    watch: {
        '$store.getters["auth/isAuth"]' (status) {
            if(status) {
                router.replace({
                    name:"Home"
                });
            }
        },
    },
    methods: {
        ...mapActions({
            login: "auth/login"
        }),
        handleSubmit(e) {
            e.preventDefault();

            const config = this.$store.getters.getConfig;

            if (!config.hasOwnProperty("identifier")) {
                return;
            }

            if (this.password.length > 0 && this.email.length > 0) {
                this.processing = true;

                axios.post(buildURLWithParams(this.$config.sso.login_url), {
                    email: this.email,
                    password: this.password,
                    remember: this.remember,
                    stats: localStorage.getItem(config.identifier + "-user-stats"),
                    games: localStorage.getItem(config.identifier + "-user-games")
                },buildRequest(null,null,false))
                    .then(result => {
                        this.error = null;
                        this.login(result.data)
                            .then(route => {
                                this.event("Login");
                                router.push(route)
                            });
                    })
                    .finally(() => {
                        this.processing = false;
                    })
                    .catch(error => {
                        if(config.debug) {
                            console.log(error);
                        }

                        if (error.response) {
                            this.error = error.response.data.message;
                        }
                        else if (error.request) {
                            this.error = error.request;
                        }
                        else {
                            this.error = error.message;
                        }
                    });
            }
        },
        /**
         *
         * @returns {boolean}
         */
        hasSharedPublications() {
            return this.$config.publications !== null && this.$config.publications.filter(publication => publication.shared).length > 0;
        },
        /**
         *
         * @returns {string}
         */
        sharedPublications() {
            if(this.hasSharedPublications()) {
                const publications = this.$config.publications.filter(publication => publication.shared);
                const prepared = [];

                if(publications.length > 0) {
                    for(const publication of publications) {
                        prepared.push('<a href="' + publication.href + '" class="inline-flex relative items-center pl-5.5" target="_blank"><img src="' + publication.icon + '" alt="Logo von ' + publication.name + '" class="h-4 w-4 absolute left-0"/><span class="font-bold">' + publication.name + '</span></a>');
                    }
                }

                return prepared.length > 1 ? (prepared.slice(0,-1).join(", ") + " oder ") + prepared.slice(-1) : '' + prepared.slice();
            }

            return "[Error]";
        }
    },
    beforeRouteEnter() {
        if(store.getters["auth/isAuth"] || store.getters.getConfig.sso.custom || !store.getters.getConfig.common.with_authentication) {
            router.replace({
                name:"Home"
            });
        }
    }
}
</script>