<template>
    <div class="container max-w-4xl mx-auto">
        <div class="p-4 pt-12 text-center grid content-center justify-items-center">
            <Spinner v-if="inProgress" classes="w-20 h-20"></Spinner>
            <icon v-else-if="verified" icon="shield-check" classes="h-20 w-20 text-brand-500 contrast:text-orange-500"></icon>
            <icon v-else icon="exclamation-circle" classes="h-20 w-20 text-yellow-400"></icon>
            <p class="text-gray-700 dark:text-gray-400" v-if="message">{{ message }}</p>
            <div v-if="!inProgress" class="flex mt-5 space-x-2">
                <router-link v-if="$store.getters['auth/isAuth']" :to="{name:'Account'}" class="btn btn-default">Zum Profil</router-link>
                <router-link v-else :to="{name:'Login'}" class="btn btn-default">Einloggen</router-link>
                <router-link :to="{name:'Home'}" v-show="verified" class="btn btn-primary">Spielen</router-link>
            </div>
        </div>
    </div>
</template>
<script>

import axios from "axios";
import { buildRequest } from "../connector-request"
import Icon from "../components/Icon"
import Spinner from "../components/Spinner.vue";

export default {
    name: "Verify",
    components: {
        Spinner,
        Icon
    },
    data() {
        return {
            message: null,
            verified: false,
            inProgress: true
        }
    },
    created() {
        const query = window.location.search,
            params = new URLSearchParams(query),
            api = params.get("url") || null;

        if (api) {
            axios.get(this.$config.sso.verify_url + api, buildRequest(this.$store.getters["auth/getToken"], this.$config.sso.auth_scheme))
                .then(response => {
                    this.setMessage(response.data.message);
                    this.verified = true;
                    this.$store.dispatch("auth/verify");
                })
                .finally(() => {
                    this.inProgress = false;
                })
                .catch(error => {
                    if (error.response) {
                        this.error = error.response.data.message ?? this.$config.content.messages.error;
                    } else if (error.request) {
                        this.error = error.request;
                    } else {
                        this.error = error.message;
                    }
                });
        } else {
            this.message = this.$config.text.warn;
            this.inProgress = false;
        }
    },
    methods: {
        /**
         *
         * @param msg
         */
        setMessage(msg = "") {
            this.message = msg;
        }
    }
}
</script>
