export const toast = {
    state: () => ({
        type: "warn",
        message: "",
        show: false,
        timeout: 3000
    }),
    actions: {
        /**
         *
         * @param commit
         * @param dispatch
         * @param msg
         * @param type
         * @param timeout
         * @param callback
         */
        showToast: ({commit,dispatch},{msg,type,timeout = 3000,callback}) => {
            commit("setToastData", {msg,type,timeout});
            commit("setVisibility",true);
            if(timeout > 0) {
                dispatch("hideToast",{callback,timeout});
            }
        },
        /**
         *
         * @param commit
         * @param state
         * @param timeout
         * @param callback
         */
        hideToast: ({commit,state},{timeout,callback}) => {
            setTimeout(() => {
                commit("setVisibility",false);
                if(callback instanceof Function) {
                    callback();
                }
            }, timeout ? timeout : state.timeout);
        }
    },
    mutations: {
        /**
         *
         * @param state
         * @param msg
         * @param type
         * @param timeout
         */
        setToastData: (state,{msg,type,timeout}) => {
            state.type = type || "warn";
            state.message = msg;
            state.timeout = timeout;
        },
        /**
         *
         * @param state
         * @param show
         */
        setVisibility: (state,show) => {
            state.show = show;
        }
    },
    getters: {
        /**
         *
         * @param state
         * @returns {string}
         */
        getType: state => state.type,
        /**
         *
         * @param state
         * @returns {string}
         */
        getMessage: state => state.message,
        /**
         *
         * @param state
         * @returns {string}
         */
        getTimeout: state => state.timeout
    },
}