<template>
    <toast></toast>
    <header class="h-14 flex border-b dark:bg-gray-800 dark:border-gray-600">
        <div class="flex justify-between items-center px-4 mx-auto w-full">
            <div class="flex flex-1 items-center justify-items-start">
                <logo :is-home="$route.name === 'Home'">
                    <span class="uppercase font-semibold text-2xl tracking-wide whitespace-nowrap">
                        <span class="text-gray-700 dark:text-gray-300">W<i class="sr-only">o</i><Honeycomb class="w-5 h-5 inline-block -mt-1 ms-px me-px"></Honeycomb>rt</span><span class="text-brand-500">Wabe</span>
                    </span>
                </logo>
            </div>
            <div v-if="$archive.active(!!$route.query?.date)" class="text-sm text-gray-800 dark:text-gray-400">
                <router-link :to="{name:'Archive'}" title="Zum Archiv" class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:text-gray-400 dark:hover:bg-gray-700">
                    <icon icon="hashtag" classes="h-5 w-5"></icon>
                    <span class="font-medium">{{ nr() }}</span>
                </router-link>
            </div>
            <Nav></Nav>
        </div>
    </header>
    <main>
        <breadcrumb></breadcrumb>
        <router-view ref="meta"></router-view>
    </main>
    <vue-progress-bar></vue-progress-bar>
</template>

<script>
import { useHead } from "@unhead/vue"
import { useRoute } from 'vue-router'
import { computed, reactive } from "vue"
import Nav from "./components/Nav"
import Footer from "./components/Footer"
import Toast from "./vendor/publisher/components/Toast"
import Logo from "./vendor/publisher/components/Logo"
import Breadcrumb from "./vendor/publisher/components/Breadcrumb.vue"
import { buildRequest } from "./vendor/publisher/connector-request"
import Honeycomb from "./components/Honeycomb.vue";
import { postMessageHeight } from "./vendor/publisher/connector-iframe"
import { initDebug } from "./vendor/publisher/debug"
import Icon from "./vendor/publisher/components/Icon.vue";

export default {
    name: "App",
    components: {
        Icon,
        Honeycomb,
      Breadcrumb,
      Logo,
      Footer,
      Nav,
      Toast
    },
    watch: {
        $route(to, from) {
            this.event("PageView", {
                to: to,
                from: from
            });
        }
    },
    setup() {
        const route = useRoute(), meta = reactive({
            title: computed(() => route.meta["title"]),
            description: computed(() => route.meta["description"]),
            canonical: computed(() => window.location.protocol + "//" + window.location.hostname + (route.meta["canonical"] || route.path)),
        });

        useHead({
            title: computed(() => meta.title),
            meta: [
                {
                    name: "description",
                    content: computed(() => meta.description),
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: computed(() => meta.canonical),
                },
            ],
        });

        return {
            meta
        }
    },
    async created() {
        this.$Progress.start();
        this.$store.dispatch("setUser");
        await this.$store.dispatch("auth/checkAuth")
            .finally(() => {
                this.$store.dispatch("subscription/boot");
            })
            .catch(() => this.$Progress.finish())
            .then(status => {
                if(status === "Not synchronous") {
                    router.push({name: "Sync"})
                }
            });
        this.$router.beforeEach((to, from, next) => {
            if (to.meta.progress !== undefined) {
                const meta = to.meta.progress;
                this.$Progress.parseMeta(meta);
            }
            if (to.meta.showModal === undefined && from.meta.showModal === undefined && to.name !== from.name) {
                this.$Progress.start();
            }
            next();
        });

        this.$router.afterEach(to => {
            if (to.meta.finishProgress === undefined || to.meta.finishProgress === true) {
                this.$Progress.finish();
            }
        });

        this.$axios.interceptors.request.use(config => {
            this.$Progress.start();
            return config;
        }, function (error) {
            this.$Progress.fail();
            return Promise.reject(error);
        });

        this.$axios.interceptors.response.use(response => {
            this.$Progress.finish();
            return response;
        }, async err => {
            if (err.response?.status === 419 && this.$config.sso?.auth_method === "token" && !this.$config.sso?.custom) {
                await this.$axios.get(this.$config.sso.csrf_cookie_url, buildRequest(this.$store.getters["auth/getToken"], false));
                return this.$axios(err.response.config);
            } else {
                this.$Progress.fail();
            }
            return Promise.reject(err);
        });
    },
    mounted() {
        initDebug();
        window.addEventListener("focus", () => {
            this.$store.commit("setToday")
        });
        this.$Progress.finish();

        this.$nextTick(function () {
            this.setViewHeight();
        }).then(() => postMessageHeight('https://' + this.$config.common?.domain, this.$integration()));
    },
    unmounted() {
        window.removeEventListener("focus", () => {
            this.$store.commit("setToday")
        });
    },
    methods: {
        setViewHeight: function () {
            const vh = window.innerHeight * 0.01;

            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
        /**
         *
         * @returns {number|*|null}
         */
        nr() {
            return this.$archive.getPuzzleNumber(this.$archive.validate(this.$route["query"]?.date))
        }
    }
}
</script>
