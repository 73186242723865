import axios from "axios"
import { set, del, _keys, getAll } from "@publisher/idb.js"
import { config, preloadPuzzles } from "../config";

export let game = {
    state: () => ({
        game: {
            archive: false,
            date: null,
            end: false,
            input: [],
            letter: null,
            nr: null,
            mandatory: null,
            min: null,
            isogram: null,
            points: 0,
        },
        games: {},
        puzzles: {}
    }),
    actions: {
        /**
         *
         * @param commit
         * @param getters
         * @param games
         */
        setGames: ({commit,getters},games) => {
            if(typeof getters.getConfig === "function") {
                config = getters.getConfig;
            }

            const today = getters.today,
                maxArchiveDate = new Date(new Date(today).setHours(0,0,0,0));

            maxArchiveDate.setDate(maxArchiveDate.getDate()-(config.setup?.game["max_archive_days"] ?? 0));

            Object.keys(games).forEach((date) => {

                const gameDate = new Date(new Date(date).setHours(0,0,0,0));

                if(gameDate >= maxArchiveDate) {
                    if(games[date].nr) {
                        commit("setGame", {
                            date: date,
                            end: games[date].end,
                            input: games[date].input,
                            nr: games[date].nr,
                            points: games[date].points
                        });
                    }
                }
            });

        },
        /**
         *
         * @param dispatch
         * @param getters
         * @returns {Promise<unknown>}
         */
        getGames: ({dispatch,getters}) => {
            return new Promise(async (resolve, reject) => {
                try {
                    await dispatch("readIndexedDB");
                } catch (e) {}

                // Keine Spiele für heute vorhanden oder nicht alle Spiele vorgeladen
                if(!getters.getPuzzleById(getters.today) || getters.countAllPuzzles < preloadPuzzles()) {
                    if(getters.getPuzzleById(getters.today)) { // Puzzle für heute vorhanden
                        resolve(true);
                    }

                    axios.get('/api/get')
                        .then((response) => {
                            dispatch("setPuzzles",response.data.puzzles ?? {})
                                .then(() => {
                                    resolve(true);
                                })
                                .catch(function (error) {
                                    reject(error);
                                });
                        })
                        .catch(function(error) {
                            reject(error);
                        });
                }
                else {
                    resolve(true);
                }
            });
        },
        /**
         *
         * @param commit
         * @param state
         * @param puzzles
         * @returns {Promise<Awaited<unknown>[]>}
         */
        setPuzzles: ({commit,state},puzzles) => {
            let promises = [];

            if(Object.keys(puzzles).length > 0) { // Puzzles available?
                for(const [date,daily] of Object.entries(puzzles)) {
                    for (const puzzle of daily) {
                        promises.push(new Promise((resolve, reject) => {
                            if(!state.puzzles[date]) {
                                set(puzzle["nr"], {date: date,letter: puzzle["letter"],min: puzzle["min"],isogram: puzzle["isogram"]}).finally(() => {
                                    commit("setPuzzle", {
                                        date: date,
                                        puzzle: puzzle
                                    });
                                    resolve();
                                });
                            }
                            else {
                                resolve("Puzzle already exists!");
                            }
                        }));
                    }
                }
            }

            return Promise.all(promises);
        },
        /**
         *
         * @param commit
         * @param getters
         * @returns {Promise<void>}
         */
        async readIndexedDB({commit,getters}) {
            let [keys, values] = await Promise.all([_keys(), getAll()]);
            const puzzles = {}, minPreloadDate = new Date();

            if(typeof getters.getConfig === 'function') {
                config = getters.getConfig;
            }

            if(keys.length > 0) {
                minPreloadDate.setDate(minPreloadDate.getDate() - (config.setup?.game["max_archive_days"] ?? 0) - 1);
                minPreloadDate.setUTCHours(0,0,0,0);

                await keys.forEach((nr,index) => {
                    /**
                     * Puzzle werden gelöscht wenn:
                     * Das Spiel veraltet ist (today - GAME_MAX_ARCHIVE_DAYS - 2 days)
                     */
                    if(new Date(values[index].date) < minPreloadDate) {
                        del(parseInt(nr)).then(r => {});
                        return;
                    }
                    else if(!puzzles[values[index].date]) { // Datum noch nicht in IndexedDB
                        puzzles[values[index].date] = [];
                    }
                    puzzles[values[index].date] = {
                        date: values[index].date,
                        letter: parseInt(values[index].letter),
                        min: parseInt(values[index].min),
                        nr: parseInt(nr),
                        isogram: values[index].isogram
                    };
                });

                commit("setPuzzles",puzzles);
            }
        }
    },
    mutations: {
        /**
         *
         * @param state
         * @param game
         */
        setCurrentGame: (state,game) => {
            state.game.date = game.date;
            state.game.end = game.end;
            state.game.input = game.input;
            state.game.letter = game.letter;
            state.game.nr = game.nr;
            state.game.mandatory = game.mandatory;
            state.game.min = game.min;
            state.game.isogram = game.isogram;
            state.game.points = game.points;
        },
        /**
         *
         * @param state
         * @param date
         * @param end
         * @param input
         * @param letter
         * @param nr
         * @param points
         */
        setGame: (state,{
            date, end, input, nr, points
        }) => {
            state.games[date] = state.games[date] ?? {};
            state.games[date] = {
                end: end,
                input: input,
                nr: parseInt(nr),
                points: parseInt(points)
            };
        },
        /**
         * Sorts games to achieve the same structure of database and localStorage
         * @param state
         */
        sortGames: (state) => {
            state.games = Object.keys(state.games)
                .sort()
                .reduce((accumulator,key) => {
                    accumulator[key] = state.games[key];
                    return accumulator;
                },{});
        },
        /**
         *
         * @param state
         */
        resetGames: (state) => {
            state.games = {};
        },
        /**
         *
         * @param state
         * @param date
         * @param puzzle
         */
        setPuzzle: (state,{date,puzzle}) => {
            if(!state.puzzles[date]) {
                state.puzzles[date] = [];
            }
            state.puzzles[date] = {
                date: date,
                letter: parseInt(puzzle.letter),
                min: parseInt(puzzle.min),
                nr: parseInt(puzzle.nr),
                isogram: puzzle.isogram
            };
        },
        /**
         *
         * @param state
         * @param puzzles
         */
        setPuzzles: (state,puzzles) => {
            state.puzzles = puzzles;
        }
    },
    getters: {
        /**
         * Get the current game of the user
         * @param state
         * @returns {{}|null}
         */
        getCurrentGame: state => {
            if(state.game.nr > 0) {
                return state.game;
            }

            return null;
        },
        /**
         *
         * @param state
         * @returns {(function(*): (*|null))|*}
         */
        getCurrentGameProperty: state => key => {
            if(state.game.nr > 0 && state.game.hasOwnProperty(key)) {
                return state.game[key];
            }

            return null;
        },
        /**
         * Get a game of the user by date and level
         * @param {*} state
         * @param {*} getters
         * @returns
         */
        getGameById: (state,getters) => (date = getters.today) => {
            if(state.games[date] !== undefined) {
                return state.games[date];
            }

            return null;
        },
        /**
         * Get all games of the user
         * @param state
         * @returns {{}|null}
         */
        getAllGames: state => {
            if(Object.keys(state.games).length > 0) {
                return state.games;
            }

            return null;
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {(function(*=): ({}|null))|*}
         */
        getAllGamesByDate: (state,getters) => (date = getters.today) => {
            if(Object.keys(state.games).length > 0 && state.games[date].length > 0) {
                return state.games[date];
            }

            return null;
        },
        /**
         *
         * @param state
         * @returns {(function(*): (number|number))|*}
         */
        getCountGamesByDate: state => date => {
            if(state.games.hasOwnProperty(date)) {
                return Object.values(state.games[date]).filter(game => true).length;
            }

            return 0;
        },
        /**
         *
         * @param state
         * @returns {number}
         */
        countAllPuzzles: state => {
            return Object.values(state.puzzles).length;
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {(function(*=): (*|undefined))|*}
         */
        getPuzzleById: (state,getters) => (date = getters.today) => {
            if(Object.keys(state.puzzles).length > 0 && state.puzzles[date]) {
                return state.puzzles[date];
            }
            else if(config["debug"]) {
                console.log("No puzzles were passed");
                console.log("state.puzzle >");
                console.log(state.puzzles);
                console.log("date >");
                console.log(date);
            }

            return null;
        },
    }
}
