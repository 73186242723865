<template>
    <!-- ActionButtons -->
    <div class="flex items-center justify-center py-3 px-5 sm:p-5 space-x-3">
        <vue-countdown v-if="action('countdown')" @end="onCountdownEnd" :time="tilNextDay()" v-slot="{ hours, minutes, seconds }">
            <time v-if="hours > 0 || minutes > 0 || seconds > 0" class="block text-gray-900 text-lg dark:text-gray-300 leading-none">Neues Rätsel in <strong class="whitespace-nowrap">{{ hours }}h {{ minutes }}m und {{ seconds }}s</strong>.</time>
            <Spinner v-else classes="-mt-1"></Spinner>
        </vue-countdown>
        <button v-else-if="action('continue','overlay')" @click="closeOverlay" type="button" class="btn btn-default whitespace-nowrap">
            Spiel fortsetzen
        </button>
        <router-link v-if="action('archive','overlay')" :to="{name:'Archive'}" tag="button" class="btn btn-default whitespace-nowrap inline-flex items-center justify-self-end">
            Zum Archiv
            <icon icon="chevron-right" classes="h-4 w-4 ml-1 -mr-1"></icon>
        </router-link>
    </div>
    <!-- /ActionButtons -->
    <!-- Promote archive & register -->
    <action-promote-archive v-if="action('promoteArchive','overlay')" class="py-3 flex border-t border-gray-200 bg-gray-100 px-5 sm:p-5 space-x-2 dark:bg-gray-700 dark:border-gray-600"></action-promote-archive>
    <!-- /Promote archive & register -->
    <!-- Rotate publications -->
    <action-rotate-publications v-else-if="action('promotePublication','overlay')" class="py-3 flex border-t border-gray-200 bg-gray-100 px-5 sm:p-5 space-x-2 dark:bg-gray-700 dark:border-gray-600"></action-rotate-publications>
    <!-- /Rotate publications -->
    <!-- Share & Stats (if !overlay) -->
    <div v-if="action('share','always')" class="flex justify-around items-center py-3 px-5 md:p-5 border-t border-gray-200 dark:border-gray-600 space-x-3">
        <span class="inline-block text-gray-900 text-sm dark:text-gray-300">Letztes Spiel (#{{ game.nr }}): {{ game.points }} Punkte mit {{ game.input.length }} Wörtern</span>
        <button v-on:click="shareResult(game.nr,game.input.length,game.points,game.mandatory,game.letter,game.isogramsFound,isTopScore)" class="inline-flex items-center btn btn-primary btn-medium sm:btn-large whitespace-nowrap" type="button">
            <icon v-if="$store.getters.canShare" icon="share" classes="-ml-1 mr-2 w-5 h-5"></icon>
            <icon v-else icon="document-duplicate" classes="-ml-1 mr-2 w-5 h-5"></icon>
            <span class="first-letter:capitalize">Erfolg teilen</span>
        </button>
    </div>
    <!-- /Share -->
</template>

<script>
import Share from "../mixins/share.mix"
import VueCountdown from "@chenfengyuan/vue-countdown"
import Icon from "../vendor/publisher/components/Icon.vue";
import Spinner from "../vendor/publisher/components/Spinner.vue";
import ActionPromoteArchive from "../vendor/publisher/components/ActionPromoteArchive.vue";
import ActionRotatePublications from "../vendor/publisher/components/ActionRotatePublications.vue";

export default {
    name: "Actions",
    props: ["game","overlay","isTopScore"],
    emits: ["close"],
    mixins: [
        Share
    ],
    components: {
        ActionRotatePublications,
        ActionPromoteArchive,
        Spinner,
        Icon,
        VueCountdown
    },
    methods: {
        /**
         *
         * @param elm
         */
        closeOverlay(elm) {
            this.$emit("close",elm);
        },
        /**
         *
         * @param action
         * @param display
         * @returns {*|boolean}
         */
        action(action = null,display = "always"/* ['always','overlay','game']*/) {
            if((display === "overlay" && !this.overlay) || (display === "game" && this.overlay)) {
                return false;
            }

            const random = Math.floor(Math.random() * 2);

            const actions = {
                "archive": () => {
                    return this.game.archive;
                },
                "continue": () => {
                    return !this.game.end
                },
                "countdown": () => {
                    return !this.game.archive && this.game.end;
                },
                "promoteArchive": () => {
                    // Todo: and games are left
                    return random === 0 && !this.game.archive && this.$archive.enabled() && this.game.end;
                },
                "promotePublication": () => {
                    return this.hasConnection && this.$config["publications"] && this.$config["publications"].length > 0 && this.game.end;
                },
                "share": () => {
                    return this.game.end;
                },
            };

            return actions[action]();

        }
    }
}
</script>
