import Archive from "../pages/Archive"

const routes = [
    {
        name: 'Archive',
        path: '/archiv',
        component: Archive,
        meta: {
            title: "Archiv",
            description: "",
            robots: "noindex, follow, noarchive",
            breadcrumb: [
                {
                    text: "Start",
                    to: {
                        name: "Home"
                    }
                },
                {
                    headline: "Archiv",
                },
            ],
            requiresAuth: true,
            availableWithoutAuthentication: true,
        },
    }
];

export { routes }