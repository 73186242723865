<template>
    <div class="text-sm flex items-center justify-center">
        <legal-link type="imprint" classes="mr-5"></legal-link>
        <legal-link type="privacy" classes="mr-5"></legal-link>
        <privacy-setup-icon></privacy-setup-icon>
    </div>
</template>

<script>
import LegalLink from "../vendor/publisher/components/LegalLink.vue";
import PrivacySetupIcon from "../vendor/publisher/components/PrivacySetupIcon.vue";

export default {
    name: "Footer",
    components: {
        LegalLink,
        PrivacySetupIcon
    },
}
</script>
