<template>
    <div class="relative px-4 w-full max-w-lg h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex justify-between items-center py-3 px-5 sm:p-5 rounded-t-lg border-b dark:border-gray-600">
                <h3 class="text-lg inline-flex sm:text-lg font-medium text-gray-900 dark:text-white">
                    <icon icon="chart-bar" classes="h-6 w-6 mr-2 text-gray-600 dark:text-gray-500"></icon>
                    {{ $addressingUser('Deine','Ihre') }} Statistik
                </h3>
                <button @click="closeOverlay" aria-label="Fenster schließen" class="text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <icon icon="x-mark" classes="w-5 h-5"></icon>
                </button>
            </div>
            <!-- Modal body -->
            <div class="py-3 px-5 sm:p-5 space-y-5 border-b dark:border-gray-600">
                <div class="flex justify-stretch gap-4 dark:text-gray-300">
                    <div class="text-center flex-1">
                        <div class="font-bold text-2xl sm:text-3xl">{{ $store.getters.getStat("games") }}</div>
                        <label class="block text-sm sm:text-base break-words">x gespielt</label>
                    </div>
                    <div class="text-center flex-1">
                        <div class="font-bold text-2xl sm:text-3xl">{{ isograms }}</div>
                        <label class="block text-sm sm:text-base break-words">Isogramm{{ isograms !== 1 ? 'e' : '' }} entdeckt</label>
                    </div>
                    <div class="text-center flex-1">
                        <div class="font-bold text-2xl sm:text-3xl">{{ getAvgWords() }}</div>
                        <label class="block text-sm sm:text-base break-words">Ø Wörter pro Rätsel</label>
                    </div>
                </div>
            </div>
            <!-- Top Score -->
            <div class="pt-3 pb-6 px-5 sm:p-5 space-y-2 border-b dark:border-gray-600 bg-gray-100 dark:bg-gray-800 relative">
                <button id="help-top-score-trigger" class="absolute top-3 right-5 text-gray-600 dark:text-gray-300 bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-500 dark:hover:text-white">
                    <icon icon="question-mark-circle" classes="w-5 h-5"></icon>
                </button>
                <div data-popover id="help-top-score-content" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                     <button @click="closeHelper" aria-label="Fenster schließen" class="absolute top-3 right-3 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <icon icon="x-mark" classes="w-5 h-5"></icon>
                    </button>
                    <div class="p-3 space-y-2">
                        <h3 class="font-semibold text-gray-900 dark:text-white">Was ist der Top Score?</h3>
                        <p>Der Top-Score wird durch die Mindestanzahl an Wörtern zum Erreichen der Endpunktzahl ({{ steps.slice(-1)[0] }}) bestimmt. Dieser Wert ist abhängig vom Isogramm des Tages.</p>
                        <h3 class="font-semibold text-gray-900 dark:text-white">Was ist ein Isogramm?</h3>
                        <p>Als Isogramm bezeichnet man üblicherweise ein einzelnes Wort, in dem jeder verwendete Buchstabe nur einmal vorkommt.</p>
                    </div>
                    <div data-popper-arrow></div>
                </div>
                <div class="flex">
                    <icon icon="rocket-launch" :classes="'shrink-0 h-9 w-9 inline-flex items-center p-1.5 rounded ' + (isTopScore() ? 'bg-brand-500 text-gray-800 dark:text-gray-700' : 'bg-gray-600 text-gray-200 dark:bg-gray-400 dark:text-gray-700')"></icon>
                    <div class="ml-2 grow pr-12">
                        <p class="text-sm text-gray-900 dark:text-white flex items-center">
                            <strong class="mr-1">Top-Score {{ game.archive ? "#" + game.nr : 'heute' }}:</strong>
                            <span class="inline-flex items-center">
                                <icon v-if="!game.end" icon="eye-slash" classes="inline-block w-4 h-4 shrink-0 mr-1"></icon>
                                <span v-else class="mr-1">{{ game.min }}</span> Wörter
                            </span>
                        </p>
                        <p v-if="!game.end" class="text-sm text-gray-900 dark:text-white">{{ $addressingUser('Du hast','Sie haben') }} bisher {{ game.input.length }} {{ game.input.length === 1 ? 'Wort' : 'Wörter' }} gefunden</p>
                        <p v-else-if="isTopScore()" class="text-sm text-gray-900 dark:text-white underline decoration-brand-500 decoration-2"><strong>{{ getRandomItem(messages[Object.keys(messages)[Object.keys(messages).length - 1]]) }}!</strong> {{ $addressingUser('Du hast','Sie haben') }} den Top-Score erreicht</p>
                        <p v-else class="text-sm text-gray-900 dark:text-white">Mit {{ game.input.length }} gefundenen Wörtern {{ $addressingUser('liegst du','liegen Sie') }}{{ aboveTopScore() <= 5 ? ' nur' : '' }} <strong class="underline decoration-brand-500 decoration-2">{{  aboveTopScore() }} {{ aboveTopScore() === 1 ? "Wort" : "Wörter" }} über dem Top-Score</strong></p>
                    </div>
                </div>
                <dl class="flex items-center">
                    <dt class="text-sm font-medium text-gray-500 dark:text-gray-300 whitespace-nowrap w-16 text-right">Top-Score</dt>
                    <dd class="grow">
                        <div class="bg-gray-300 rounded-lg h-2.5 dark:bg-gray-400 mr-2 ml-2">
                            <div v-bind:class="game.end === true && isTopScore() ? 'bg-brand-500 dark:bg-brand-400' : 'bg-stone-400 dark:bg-gray-800'" class="h-2.5 rounded-lg" v-bind:style="'width: ' + getDeviationWidth(deviations.topScore) + '%;'"></div>
                        </div>
                    </dd>
                    <dd class="w-6">
                        <span class="text-sm font-medium text-gray-500 dark:text-gray-300">{{ deviations.topScore }}</span>
                    </dd>
                </dl>
            </div>
            <!-- /Top Score -->
            <!-- Deviations -->
            <div class="py-3 px-5 sm:p-5 space-y-2 border-b dark:border-gray-600 relative">
                <span class="absolute -top-3 border dark:border-gray-600 left-2 bg-white dark:bg-gray-700 text-gray-800 text-xs font-medium ml-2 px-2.5 py-0.5 rounded-full dark:text-gray-300 flex items-center">
                    <icon icon="arrow-down" classes="h-3 w-3 mr-1"></icon>Abweichung
                </span>
                <dl v-for="(deviation,key,index) of deviations.rest()" class="flex items-center">
                    <dt class="text-sm font-medium text-gray-500 dark:text-gray-300 whitespace-nowrap w-16 text-right">{{ (key.indexOf("+") > -1 ? '>' : '+') + parseInt(key) + ' ' + (key === '1' ? 'Wort' : 'Wörter') }}</dt>
                    <dd class="grow">
                        <div class="bg-gray-300 rounded-lg h-2.5 dark:bg-gray-400 mr-2 ml-2">
                            <div v-bind:class="game.end === true && aboveTopScore() === (index+1) ? 'bg-brand-500 dark:bg-brand-400' : 'bg-stone-400 dark:bg-gray-800'" class="h-2.5 rounded-lg" v-bind:style="'width: ' + getDeviationWidth(deviation) + '%;'"></div>
                        </div>
                    </dd>
                    <dd class="w-6">
                        <span class="text-sm font-medium text-gray-500 dark:text-gray-300">{{ deviation }}</span>
                    </dd>
                </dl>
            </div>
            <!-- /Deviations -->
            <!-- /Modal body -->
            <!-- Modal footer -->
            <Actions @close="closeOverlay" :game="game" :is-top-score="isTopScore()" :overlay="true"></Actions>
            <!-- /Modal footer -->
        </div>
    </div>
</template>

<script>
import { useRoute } from "vue-router"
import { useHead } from "@unhead/vue"
import Actions from "../components/Actions"
import Icon from "../vendor/publisher/components/Icon"
import { Popover } from 'flowbite'

export default {
    name: "Stats",
    props: ["game"],
    components: {
        Icon,
        Actions,
    },
    data() {
        return {
            deviations: {
                topScore: this.$store.getters.getStat("deviations")[Object.keys(this.$store.getters.getStat("deviations"))[0]],
                rest: () => {
                    const deviations = Object.assign({},this.$store.getters.getStat("deviations"));
                    delete deviations[Object.keys(deviations)[0]];

                    return deviations;
                }
            },
            messages: this.$config.content.messages.game.found,
            isograms: this.$store.getters.getStat("isograms"),
            helper: null,
            steps: Object.values(this.$config.setup.game.achievements)
        }
    },
    setup() {
        const route = useRoute();
        useHead({
            title: route.meta["title"]
        });
    },
    mounted() {
        document.body.addEventListener("click",this.clickOverlay,true);

        this.helper = new Popover(document.getElementById("help-top-score-content"), document.getElementById("help-top-score-trigger"), {
            triggerType: "click",
            placement: "left"
        });
    },
    unmounted() {
        document.body.removeEventListener("click",this.clickOverlay,true);
    },
    methods: {
        /**
         *
         * @param e
         */
        closeOverlay(e) {
            e.preventDefault();
            const route = {
                name: "Home"
            };

            if(this.game.archive) {
                route["query"] = {
                    date: this.game.date
                };
            }

            router.push(route);
        },
        /**
         *
         * @param e
         */
        clickOverlay(e) {
            const modal = document.querySelector("[aria-modal]:not([id=CybotCookiebotDialog])");
            if(modal && !e.target.closest('#' + modal.getAttribute("id") + ' .shadow')) {
                this.closeOverlay(e);
            }
        },
        /**
         *
         * @param e
         */
        closeHelper(e) {
            this.helper.hide();
            e.stopPropagation();
        },
        /**
         *
         * @returns {string}
         */
        getAvgWords() {
            const games = this.$store.getters.getStat("games");
            const words = this.$store.getters.getStat("words");

            if(games && words) {
                return (words/games).toFixed(1);
            }

            return "0.0";
        },
        /**
         *
         * @param countDeviation
         * @returns {number}
         */
        getDeviationWidth(countDeviation = 0) {
            if(countDeviation > 0) {
                return Math.round(100 * countDeviation / this.$store.getters.getStat("games") ?? 0);
            }

            return 0;
        },
        /**
         *
         * @returns {boolean}
         */
        isTopScore() {
            return this.game.end && this.game.input.length <= this.game.min;
        },
        /**
         *
         * @returns {number}
         */
        aboveTopScore() {
            return this.game.input.length-this.game.min;
        }
    }
};
</script>
