<template>
    <div class="container max-w-4xl mx-auto">
        <div class="pb-10 p-4">
            <div class="w-full pt-5 sm:max-w-sm mx-auto bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                <div class="flex flex-col items-center py-10">
                    <div class="relative w-24 h-24 overflow-hidden bg-gray-100 rounded-full shadow-lg mb-3 dark:bg-gray-600">
                        <icon icon="user" classes="absolute w-28 h-28 text-gray-400 -left-2"></icon>
                    </div>
                    <Achievements v-if="displayAchievements && achievements" :achievements="achievements"></Achievements>
                    <span class="text-sm text-gray-500">{{ username }}</span>
                    <a v-if="$config.sso.account_url" :href="$config.sso.account_url" class="mt-2 mx-auto bg-gray-100 hover:bg-gray-200 transition-colors text-gray-600 text-xs font-medium px-3 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-400 border border-gray-400">Zum Profil</a>
                </div>
                <div data-accordion="collapse" data-active-classes="text-gray-900 dark:text-white bg-gray-100 dark:bg-gray-700 font-bold" data-inactive-classes="text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-400">
                    <VerifyEmail v-if="!$store.getters['auth/isVerified']"></VerifyEmail>
                    <component v-for="item in accordionItems" :is="item.component" v-bind="item.hasOwnProperty('props') ? item.props : null"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from "vue"
import { initAccordions } from "flowbite"

onMounted(() => {
    initAccordions()
});
</script>

<script>
import SendVerification from "../components/SendVerification"
import ChangeEmail from "../components/ChangeEmail.vue"
import ChangePassword from "../components/ChangePassword.vue"
import DeleteAccount from "../components/DeleteAccount.vue"
import ResetStats from "../components/ResetStats.vue"
import UpdateNotifications from "../components/UpdateNotifications.vue"
import VerifyEmail from "../components/VerifyEmail.vue"
import Achievements from "../components/Achievements.vue";
import Icon from "../components/Icon";
import { displayAchievementsInAccount } from "../config";

export default {
    name: "Account",
    components: {
        Icon,
        Achievements,
        ChangeEmail,
        ChangePassword,
        DeleteAccount,
        ResetStats,
        SendVerification,
        UpdateNotifications,
        VerifyEmail,
    },
    computed: {
        username() {
            return this.censorEmail(this.email);
        },
        email() {
            return this.$store.getters["auth/getUser"]?.email ?? this.$store.getters["auth/getUser"]?.username;
        }
    },
    data() {
        return {
            displayAchievements: displayAchievementsInAccount(this.$config.identifier),
            achievements: this.$config.setup?.game?.achievements,
            accordionItems: []
        }
    },
    created() {
        if(this.$store.getters.hasOwnProperty("getStats") && this.$store.getters.getStats.hasOwnProperty("games")) {
            this.accordionItems = [{
                id: "reset-stats",
                component: "ResetStats",
            }];
        }

        if(!this.$config.sso.custom || this.$config.sso.edit_email_url) {
            this.accordionItems.push(
                {
                    id: "change-email",
                    component: "ChangeEmail",
                    props: {
                        custom: this.$config.sso.custom,
                        url: this.$config.sso.edit_email_url
                    }
                }
            );
        }

        if(!this.$config.sso.custom || this.$config.sso.edit_password_url) {
            this.accordionItems.push(
                {
                    id: "change-password",
                    component: "ChangePassword",
                    props: {
                        custom: this.$config.sso.custom,
                        url: this.$config.sso.edit_password_url
                    }
                }
            );
        }

        if(!this.$config.sso.custom || this.$config.sso.edit_notification_url) {
            this.accordionItems.push(
                {
                    id: "change-notifications",
                    component: "UpdateNotifications",
                    props: {
                        custom: this.$config.sso.custom,
                        url: this.$config.sso.edit_notification_url
                    }
                }
            );
        }

        if(!this.$config.sso.custom || this.$config.sso.delete_account_url) {
            this.accordionItems.push(
                {
                    id: "delete-account",
                    component: "DeleteAccount",
                    props: {
                        custom: this.$config.sso.custom,
                        url: this.$config.sso.delete_account_url
                    }
                }
            );
        }
    },
    methods: {
        /**
         *x
         * @param str
         * @returns {string}
         */
        censorWord(str) {
            return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
        },
        /**
         *
         * @param email
         * @returns {*|string}
         */
        censorEmail(email) {
            const arr = (email || "").split("@");

            if(arr.length > 1) {
                return this.censorWord(arr[0]) + "@" + arr[1];
            }
            else if(email) {
                return email;
            }

            return "Anonym";
        },
    },
}
</script>
