let config = {
    identifier: process.env.IDENTIFIER,
    name: process.env.NAME,
    asset_url: process.env.ASSET_URL,

    common: {
        domain: window.location.host,
        revision: process.env.REVISION,
    },

    setup: {
        game: {
            max_letters: 20,
            min_letters: 4,
            achievements: {
                1:0,
                2:5,
                3:10,
                4:18,
                5:29,
                6:43,
                7:60,
                8:80
            },
            points_min: 1,
            points_letters: 5,
            points_isogram: 10
        }
    },

    layout: {
        //...
    },

    content: {
        messages: {
            notExists: "Das Wort ist nicht in der Wortliste.",
            alreadyPresent: "Das Wort wurde bereits gefunden.",
            warn: "Das hat leider nicht geklappt.",
            game: {
                success: ["Erfolgreich gelöst!","Sehr gut gemacht!","Glückwunsch! Rätsel gelöst."],
                failed: {
                    //...
                },
                found: {
                    1: ["Ok", "Gut", "Effizient", "Treffer", "Fehlerfrei", "Sauber", "Schön"],
                    5: ["Weiter so", "Tadellos", "Cool", "Optimal", "Stark", "Toll"],
                    6: ["Super", "Top", "Exzellent", "Spitze", "Fabelhaft"],
                    7: ["Sehr gut", "Klasse", "Engagiert", "Wunderbar", "Clever", "Perfekt"],
                    8: ["Beeindruckend", "Einfallsreich", "Erstaunlich", "Hervorragend"],
                    9: ["Wow", "Bewundernswert", "Genial", "Bemerkenswert", "Großartig", "Wundervoll"],
                    10: ["Faszinierend", "Herausragend", "Beachtlich", "Gewieft", "Wunderbar"],
                    11: ["Meisterhaft", "Phänomenal", "Kreativ", "Sagenhaft"],
                    12: ["Respekt", "Inspirierend", "Eindrucksvoll", "Überragend","Unglaublich"],
                }
            }
        }
    }
}

/**
 *
 * @param preload
 * @returns {*}
 */
function preloadPuzzles(preload = window.Publisher.setup?.game["max_archive_days"] ?? 7) {
    return (preload*2)+2;
}

export { config, preloadPuzzles }
