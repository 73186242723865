<template>
    <div v-if="publication">
        <p class="text-gray-900 text-sm dark:text-gray-300 flex items-center mx-auto">
            <span>Probiere{{ $addressingUser(' ','n Sie') }} jetzt</span>
            <icon icon="chevron-right" classes="h-4 w-4 mx-1"></icon>
            <a :href="publication.href + '?utm_source=' + encodeURIComponent(publication.name) + '&utm_medium=last-game&utm_campaign=' + encodeURIComponent('oliwol Publisher Tool/' + publication.name)" class="bg-white text-xs border transition-colors hover:bg-transparent rounded-r-full inline-flex items-center pl-1 py-1 pr-3" target="_blank">
                <img :src="publication.icon" class="w-5 h-5 mr-1.5" :alt="'Logo von ' + publication.name">
                {{ publication.name }}
            </a>
        </p>
    </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
    name: "ActionRotatePublications",
    components: {Icon},
    data() {
        return {
            publication: null
        }
    },
    created() {
        this.publication = this.getRandomPublication();
    },
    methods: {
        /**
         *
         * @returns {*|null}
         */
        getRandomPublication() {
            if(!this.$config.publications || this.$config.publications.length < 1) {
                return null;
            }

            const random = Math.floor(Math.random() * this.$config.publications.length);

            return this.$config.publications[random];
        }
    }
}
</script>