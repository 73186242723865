import Register from "../pages/Register"
import Login from "../pages/Login"
import Account from "../pages/Account"
import Verify from "../pages/Verify"
import Welcome from "../pages/Welcome"
import Help from "../pages/Help"
import Sync from "../pages/Sync"
import ForgotPassword from "../pages/ForgotPassword"
import NewPassword from "../pages/NewPassword"
import Imprint from "../pages/Imprint"
import Privacy from "../pages/Privacy"
import EmptyRouterView from "../pages/EmptyRouterView"
import PageNotFound from "../pages/PageNotFound"
import Offline from "../pages/Offline"

const routes = [
    {
        path: '/hilfe',
        component: EmptyRouterView,
        name: 'HelpWrapper',
        children: [
            {
                name: 'Help',
                path: '',
                component: Help,
                meta: {
                    title: "Wordle Hilfe - Häufig gestellte Fragen zum beliebten Wort-Rätsel",
                    description: "Im Hilfebereich findest du nützliche Tipps zum Wort-Rätsel Wordle und Antworten auf häufig gestellte Fragen.",
                    robots: "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
                    breadcrumb: [
                        {
                            text: "Start",
                            to: {
                                name: "Home"
                            }
                        },
                        {
                            headline: "Hilfe",
                        },
                    ]
                },
            }
        ]
    },
    {
        name: 'Register',
        path: '/registrieren',
        component: Register,
        meta: {
            title: "Registriere dich kostenlos",
            description: "Registriere dich kostenlos, um alle Vorteile online zu nutzen: 7-Tage-Archiv und Spielstand-Speicher",
            robots: "noindex, follow, noarchive"
        },
    },
    {
        name: 'Login',
        path: '/anmelden',
        component: Login,
        meta: {
            title: "Login - Dein Spielstand von überall abrufbar",
            description: "Logge dich ein und nutze den Spielstand-Speicher - egal ob vom PC, im Büro, dem Handy oder Tablet zu Hause",
            robots: "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        },
    },
    {
        name: 'ForgotPassword',
        path: '/passwort',
        component: ForgotPassword,
        meta: {
            title: "Passwort wiederherstellen",
            description: "",
            robots: "noindex, follow, noarchive"
        },
    },
    {
        name: "NewPassword",
        path: '/neues-passwort-erstellen',
        component: NewPassword,
        meta: {
            title: "Neues Passwort erstellen",
            description: "",
            robots: "noindex, follow, noarchive"
        }
    },
    {
        name: "Verify",
        path: '/verifizierung',
        component: Verify,
        meta: {
            finishProgress: false,
            title: "Email-Adresse bestätigen",
            description: "",
            robots: "noindex, follow, noarchive"
        }
    },
    {
        name: 'Account',
        path: '/profil',
        component: Account,
        meta: {
            title: "Dein Profil",
            description: "",
            robots: "noindex, follow, noarchive",
            requiresAuth: true
        }
    },
    {
        name: 'Welcome',
        path: '/willkommen',
        component: Welcome,
        meta: {
            title: "Willkommen",
            description: "",
            robots: "noindex, follow, noarchive",
            requiresAuth: true,
            requiresVerify: true
        }
    },
    {
        name: 'Sync',
        path: '/sync',
        component: Sync,
        meta: {
            // finishProgress: false,
            title: "Spieldaten synchronisieren",
            description: "",
            robots: "noindex, follow, noarchive",
            requiresAuth: true,
            requiresSync: true
        }
    },
    {
        name: "PageNotFound",
        path: '/:catchAll(.*)*',
        component: PageNotFound,
        meta: {
            title: "Seite existiert nicht",
            description: "",
            robots: "noindex, follow, noarchive"
        }
    },
    {
        name: "Offline",
        path: '/offline',
        component: Offline,
        meta: {
            title: "Kein Internet",
            description: "",
            robots: "noindex, follow, noarchive"
        }
    },
    {
        name: 'Imprint',
        path: '/impressum',
        component: Imprint,
        meta: {
            title: "Impressum",
            description: "",
            robots: "noindex, follow, noarchive",
            breadcrumb: [
                {
                    text: "Start",
                    to: {
                        name: "Home"
                    }
                },
                {
                    text: "Impressum",
                },
            ]
        },
    },
    {
        name: 'Privacy',
        path: '/datenschutz',
        component: Privacy,
        meta: {
            title: "Datenschutz",
            description: "",
            robots: "noindex, follow, noarchive",
            breadcrumb: [
                {
                    text: "Start",
                    to: {
                        name: "Home"
                    }
                },
                {
                    text: "Datenschutz",
                },
            ]
        }
    },
];

/**
 *
 * @param index
 * @param follow
 * @returns {string}
 */
function getMetaRobots(index = true,follow = true) {

    let robots = '';

    if(index === false) {
        robots += 'no';
    }

    robots += 'index, ';

    if(follow === false) {
        robots += 'no';
    }

    robots += 'follow';

    if(index === true) {
        robots += ', max-snippet:-1, max-image-preview:large, max-video-preview:-1';
    }

    return robots;

}

/**
 *
 * @param route
 * @param meta
 * @param component
 * @returns {{description, robots: *, title}|{description, robots: string, title: *}|null}
 */
function setMeta(route,meta,component) {
    if(!route.hasOwnProperty("meta")) {
        return null;
    }

    if(meta && meta.hasOwnProperty(component)) {
        return {...route.meta,...{
            title: meta[component].title ?? route.meta.title,
            description: meta[component].description,
            robots: getMetaRobots(meta[component].index ?? true,meta[component].follow ?? true)
        }}
    }

    return {...route.meta,...{
        title: route.meta.title,
        description: route.meta.description,
        robots: route.meta.robots
    }};
}

export { routes, getMetaRobots, setMeta }
