import axios from "axios";

/**
 *
 * @param token {null|string}
 * @param schema {null|string}
 * @param withCredentials {boolean}
 * @param timeout {number}
 * @returns {{withCredentials: boolean, timeout: number}}
 */
function buildRequest(token = null,schema = "Bearer",withCredentials = true,timeout = 4000) {
    const request = {
        withCredentials: withCredentials,
        timeout: timeout
    };

    axios.defaults.withCredentials = withCredentials;

    if(token) {
        request.headers = {
            "Authorization": (schema ? schema + " " : "") +  token
        }
    }
    else {
        delete axios.defaults.headers.common["X-Requested-With"];
        delete axios.defaults.headers.common["X-CSRF-TOKEN"];
    }

    return request;
}

/**
 *
 * @param url
 * @param params {null|object}
 * @returns {*}
 */
function buildURLWithParams(url,params = null) {
    const _url = new URL(url);

    if(params && typeof params === "object") {
        for (let key in params) {
            _url.searchParams.append(key,params[key]);
        }
    }

    /*if(_url.searchParams.size > 0 && jwt && typeof jwt === "object") {
        const regex = new RegExp(/^__(.+)__$/,"i");

        for (const [key,value] of _url.searchParams) {
            const variable = value.match(regex);
            if(variable && jwt.hasOwnProperty(variable[1])) {
                _url.searchParams.set(key,jwt[variable[1]])
            }

        }
    }*/

    return _url.href;
}

/**
 *
 * @param key
 * @returns {string|null}
 */
function getCookie(key) {
    const cookie = document.cookie.match('(^|;)?' + key + '=([^;]*)(;|$)');
    return cookie ? decodeURIComponent(cookie[2]) : null;
}

/**
 *
 * @param key
 * @param val
 */
function setCookie(key,val) {
    const today = new Date();
    const expire = new Date();
    expire.setTime(today.getTime() + 3600000*24*7);

    document.cookie = key + " = " + val + "; SameSite=Lax; Secure; Expires=" + expire.toUTCString();
}

/**
 *
 * @param key
 */
function removeCookie(key) {
    if(getCookie(key) ) {
        document.cookie = key + "=" + "; SameSite=Lax; Secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

/**
 *
 * @param token
 * @param authType
 * @param timeout
 * @returns {{withCredentials: boolean, timeout: number}}
 */
export { setCookie, getCookie, removeCookie, buildRequest, buildURLWithParams }
