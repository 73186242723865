<template>
    <div class="container max-w-4xl mx-auto">
        <legal type="imprint"></legal>
    </div>
</template>

<script>
import Legal from "../components/Legal.vue"

export default {
    name: "Imprint",
    components: {
        Legal,
    },
}
</script>
