export const subscription = {
    namespaced: true,
    state: () => ({
        paywall: false,
    }),
    actions: {
        register: (/*{dispatch,rootGetters}*/) => {
            // Register paywall methods
        },
        /**
         *
         * @param dispatch
         * @param commit
         * @param rootGetters
         */
        boot: ({dispatch,commit,rootGetters}) => {
            const config = rootGetters.getConfig;

            if(config.common["with_authentication"] && config.paywall?.enabled) {
                commit("enable");
                dispatch("register");
            }
            else if(config.debug) {
                console.info("The paywall could not be booted because either no authentication is active or the paywall is not active.");
            }
        },
        /**
         *
         * @param dispatch
         * @param commit
         * @param getters
         * @param action
         * @param data
         * @returns {Promise<unknown>}
         */
        paywall: ({dispatch, commit, rootGetters},{action,data}) => {
            return new Promise((resolve/*, reject*/) => {
                const config = rootGetters.getConfig;

                dispatch(action, {
                    data: data,
                    config: config
                })
                    .catch(error => { // paywall for action has an error
                        if(config.debug) {
                            console.warn("Action [" + action + "] reject '" + error + "'. \nData: " + JSON.stringify(data))
                        }
                    })
                    .finally(() => resolve(true));
            });
        },
        afterLogin: (/*{dispatch}*/) => {
            // Action after login
        },
        afterLogout: (/*{dispatch}*/) => {
            // Action after logout
        },
        /*action: ({getters,commit},{config}) => {
            return new Promise((resolve, reject) => {
                if(!config.setup.paywall.action) {
                    commit("setActionPaywall",false)
                    return resolve("Access for paywall action [action] allowed")
                }

                commit("setActionPaywall",true)
                reject("Access for paywall action [action] denied")
            });
        }*/
    },
    mutations: {
        /**
         *
         * @param state
         */
        enable: (state) => {
            state.paywall = true;
        },
        /*setActionPaywall: (state,status) => {
            state.action = status;
        }*/
    },
    getters: {
        /**
         *
         * @param state
         * @param getters
         * @returns {function(*, ...[*]): *}
         */
        isPaywall: (state,getters) => (type,...args) => {
            return state.paywall && !store.getters["auth/isAuth"] && getters[`${type}`].apply(null,args);
        },
        /*
        |--------------------------------------------------------------------------
        | The action must return a closure
        | Call
        |   > this.$store.getters["subscription/[action]"]
        | or with args
        |   > this.$store.getters["subscription/[action]"](arg1,arg2,...)
        |--------------------------------------------------------------------------
        |
        |
        */
        // action: state => () => state.action
    }
}
