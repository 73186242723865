<template>
    <icon icon="user" classes="w-6 h-6"></icon>
    <span v-if="isAuth" class="absolute bottom-1 right-0.5 w-3 h-3 bg-green-400 border-2 border-white rounded-full dark:border-gray-800 contrast:bg-orange-400"></span>
    <span v-else class="flex h-3 w-3 absolute bottom-1 right-0.5">
        <span class="animate-[ping_2s_ease-in-out_infinite] absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span>
        <span class="relative inline-flex rounded-full border-2 border-white h-3 w-3 bg-gray-500 dark:border-gray-800"></span>
    </span>
</template>
<script>
import Icon from "./Icon";

export default {
    name: "AuthIconSvg",
    components: {Icon},
    props: ["isAuth"]
}
</script>