<template>
    <router-link v-if="hasFaqs" :to="{name:'Help'}" title="Hilfe" class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:text-gray-500 dark:hover:bg-gray-700">
        <icon icon="question-mark-circle" classes="w-6 h-6"></icon>
    </router-link>
</template>

<script>
import Icon from "./Icon";

export default {
    name: "HelpIconSvg",
    components: {Icon},
    data() {
        return {
            hasFaqs: this.$config.content.faqs,
        }
    }
}
</script>