<template>
    <ul v-if="hasFaqs || withAuthentication" class="space-y-6 pt-6 border-l border-slate-100 dark:border-slate-600">
        <li v-if="hasFaqs">
            <router-link active-class="active" :to="{name:'Help'}" @click.native="handleCallback" class="item">
                Hilfe
            </router-link>
        </li>
        <li v-if="withAuthentication">
            <router-link v-if="isAuth" active-class="active" :to="{name:'Account'}" @click.native="handleCallback" class="item">Profil</router-link>
            <span v-else class="item !inline-flex cursor-pointer" @click="blockedFeature(true)">
                Profil
                <LockedIcon :svg-classes="['w-5','h-5','fill-current','ml-2']" icon-classes="h-5 w-5 ml-2 text-gray-400"></LockedIcon>
            </span>
        </li>
        <li v-if="withAuthentication && isAuth && logout_url">
            <button @click="handleLogout" class="item">
                Ausloggen
                <Spinner v-if="processing" classes="inline ml-2 w-4 h-4"></Spinner>
            </button>
        </li>
        <li v-if="withAuthentication && !isAuth">
            <a v-if="useSSO" :href="login_url" @click="handleLogin" class="item">Login</a>
            <router-link v-else :to="{name:'Login'}" class="item" @click.native="handleCallback" active-class="active">Login</router-link>
        </li>
    </ul>
    <Paywall v-if="paywall.enabled && featureBlocked" class="p-4 mt-6 rounded-lg bg-gray-100 text-gray-800 dark:text-gray-400 dark:bg-gray-800" role="alert"></Paywall>
    <div v-else-if="featureBlocked" class="p-4 mt-6 rounded-lg bg-green-100 dark:bg-green-200 contrast:!bg-orange-100 text-green-900 contrast:!text-orange-900" role="alert">
        <div class="flex items-center mb-3">
            <LockedIcon :svg-classes="['w-5','h-5','fill-current','mr-2']" icon-classes="h-5 w-5 mr-2"></LockedIcon>
            <span class="text-sm font-semibold">Bitte melde{{ $addressingUser(' dich','n Sie sich') }} an</span>
        </div>
        <p class="text-sm">
            Um diese Funktion nutzen zu können, {{ $addressingUser('musst du','müssen Sie') }} <a v-if="useSSO" :href="login_url" @click="handleLogin" class="link-intern">eingeloggt</a><router-link v-else :to="{name:'Login'}" class="link-intern" @click.native="handleCallback" active-class="active">eingeloggt</router-link> sein.
        </p>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import axios from "axios"
import { buildRequest, buildURLWithParams } from "../connector-request"
import Icon from "./Icon";
import Spinner from "./Spinner.vue";
import LockedIcon from "./LockedIcon.vue";
import Paywall from "./Paywall.vue";

export default {
    name: "ExtendedNav",
    components: { Paywall, LockedIcon, Spinner, Icon },
    emits: ["callback"],
    computed: {
        isAuth() {
            return this.$store.getters["auth/isAuth"];
        }
    },
    data() {
        return {
            hasFaqs: this.$config.content.faqs,
            withAuthentication: this.$config.common["with_authentication"],
            processing: false,
            featureBlocked: false,
            useSSO: this.$config.sso.custom,
            login_url: this.getSSOLoginUrl(),
            logout_url: this.$config.sso.logout_url,
            logout_method: this.$config.sso.logout_method ?? "get",
            paywall: this.$config.paywall
        }
    },
    mounted() {
        window.addEventListener("feature:blocked", (event) => {
            this.blockedFeature(event.detail.blocked);
        });
    },
    methods: {
        ...mapActions({
            logout: "auth/logout"
        }),
        /**
         *
         * @param status
         */
        blockedFeature(status = true) {
            if(!this.$config.common["with_authentication"]) {
                return;
            }

            this.featureBlocked = status;
        },
        /**
         *
         * @param e
         */
        handleLogin(e) {
            this.handleCallback();
            if(!this.isAuth && this.$config.sso.iframe) {
                e.preventDefault();
                window.dispatchEvent(new CustomEvent("login:modal"));
            }
        },
        handleLogout() {
            this.processing = true;
            axios[this.logout_method](buildURLWithParams(this.$config.sso.logout_url),buildRequest(this.$store.getters["auth/getToken"],this.$config.sso.auth_scheme))
                .finally(() => {
                    this.processing = false;
                    this.logout()
                        .finally(() => {
                            this.handleCallback();
                            router.push({name:"Login"});
                        });
                });
        },
        handleCallback() {
            this.$emit("callback");
        }
    },
    unmounted() {
        window.removeEventListener("feature:blocked", (event) => {
            this.blockedFeature(event.detail.blocked);
        });
    }
}
</script>
