<template>
    <slot v-if="show"></slot>
</template>

<script>
export default {
    name: "AdSlot",
    computed: {
        show() {
            return this.canSee() && (window.innerWidth >= this.screens[this.breakpointMin] ||
                (this.breakpointMax && window.innerWidth <= this.screens[this.breakpointMax]));
        }
    },
    props: {
        slot: {
            type: String,
            required: true
        },
        group: {
            type: String,
            required: false,
            default: "all"
        },
        breakpointMin: {
            type: String,
            required: false,
            default: "xs"
        },
        breakpointMax: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            screens: {
                'xs': 0,
                'sm': 640,
                'md': 768,
                'lg': 1024,
                'xl': 1280,
                '2xl': 1536,
            }
        }
    },
    methods: {
        /**
         *
         * @returns {boolean}
         */
        canSee() {
            if(this.group === "all") {
                return true;
            }
            else if(this.group === "loggedIn" && this.$store.getters["auth/isAuth"]) {
                return true;
            }
            else if(this.group === "loggedOut" && !this.$store.getters["auth/isAuth"]) {
                return true
            }

            return false;
        }
    }
}
</script>