<template>
    <div role="dialog" aria-modal="true" v-if="showModal" class="overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center md:inset-0 h-modal sm:h-full flex transition-all duration-300" id="init">
        <div class="relative px-4 w-full max-w-lg h-full md:h-auto">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal body -->
                <div class="px-5 pt-5 pb-3 sm:pb-5 rounded-t-lg relative">
                    <button aria-label="Fenster schließen" type="button" class="text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-3.5 right-3.5 dark:hover:bg-gray-600 dark:hover:text-white" v-on:click="closeModal">
                        <icon icon="x-mark" classes="w-5 h-5"></icon>
                    </button>
                    <h1 class="mb-3 text-center dark:text-gray-300 font-bold text-xl">So funktioniert Wortwabe</h1>
                    <p class="text-sm sm:text-base mb-2 text-justify dark:text-gray-300">Erstelle{{ $addressingUser(' ','n Sie') }} Wörter aus den 7 Buchstaben in der Wortwabe und erreiche{{ $addressingUser(' ','n Sie') }} {{ steps.slice(-1)[0] }} Punkte.</p>
                    <ul class="text-sm sm:text-base mb-3 space-y-1 list-disc pl-4 dark:text-gray-300">
                        <li>Wörter müssen <strong>mindestens {{ config.setup.game?.min_letters }} Buchstaben</strong> enthalten</li>
                        <li>Wörter müssen den <strong>mittleren Buchstaben</strong> enthalten</li>
                        <li><strong>Buchstaben</strong> können <strong>mehrfach</strong> vorkommen</li>
                        <li>Keine Schimpfwörter, keine Eigennamen und keine regionalen Wörter</li>
                        <li>Substantive in der Grundform (Vogel, Vögel, <i class="line-through">Vogels</i>); Verben in der Nennform (sehen, <i class="line-through">sah</i>)</li>
                    </ul>
                    <h2 class="mb-3 text-center dark:text-gray-300 font-bold text-base">Punkte pro Wort</h2>
                    <ul class="text-sm sm:text-base space-y-1 list-disc pl-4 dark:text-gray-300">
                        <li><strong>{{ config.setup.game.points_min }} Punkt</strong> für Wörter mit {{ (config.setup.game?.points_letters-1) }} Buchstaben</li>
                        <li>Längere Wörter geben <strong>1 Punkt je Buchstabe</strong></li>
                        <li>Jedes Puzzle besteht aus mindestens einem Isogramm, das jeden Buchstaben ein Mal enthält.
                            Für die Entdeckung gibt es <strong>10 Punkte</strong>.</li>
                    </ul>
                </div>
                <!-- Modal footer -->
                <div class="px-5 py-3 flex items-center justify-between sm:py-5 bg-gray-200 rounded-b-lg border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:border-t">
                    <ul class="text-sm sm:text-base flex-none list-inside text-gray-800 dark:text-gray-300">
                        <li>
                            <icon icon="cake" classes="h-5 w-5 inline-flex -mt-1 mr-1"></icon>
                            Jeden Tag ein neues Isogramm
                        </li>
                        <li class="my-2 sm:my-3" v-if="config.setup?.features?.archive && config.setup?.game['max_archive_days']">
                            <icon icon="calendar-days" classes="h-5 w-5 inline-flex -mt-1 mr-1"></icon>
                            {{ config.setup?.game["max_archive_days"] }}-Tage-Archiv
                        </li>
                        <li class="my-2 sm:my-3" v-if="config.common['with_authentication']">
                            <icon icon="cloud-arrow-down" classes="h-5 w-5 inline-flex -mt-1 mr-1"></icon>
                            Spielstand-Speicher
                        </li>
                    </ul>
                    <div class="text-center">
                        <button class="btn btn-primary" v-on:click="closeModal">Starten</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="this.showModal" class="bg-gray-900 bg-opacity-50 fixed inset-0 z-40 transition-all"></div>
</template>
<script>
import { config } from "../config"
import Icon from "../vendor/publisher/components/Icon.vue";

export default {
    data() {
        return {
            showModal: false,
            android: navigator.userAgent.match(/android/i) && this.$config.common["android_app_store_url"],
            config: this.$config,
            steps: Object.values(this.$config.setup.game.achievements)
        }
    },
    created() {
        if(!this.$store.getters.dataExists && this.$route["name"] === "Home") {
            this.showModal = true;
            this.$store.commit("setExistingData",true);
            document.getElementById("app").addEventListener("click",this.clickOverlay,true);
        }
    },
    components: {
        Icon,
    },
    methods: {
        clickOverlay(e) {
            const modal = document.querySelector("[aria-modal]:not([id=CybotCookiebotDialog])");
            if(modal && !e.target.closest('#' + modal.getAttribute("id") + ' .shadow')) {
                this.closeModal(e);
            }
        },
        closeModal(e) {
            e.preventDefault();
            this.showModal = false;
            document.getElementById("app").removeEventListener("click",this.clickOverlay,true);
        }
    }
}
</script>
