<template>
    <div class="container max-w-4xl mx-auto">
        <div class="static">
            <template v-if="config.content.faq_intro">
                <div class="first-letter:text-7xl first-letter:font-bold first-letter:mr-3 first-letter:float-left my-3 text-justify dark:text-gray-400" v-html="markdownToHtml(config.content.faq_intro)"></div>
                <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
            </template>
            <div id="accordion-help">
                <template v-for="(question, index) in questions">
                    <h2 :id="'q-' + index">
                        <button type="button" v-bind:class="(index+1) < questions.length ? 'border-b border-gray-200 dark:border-gray-700' : null" class="group flex items-center justify-between w-full py-5 font-medium text-left text-gray-500 dark:text-gray-400">
                            <span>{{ question.title }}</span>
                            <icon icon="chevron-down" classes="w-6 h-6 shrink-0 group-[.active]:rotate-180 transition-transform"></icon>
                        </button>
                    </h2>
                    <div :id="'q-body-' + index" class="hidden">
                        <article v-bind:class="(index+1) < questions.length ? 'border-b' : 'border-t'" class="border-gray-200 dark:border-gray-700 py-5">
                            <div v-if="question.body" class="text-justify dark:text-gray-400" v-html="markdownToHtml(question.body, {classes:{ p: 'mb-2 text-justify dark:text-gray-400'}})"></div>
                            <p v-else class="flex text-justify text-gray-500">
                                <icon icon="information-circle" classes="flex-shrink-0 mr-1 w-5 h-5 text-gray-700 dark:text-gray-300"></icon>
                                Aktuell stehen noch keine Inhalte zur Verfügung.
                            </p>
                            <footer class="flex justify-center mt-3" v-if="question.target">
                                <router-link class="btn-default btn-medium inline-flex items-center justify-self-end" :to="{name:question.target}">
                                    {{ question.targetText ? question.targetText : "Mehr Informationen"}}
                                    <icon icon="chevron-right" classes="h-4 w-4 ml-1 -mr-1"></icon>
                                </router-link>
                            </footer>
                        </article>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <component :is="'script'" v-html="JSON.stringify(jsonld)" type="application/ld+json"></component>
</template>

<script setup>
import {watch, inject, onBeforeMount, onMounted, reactive} from "vue"
import { useRoute } from "vue-router"
import { Accordion } from "flowbite"

const route = useRoute();
const config = inject("config");
const jsonld = reactive({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": []
});
const questions = [];
const accordionItems = [];
const handleHash = (hash) => {
    if(hash.indexOf('#') > -1) {
        accordion.open(hash.substring(hash.indexOf('#')+1));
    }
    else {
        const result = accordion._items.find(obj => {
            return obj.active === true
        });
        if(result) {
            accordion.close(result.id);
        }
    }
};

let accordion = null;

watch(route,(to) => {
    handleHash(to.hash);
});


onBeforeMount(() => {
    if (Object.keys(config.content.faqs).length > 0) {
        for (const faq of Object.values(config.content.faqs)) {
            questions.push({
                title: faq.question,
                target: faq.teaser ? faq.slug : null,
                targetText: faq.teaser ? faq.button_label : null,
                body: faq.teaser ?? faq.answer,
            });
        }
    }
});

onMounted(() => {
    const q = window.location.hash ? Number(window.location.hash.substring(window.location.hash.indexOf("-") + 1)) : null;

    questions.forEach((item,index) => {
        accordionItems[index] = {
            id: "q-" + index,
            triggerEl: document.querySelector("#q-" + index + " > button"),
            targetEl: document.querySelector("#q-body-" + index),
            active: q === index,
            iconEl: document.querySelector("#q-" + index + " > button > [data-accordion-icon]")
        }
        jsonld["mainEntity"].push({
            "@type": "Question",
            "name": item.title,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": item.body
            }
        });
    });

    accordion = new Accordion(document.querySelector("#accordion-help"),accordionItems,{
        alwaysOpen: false,
        activeClasses: "font-bold bg-white dark:bg-gray-900 text-gray-900 dark:text-white active",
        inactiveClasses: "font-medium text-gray-500 dark:text-gray-400 inactive",
        onOpen: (items,item) => {
            router.push({name:route.name,hash:"#" + item.id});
        },
        onClose: () => {
            router.push({name:route.name});
        }
    });
});
</script>

<script>
import Icon from "../components/Icon"

export default {
    name: "Help",
    components: {
        Icon
    },
    props: ["faq"],
    mounted() {
        this.linkToRouter();
    },
}
</script>
