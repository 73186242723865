<template>
    <div class="container max-w-4xl mx-auto">
        <div class="p-4 pt-12 text-center grid content-center justify-items-center">
            <icon icon="signal-slash" classes="h-20 w-20 text-gray-600"></icon>
            <p class="text-gray-700 dark:text-gray-400">{{ config.content.addressing_users === "formal" ? "Sie sind" : "Du bist" }} nicht mit dem Internet verbunden.<br>
                Stelle{{ config.content.addressing_users === "formal" ? "n Sie" : "" }} eine Verbindung mit dem Internet her,
                um die aktuellen Spieldaten zu laden.</p>
        </div>
    </div>
</template>
<script>
import Icon from "../components/Icon";

export default {
    name: "Offline",
    components: {Icon},
    data() {
        return {
            config: this.$config
        }
    },
    watch: {
        hasConnection: function(connection) {
            if(connection) {
                this.goOnline();
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        if(navigator.onLine) {
            next({name:"Home"});
        }
        else {
            next();
        }
    }
}
</script>
