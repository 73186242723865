<template>
    <label class="text-sm text-gray-500 dark:text-gray-400">Dein Rang</label>
    <h2 class="mb-2 text-xl font-medium text-gray-900 dark:text-white inline-flex items-center cursor-help" id="achievements-trigger" v-show="rank" @click="toggleAchievements('#achievements-body')">
        {{ rank }}
        <icon icon="information-circle" classes="h-4 w-4 flex-none ml-1 text-gray-400 dark:text-gray-500"></icon>
    </h2>
    <div id="achievements-body" class="p-5 w-full relative bg-gray-100 dark:bg-gray-700 shadow-inner border-t border-b border-gray-200 mb-2 dark:border-gray-700 hidden transform-all duration-1000" data-collapse="true">
        <button @click="toggleAchievements('#achievements-body')" class="absolute z-50 top-3.5 right-4 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
            <icon icon="x-mark" classes="h-6 w-6"></icon>
        </button>
        <div class="grid grid-cols-2">
            <template v-for="(award,_stat) in achievements">
                <div class="text-gray-800 dark:text-gray-400 text-sm mb-0.5 text-right pr-2">Ab Siegesstrecke {{ _stat }}:</div>
                <div class="text-gray-800 dark:text-gray-400 text-sm mb-0.5">{{ stat >= _stat ? award : "***********"}}</div>
            </template>
        </div>
        <p class="pt-3 pb-2 text-gray-800 dark:text-gray-400 text-sm relative pl-6">
            <icon icon="information-circle" classes="h-5 w-5 absolute top-3 left-0 text-gray-400 dark:text-gray-400"></icon>
            Mit jedem erratenen Rätsel steigt {{ informal ? "deine" : "Ihre" }} Siegesstrecke und damit auch {{ informal ? "dein" : "Ihr" }} Rang.
            Sobald ein Rätsel nicht gelöst wird, geht es zurück auf 0.
            Der Rang bezieht sich immer auf die <strong>aktuelle Siegesstrecke</strong>.</p>
        <p class="pt-2 text-gray-800 dark:text-gray-400 text-sm relative pl-6">
            <icon icon="chart-bar" classes="h-5 w-5 absolute top-2 left-0 text-gray-400 dark:text-gray-400"></icon>
            {{ informal ? "Deine" : "Ihre" }} aktuelle Siegestrecke: <strong>{{ stat }}</strong>
        </p>
    </div>
</template>

<script>
import Icon from "./Icon";

export default {
    name: "Achievements",
    components: {Icon},
    props: ["achievements"],
    data() {
        return {
            informal: this.$config.content.addressing_users === "informal",
            rank: "",
            stat: this.$store.getters.getStat("streak"),
        }
    },
    created() {
        this.createRank();
    },
    methods: {
        createRank() {
            const steps = Object.keys(this.achievements);

            let rank = steps[0];

            steps.forEach((key) => {
                if(this.stat >= key || key >= steps[steps.slice(-1)]) {
                    rank = key;
                }
            });

            this.rank = this.achievements[rank];
        },
        /**
         *
         * @param selector
         */
        toggleAchievements(selector) {
            const target = document.querySelector(selector);

            let collapse = target.getAttribute("data-collapse");

            if(collapse === "true") {
                target.setAttribute("data-collapse","false");
                target.classList.remove("hidden");
            }
            else {
                target.setAttribute("data-collapse","true");
                target.classList.add("hidden");
            }
        },
    }
}
</script>