import { Workbox } from "workbox-window"
import { clear } from "./idb"

let wb = null;

if("serviceWorker" in navigator) {
    if(window.Publisher.env === "dev") {
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
            for(let registration of registrations) {
                registration.unregister().then(() => console.log("Service worker successfully unregistered.")).catch(() => console.log("Service worker unregister failed."));
            }
        });
    }
    else {
        wb = new Workbox(process.env.ASSET_URL + "/service-worker.js",{scope: "/"});

        wb.addEventListener("controlling",async () => {
            await clear();
            window.location.reload();
        });

        wb.addEventListener("waiting",async (event) => {
            const registration = await navigator.serviceWorker.ready;
            registration.waiting.postMessage({ type: "SKIP_WAITING" })
        });

        wb.register();

        window.addEventListener("beforeinstallprompt", function(event) {
            event.userChoice.then((result) => {
                document.dispatchEvent(new CustomEvent("serviceWorkerInstallPrompt", {
                    detail: {
                        action: result.outcome,
                        platform: event.platform
                    }
                }));
            });
        });
    }
}

export default wb
