<template>
    <div class="flex items-center">
        <auth-icon></auth-icon>
        <help-icon></help-icon>
        <router-link :to="{name:'Stats'}" title="Statistiken" class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:text-gray-500 dark:hover:bg-gray-700">
            <icon icon="chart-bar" classes="h-6 w-6"></icon>
        </router-link>
        <button aria-label="Menü" @click="openNav" class="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:text-gray-500 dark:hover:bg-gray-700">
            <icon icon="bars-3" classes="h-6 w-6"></icon>
        </button>
    </div>
    <div v-bind:class="[canvas && transition ? 'right-0' : '-right-80',!transition ? 'hidden': null]" id="off-canvas" class="h-full fixed z-50 top-0 overflow-x-hidden transition-all duration-300 bg-white w-80 dark:bg-gray-700">
        <div class="p-6">
            <button aria-label="Menü schließen" class="absolute z-50 top-3.5 right-4 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" @click="closeNav">
                <icon icon="x-mark" classes="h-6 w-6"></icon>
            </button>
            <nav id="nav">
                <ul class="space-y-6 border-l border-slate-100 dark:border-slate-600">
                    <li>
                        <router-link :class="$route.hash !== '#hilfe' && $route.name === 'Home' && !archive.active(!!$route.query.date) ? 'active' : null" :to="{name:'Home'}" @click.native="closeNav" class="item">Spielen</router-link>
                    </li>
                    <li>
                        <router-link replace active-class="active" :to="{name:'Stats'}" @click.native="closeNav(false)" class="item">Statistiken</router-link>
                    </li>
                </ul>
                <archive-nav @callback="closeNav"></archive-nav>
                <extended-nav @callback="closeNav"></extended-nav>
                <span class="block mt-8 mb-4 lg:mb-3 font-semibold text-slate-900 dark:text-slate-200">Einstellungen</span>
                <ul class="space-y-4">
                    <!-- //... -->
                    <li>
                        <label for="toggle-darkmode" @click="config.common['with_authentication'] && !auth && !store.getters.isDarkMode ? handleBlocked() : null" class="inline-flex relative items-center mr-5 cursor-pointer">
                            <input type="checkbox" value="" id="toggle-darkmode" :disabled="config.common['with_authentication'] && !auth && !store.getters.isDarkMode" class="sr-only peer" v-model="darkMode">
                            <span class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brand-500 contrast:peer-checked:bg-orange-400"></span>
                            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" :class="config.common['with_authentication'] && !auth && !store.getters.isDarkMode ? 'text-gray-400 dark:text-gray-500' : 'text-gray-900 dark:text-gray-300'">Dunkles Layout</span>
                            <LockedIcon v-if="config.common['with_authentication'] && !auth && !store.getters.isDarkMode" :svg-classes="['w-5','h-5','fill-current','ml-2']" icon-classes="h-5 w-5 ml-2 text-gray-400"></LockedIcon>
                        </label>
                    </li>
                </ul>
                <div class="text-gray-500 dark:text-gray-400 border-t border-slate-100 pt-4 mt-6 dark:border-slate-600">
                    <Footer></Footer>
                </div>
                <related-publications></related-publications>
            </nav>
        </div>
    </div>
    <div v-bind:aria-hidden="!canvas ? 'true' : 'false'" v-bind:class="[!canvas ? 'bg-opacity-0 invisible' : (integration() === 'dns' ? 'bg-opacity-50 bg-gray-900 visible' : 'bg-white/30 backdrop-blur-sm')]" @click="closeNav" class="fixed z-40 inset-0 transition-all duration-300"></div>
</template>

<script>
import Footer from "./Footer"
import AuthIcon from "../vendor/publisher/components/AuthIcon"
import HelpIcon from "../vendor/publisher/components/HelpIcon"
import ExtendedNav from "../vendor/publisher/components/ExtendedNav.vue";
import RelatedPublications from "../vendor/publisher/components/RelatedPublications.vue";
import Icon from "../vendor/publisher/components/Icon.vue";
import ArchiveNav from "../vendor/publisher/components/ArchiveNav.vue";
import LockedIcon from "../vendor/publisher/components/LockedIcon.vue";

export default {
    name: "Nav",
    components: {
        LockedIcon,
        ArchiveNav,
        AuthIcon,
        ExtendedNav,
        Footer,
        HelpIcon,
        Icon,
        RelatedPublications
    },
    data() {
        return {
            canvas: false,
            transition: true,
            config: this.$config,
            archive: this.$archive,
            store: this.$store
        }
    },
    watch: {
        '$route' (to) {
            this.closeNav(to.name !== 'Stats');
        }
    },
    computed: {
        darkMode: {
            get() {
                return this.$store.getters.isDarkMode;
            },
            set(status) {
                this.switchSetup(status,"darkMode","dark");
            }
        },
        auth() {
            return this.$store.getters["auth/isAuth"];
        }
    },
    methods: {
        /**
         *
         * @returns {*}
         */
        integration() {
            return this.$integration();
        },
        openNav() {
            this.canvas = true;
        },
        /**
         *
         * @param transition
         */
        closeNav(transition = true) {
            window.dispatchEvent(new CustomEvent("feature:blocked",{
                detail: {
                    blocked: false
                }
            }));

            this.transition = transition;
            this.canvas = false;
            setTimeout(() => {
                this.transition = true;
            }, 300);
        },
        /**
         *
         * @param status
         * @param key
         * @param _class
         */
        switchSetup(status,key,_class = null) {
            this.$store.commit("setSetup",{key,_class,status});
            this.$store.dispatch("writeToLocalStorage", {
                key: "pref-" + key.toLowerCase(),
                val: status
            });
        },
        handleBlocked() {
            window.dispatchEvent(new CustomEvent("feature:blocked",{
                detail: {
                    blocked: true
                }
            }));
        },
    },
}
</script>
