import { routes, getMetaRobots, setMeta } from "./router"
import { auth } from "./store/auth"
import { publisher } from "./store/publisher"
import { toast } from "./store/toast"
import merge from "lodash/merge"
import { publisherConfig } from "./config"
import { debug } from "./debug"

import AuthIcon from "./components/AuthIcon"
import ExtendedNav from "./components/ExtendedNav"
import ArchiveNav from "./components/ArchiveNav"
import Toast from "./components/Toast"
import Breadcrumb from "./components/Breadcrumb"
import PrivacySetupIcon from "./components/PrivacySetupIcon"

import CMP from "./mixins/cmp.mix"
import Event from "./mixins/event.mix"
import Markdown from "./mixins/markdown.mix"
import Network from "./mixins/network.mix"
import SSO from "./mixins/sso.mix"

export default {

    install: (app, options) => {

        const { router, store, config } = options;

        if (!config) {
            throw new Error("Please provide a config object.");
        }
        else if (!config.hasOwnProperty("identifier")) {
            throw new Error('Object "config" has no property "identifier".');
        }

        let _ = merge(config,window.Publisher || {});

        if(!_.sso?.custom) {
            _ = merge(_,publisherConfig(_.debug))
        }
        else {
            _ = merge(publisherConfig(_.debug),_)
        }

        window.store = store;
        window.router = router;
        window.Publisher = _;
        app.config.globalProperties.$config = _;

        app.provide("config",_);

        app.component("auth-icon", AuthIcon);
        app.component("extended-nav", ExtendedNav);
        app.component("archive-nav", ArchiveNav);
        app.component("toast", Toast);
        app.component("breadcrumb", Breadcrumb);
        app.component("privacy-setup-icon", PrivacySetupIcon);

        app.mixin(CMP);
        app.mixin(Event);
        app.mixin(Markdown);
        app.mixin(Network);
        app.mixin(SSO);

        if (!store) {
            throw new Error("Please provide vuex store.");
        }

        store.registerModule("auth",auth);
        store.registerModule("publisher",publisher);
        store.registerModule("toast",toast);

        store.commit("setConfig",_);

        if (!router) {
            throw new Error("Please provide router.");
        }

        __webpack_public_path__ = _.asset_url + "/";

        for (let route of [...router.getRoutes(),...routes]) {
            const component = route.name;

            /**
             * Todo: Refactoring necessary
             * Load components async depending on existing routes
             */
            if(component === 'HelpWrapper' && _.content.hasOwnProperty("faqs") && _.content.faqs !== null) {
                route.props = route => ({
                    faq: route.meta?.props?.key
                });

                try {
                    route.children[0].meta.title = _.content.meta['Help']?.title ?? routes['Help'].children[0].meta.title;
                    route.children[0].meta.description = _.content.meta['Help']?.title ?? routes['Help'].children[0].meta.description;
                    route.children[0].meta.robots = getMetaRobots(_.content.meta['Help']?.index ?? true,_.content.meta['Help']?.follow ?? true);
                } catch(error) {
                    debug("The \"Help\" component does not exist!","warn");
                }

                for (let key in _.content.faqs) {
                    if(_.content.faqs[key].teaser) {
                        route.children.push({
                            name: _.content.faqs[key].slug,
                            path: _.content.faqs[key].slug,
                            component: () => import('./pages/Faq.vue'),
                            meta: {
                                title: _.content.faqs[key].meta.title ?? _.content.faqs[key].question,
                                description: _.content.faqs[key].meta.description ?? _.content.faqs[key].teaser,
                                robots: getMetaRobots(true,true),
                                props: { key: key },
                                breadcrumb: [
                                    {
                                        text: "Start",
                                        to: {
                                            name: "Home"
                                        }
                                    },
                                    {
                                        text: "Hilfe",
                                        to: {
                                            name: "Help"
                                        }
                                    },
                                    {
                                        headline: _.content.faqs[key].meta.title ?? _.content.faqs[key].question,
                                    },
                                ]
                            },
                        });
                    }
                }
            }
            else {
                route.meta = setMeta(route,_.content.meta,component)
            }

            router.addRoute(route);

        }

        router.beforeEach((to, from, next) => {
            const routeAfterAuth = function() {
                    if(to.matched.some(record => record.meta.requiresVerify) && store.getters["auth/isVerified"]) {
                        next({name: "Account"});
                    }
                    else if(to.matched.some(record => record.meta.requiresSync) && store.getters["auth/isSynced"]) {
                        next({name: "Home"});
                    }
                    else {
                        next()
                    }
                };

            if(to.matched.some(record => record.meta.requiresAuth)) {
                if(!store.getters["auth/isAuth"] && (_.common.with_authentication || to.meta.availableWithoutAuthentication !== true)) {
                    store.dispatch("auth/checkAuth")
                        .then(result => {
                            if(result === true) {
                                routeAfterAuth()
                            }
                            else {
                                next({
                                    name: "Login",
                                    query: {
                                        next: to.path + location.search
                                    }
                                });
                            }
                        })
                }
                else {
                    routeAfterAuth()
                }
            }
            else {
                routeAfterAuth()
            }
        });

        /**
         *
         * @param e
         */
        app.config.globalProperties.$handleLogin = (e) => {
            if(_.common.with_authentication) {
                if(_.sso.custom && _.sso.iframe) {
                    e.preventDefault();
                    window.dispatchEvent(new CustomEvent("login:modal"));
                }
                else if (!_.sso.custom) {
                    e.preventDefault();
                    router.push({name: "Login"});
                }
            }
        }

        /**
         *
         * @param informal
         * @param formal
         * @returns {string}
         */
        app.config.globalProperties.$addressingUser = (informal = "",formal = "") => {
            if(!_.content?.addressing_users || _.content.addressing_users === "informal") {
                return informal;
            }

            return formal;
        }

        /**
         *
         * @type {{is: (function(function(): boolean=): *), validateDate: ((function(*): (string|string|*))|*)}}
         */
        app.config.globalProperties.$archive = {
            /**
             *
             * @returns {boolean}
             */
            enabled: () => {
                return !!_.setup?.features?.archive
            },
            /**
             *
             * @param condition
             * @returns {boolean}
             */
            called: (condition = false) => {
                return condition;
            },
            /**
             *
             * @param condition
             * @returns {false|boolean|(function(): *)|*|(function(): *)|(function(*): *)|boolean}
             */
            active: (condition = true) => {
                return !!_.setup?.features?.archive && (!_.common.with_authentication || store.getters["auth/isAuth"]) && condition;
            },
            /**
             *
             * @param date
             * @returns {string|*|string}
             */
            validate: (date) => {
                const obj = new Date(date);

                if(!isNaN(obj)) {
                    let day = obj.getDate();
                    day = day < 10 ? "0" + day : day;
                    let month = obj.getMonth() + 1;
                    month = month < 10 ? "0" + month : month;
                    const year = obj.getFullYear();

                    return year + "-" + month + "-" + day;
                }

                return store.getters.today;
            },
            /**
             *
             * @param dateStr
             * @returns {boolean}
             */
            inDateRange: (dateStr) => {
                const date = new Date(dateStr)
                date.setHours(0,0,0,0);

                const today = new Date();
                today.setHours(0,0,0,0); // Today at midnight (23:59:59)

                const subDays = _.setup.game["max_archive_days"] ?? 0;
                const minDate = new Date(today).setDate(today.getDate() - subDays);

                return date >= minDate && date < today;
            },
            /**
             *
             * @param indexStr
             * @param max
             * @returns {boolean}
             */
            inIndexRange: (indexStr,max = 0) => {
                const index = Math.abs(indexStr);

                return index <= max;
            },
            /**
             *
             * @param key
             * @param date
             * @returns {number|*|null}
             */
            getPuzzleNumber: (key,date) => {
                const puzzle = store.getters["getPuzzleById"](key,date);

                if(puzzle) {
                    return puzzle.nr;
                }

                return null;
            }
        }

        app.provide("archive",app.config.globalProperties.$archive);

        /**
         *
         * @returns {any|null|string}
         */
        app.config.globalProperties.$integration = () => {
            if(!_.common["integration"] || _.common.integration === "dns") {
                return "dns";
            }
            else if(!_.common["integration"]) {
                return null;
            }

            return _.common.integration;
        }
    },

};
