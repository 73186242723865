const prefix = "[oliwol] ";

const emoticons = {
    rocket: "🚀",
    astronaut: "🧑‍🚀",
    robots: "🤖",
    construction: "🚧",
};

const config = window.Publisher;
const env = config.env;

const debugging = () => config.debug === 1;

/**
 *
 * @param output
 * @param type
 *
 * List of all types: https://developer.mozilla.org/en-US/docs/Web/API/console?retiredLocale=de
 * @param emoticon
 */
const debug = (output,type = "debug",emoticon = null) => {
    if(debugging()) {
        console[type](prefix + output + (emoticon ? " " + emoticons[emoticon] : ""));
    }
};

/**
 *
 * @param data
 */
const table = (data) => {
    if(debugging()) {
        console.table(data);
    }
};

/**
 *
 * @param label
 * @param collapsed
 * @param end
 */
const group = (end = false,label = null,collapsed = true) => {
    if(debugging()) {
        if(end) {
            console.groupEnd();
        }
        else if(collapsed) {
            console.groupCollapsed(prefix + label);
        }
        else {
            console.group(prefix + label)
        }
    }
};

const initDebug = () => {
    debug("Debugging is active","info","construction");
    debug("Current Environment: " + env,"info");

    if(env === "dev") {
        debug("No service worker is active","info");
        debug("Caching ist not active","info");
    }
    else if(env === "stage") {
        debug("Service worker is active","info");
        debug("Caching ist active","info","rocket");
    }

    group(false,"Common");
    table({
        title: config?.name,
        product: config?.identifier,
        domain: config?.common?.domain,
        "current version": config?.common?.revision,
        "integrate by": config?.common["integration"] ?? "dns",
        "asset url": config?.asset_url,
        "use authentication": config?.common["with_authentication"] ? "yes" : "no"
    });
    group(true);

    group(false,"SSO");
    table({
        enabled: config?.common["with_authentication"] ? "yes" : "no",
        custom: config?.sso?.custom ? "yes" : "no"
    });
    group(true);

    group(false,"Paywall");
    table({
        enabled: config?.paywall?.enabled,
        icon: config?.paywall?.icon ? "exists" : "not exists",
        "landing page": config?.paywall?.url,
        "button text": config?.paywall?.cta,
        "redirect parameter": config?.paywall?.redirect_key,
        "text [markdown]": config?.paywall?.text
    });
    group(true);
};

export { debug, initDebug }