export default {
    methods: {
        /**
         *
         * @param encrypt
         * @returns {number[]}
         */
        decrypt(encrypt = null) {
            let str = encrypt.match(/.{1,2}/g) || [];
            str = str.map((i) => {
                return parseInt(i,10);
            });
            return str;
        },
        /**
         *
         * @param decrypt
         * @param key
         * @returns {*}
         */
        encrypt(decrypt = null,key = null) {
            return decrypt.map(obj => {
                return (key === null ? obj : obj[key]).charCodeAt(0);
            });
        },
        /**
         *
         * @param arrCharKey
         * @returns {string}
         */
        arrayCharKeyToString(arrCharKey = []) {
            const arr = arrCharKey.map(code => {
                return String.fromCharCode(code).toUpperCase();
            });

            return arr.join("");
        },
        /**
         *
         * @param isogram
         * @param letter
         * @returns {*}
         */
        mandatoryLetters(isogram,letter) {
            const index = isogram.indexOf(letter), copy = [...isogram];
            if (index !== -1) {
                copy.splice(index,1);
            }

            return copy;
        },
        onCountdownEnd() {
            setTimeout(router.go(0),1000);
        },
        tilNextDay() {
            const midnight = new Date(new Date().setHours(24,0,0,0));
            return midnight.getTime() - new Date().getTime();
        },
        /**
         *
         * @param array
         * @returns {*}
         */
        shuffle(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }

            return array;
        },
        /**
         *
         * @param array
         * @returns {*}
         */
        getRandomItem(array) {
            return array[Math.floor(Math.random()*array.length)];
        },
        /**
         *
         * @param length
         * @param word
         * @returns {boolean}
         */
        isIsogram(length,word) {
            const letters = word.split("");
            return length === letters.length && length === letters.filter((letter,index,array) => array.indexOf(letter) === index).length;
        },
    }
}
