import { Markdown } from "../markdown"

export default {
    methods: {
        /**
         *
         * @param markdown
         * @param options
         * @returns {string}
         */
        markdownToHtml(markdown,options = {}) {
            const md = new Markdown(markdown,options);
            return md.render();
        },

        /**
         *
         * @param selector
         */
        linkToRouter: (selector = '.md') => {
            document.querySelectorAll(selector).forEach(a => {
                const url = new URL(a.href);
                if(url && url.host === window.location.host && router.resolve(url.pathname)) {
                    a.addEventListener("click", (e) => {
                        e.preventDefault()
                        router.push(router.resolve(url.pathname))
                    })
                }
            });
        },
        /**
         *
         * @param selector
         */
        activeScripts: (selector = '.md-script') => {
            document.querySelectorAll(selector).forEach(src => {
                const url = new URL(src.getAttribute("data-src"));
                if(url) {
                    const script=document.createElement("script");
                    script.src = url.href;
                    src.appendChild(script);
                }
            });
        }
    }
}
