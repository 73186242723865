<template>
    <svg fill="#000000" height="800px" width="800px" viewBox="-3.388 66.953 321.619 322.264" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <path id="path-0" style="fill: none;" d="M 136.762 206.876 C 148.483 220.185 151.513 225.996 155.301 231.336 C 160.507 237.987 164.536 243.094 168.891 247.848 L 169.833 248.898 C 171.149 250.404 172.663 251.637 174.234 252.792"/>
        </defs>
        <g>
            <path d="M84.62,120.674h-48.36c-2.671,0-5.16,1.436-6.495,3.75l-24.18,41.879c-1.336,2.316-1.336,5.188,0,7.5l24.18,41.885 c1.335,2.311,3.824,3.746,6.495,3.746h48.36c2.671,0,5.16-1.436,6.496-3.746l24.18-41.885c1.335-2.313,1.335-5.184,0-7.5 l-24.18-41.879C89.78,122.11,87.29,120.674,84.62,120.674z" style="fill: rgb(231, 229, 228);"/>
            <path d="M180.034,178.586h-48.361c-2.67,0-5.16,1.439-6.495,3.75l-24.181,41.885c-1.334,2.311-1.334,5.186,0,7.498l24.181,41.883 c1.335,2.313,3.825,3.75,6.495,3.75h48.361c2.67,0,5.158-1.438,6.494-3.75l24.179-41.883c1.335-2.313,1.335-5.188,0-7.498 l-24.179-41.885C185.192,180.026,182.704,178.586,180.034,178.586z" style="fill: rgb(251, 191, 36);"/>
            <path d="M186.528,293.168c-1.336-2.315-3.824-3.75-6.494-3.75h-48.361c-2.67,0-5.16,1.435-6.495,3.75l-24.181,41.879 c-1.334,2.314-1.334,5.188,0,7.5l24.181,41.881c1.335,2.313,3.825,3.75,6.495,3.75h48.361c2.67,0,5.158-1.438,6.494-3.75 l24.179-41.881c1.335-2.313,1.335-5.186,0-7.5L186.528,293.168z" style="fill: rgb(231, 229, 228);"/>
            <path d="M 114.721 277.166 L 90.541 235.287 C 89.206 232.973 86.717 231.537 84.047 231.537 L 35.685 231.537 C 33.014 231.537 30.525 232.973 29.189 235.287 L 5.009 277.166 C 3.675 279.481 3.675 282.354 5.009 284.666 L 29.189 326.547 C 30.525 328.86 33.014 330.297 35.685 330.297 L 84.046 330.297 C 86.716 330.297 89.205 328.859 90.54 326.547 L 114.721 284.666 C 116.056 282.354 116.056 279.481 114.721 277.166 Z" style="fill: rgb(231, 229, 228);"/>
            <path d="M 181.269 67.473 L 132.908 67.473 C 130.238 67.473 127.748 68.909 126.413 71.223 L 102.232 113.102 C 100.898 115.416 100.898 118.287 102.232 120.604 L 126.413 162.483 C 127.748 164.797 130.238 166.233 132.908 166.233 L 181.269 166.233 C 183.939 166.233 186.427 164.797 187.763 162.483 L 211.942 120.604 C 213.277 118.288 213.277 115.416 211.942 113.102 L 187.763 71.223 C 186.427 68.909 183.939 67.473 181.269 67.473 Z" style="fill: rgb(231, 229, 228);"/>
            <path d="M229.167,221.899h48.36c2.67,0,5.16-1.434,6.495-3.75l24.181-41.879c1.334-2.314,1.334-5.188,0-7.5l-24.181-41.885 c-1.335-2.309-3.825-3.748-6.495-3.748h-48.36c-2.671,0-5.159,1.44-6.495,3.748l-24.178,41.885c-1.336,2.313-1.336,5.186,0,7.5 l24.178,41.879C224.008,220.465,226.497,221.899,229.167,221.899z" style="fill: rgb(231, 229, 228);"/>
            <path d="M379.436,184.803c-1.336-2.314-3.824-3.75-6.495-3.75h-48.36c-2.671,0-5.16,1.436-6.496,3.75l-24.18,41.879 c-1.334,2.314-1.334,5.186,0,7.5l24.18,41.881c1.336,2.313,3.825,3.75,6.496,3.75h48.36c2.671,0,5.159-1.438,6.495-3.75 l24.18-41.881c1.336-2.314,1.336-5.186,0-7.5L379.436,184.803z" style="fill: rgb(251, 191, 36); visibility: hidden;"/>
            <path d="M379.436,297.295c-1.336-2.309-3.824-3.75-6.495-3.75h-48.36c-2.671,0-5.16,1.441-6.496,3.75l-24.18,41.885 c-1.334,2.309-1.334,5.186,0,7.5l24.18,41.879c1.336,2.315,3.825,3.75,6.496,3.75h48.36c2.671,0,5.159-1.435,6.495-3.75 l24.18-41.879c1.336-2.314,1.336-5.191,0-7.5L379.436,297.295z" style="fill: rgb(251, 191, 36); visibility: hidden;"/>
            <path d="M308.204,280.049l-24.181-41.879c-1.335-2.314-3.825-3.75-6.495-3.75h-48.36c-2.671,0-5.159,1.436-6.495,3.75 l-24.178,41.879c-1.336,2.315-1.336,5.188,0,7.5l24.178,41.881c1.336,2.313,3.824,3.75,6.495,3.75h48.36 c2.67,0,5.16-1.438,6.495-3.75l24.181-41.881C309.538,285.237,309.538,282.364,308.204,280.049z" style="fill: rgb(231, 229, 228);"/>
            <path d="M308.204,57.94l-24.181-41.881c-1.335-2.313-3.825-3.75-6.495-3.75h-48.36c-2.671,0-5.159,1.438-6.495,3.75L198.495,57.94 c-1.336,2.313-1.336,5.189,0,7.5l24.178,41.883c1.336,2.311,3.824,3.75,6.495,3.75h48.36c2.67,0,5.16-1.439,6.495-3.75 l24.181-41.883C309.538,63.129,309.538,60.252,308.204,57.94z" style="fill: rgb(251, 191, 36); visibility: hidden;"/>
            <path d="M379.436,70.639c-1.336-2.313-3.824-3.75-6.495-3.75h-48.36c-2.671,0-5.16,1.438-6.496,3.75l-24.18,41.885 c-1.334,2.309-1.334,5.186,0,7.496l24.18,41.883c1.336,2.314,3.825,3.752,6.496,3.752h48.36c2.671,0,5.159-1.438,6.495-3.752 l24.18-41.883c1.336-2.311,1.336-5.188,0-7.496L379.436,70.639z" style="fill: rgb(251, 191, 36); pointer-events: none; visibility: hidden;"/>
        </g>
    </svg>
</template>
