<template>
    <div class="container max-w-4xl mx-auto">
        <div class="static">
            <div class="flex p-4 mb-4 bg-green-100 border-t-4 border-green-500 contrast:border-orange-300 contrast:bg-orange-100" role="alert">
                <icon icon="information-circle" classes="flex-shrink-0 w-5 h-5 text-green-700 contrast:text-orange-500"></icon>
                <div class="ml-3 text-sm font-medium text-green-700 contrast:text-orange-500">
                    {{ $addressingUser('Du hast dich','Sie haben sich') }} erfolgreich registriert.
                </div>
            </div>
            <ol class="relative border-l border-gray-200 dark:border-gray-700">
                <li class="mb-10 ml-6">
                    <span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-gray-200 rounded-full ring-8 ring-white dark:bg-gray-600 dark:ring-gray-900">
                        <icon icon="cursor-arrow-rays" classes="w-4 h-4 text-gray-600 dark:text-gray-300"></icon>
                    </span>
                    <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">Bestätige{{ $addressingUser(' deine','n Sie Ihre') }} Email-Adresse</h3>
                    <p class="mb-4 text-base font-normal text-gray-500 text-justify dark:text-gray-400">{{ $addressingUser('Du hast','Sie haben') }} gerade eine Email mit einem Bestätigungslink erhalten. Schaue{{ $addressingUser('','n Sie') }} zur Sicherheit auch in {{ $addressingUser('deinem','Ihrem') }} Spam-Ordner nach.</p>
                    <SendVerification></SendVerification>
                </li>
                <li class="mb-10 ml-6">
                    <span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-gray-200 rounded-full ring-8 ring-white dark:bg-gray-600 dark:ring-gray-900">
                        <icon icon="circle-stack" classes="w-4 h-4 text-gray-600 dark:text-gray-300"></icon>
                    </span>
                    <h3 class="mb-1 text-lg font-semibold text-gray-900 dark:text-white">{{ $addressingUser('Deine','Ihre') }} Spieldaten sind jetzt sicher</h3>
                    <p class="text-base font-normal text-gray-500 text-justify dark:text-gray-400">Mit {{ $addressingUser('deiner','Ihrer') }} Registrierung wurden {{ $addressingUser('deine','Ihre') }} bisherigen Spieldaten gesichert.
                        {{ $addressingUser('Du kannst dich','Sie können sich') }} jetzt von überall einloggen und mit {{ $addressingUser('deinem','Ihrem') }} letzten Spielstand fortfahren.
                        Solltest{{ $addressingUser(' du','n Sie') }} es einmal vergessen {{ $addressingUser('dich','sich') }} einzuloggen, werden {{ $addressingUser('deine','Ihre') }} Statistiken beim nächsten Login synchronisiert.</p>
                </li>
                <li class="mb-10 ml-6">
                    <span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-gray-200 rounded-full ring-8 ring-white dark:bg-gray-600 dark:ring-gray-900">
                        <icon icon="user" classes="w-4 h-4 text-gray-600 dark:text-gray-300"></icon>
                    </span>
                    <h3 class="mb-1 text-lg font-semibold text-gray-900 dark:text-white">Schau{{ $addressingUser(' dir dein','n Sie Ihr') }} Profil an</h3>
                    <p class="text-base font-normal text-gray-500 text-justify dark:text-gray-400">{{ $addressingUser('Du hast','Sie haben') }} jetzt ein eigenes Profil.
                        Dort finde{{ $addressingUser('st du deine','n Sie Ihre') }} Daten mit denen {{ $addressingUser('du','Sie') }} angemeldet {{ $addressingUser('bist','sind') }}.
                        Im Profil kannst du auch jederzeit deinen Account löschen oder deinen Spielstand zurücksetzen.</p>
                </li>
                <li class="mb-10 ml-6">
                    <span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-gray-200 rounded-full ring-8 ring-white dark:bg-gray-600 dark:ring-gray-900">
                        <icon icon="bars-3" classes="w-4 h-4 text-gray-600 dark:text-gray-300"></icon>
                    </span>
                    <h3 class="mb-1 text-lg font-semibold text-gray-900 dark:text-white">Noch mehr finde{{ $addressingUser('st du','n Sie') }} im Menü</h3>
                    <p class="text-base font-normal text-gray-500 text-justify dark:text-gray-400">Mit einem Klick auf
                        <icon icon="bars-3" classes="w-4 h-4 text-gray-600 inline dark:text-gray-400"></icon> entdeck{{ $addressingUser('st du','en Sie') }} noch mehr.
                        Vom Menü aus {{ $addressingUser('kannst du','können Sie') }} das Archiv ansteuern,
                        die Hilfe aufrufen oder das Design anpassen.</p>
                </li>
            </ol>
        </div>
    </div>
</template>
<script>

import SendVerification from "../components/SendVerification";
import Icon from "../components/Icon";
export default {
    name: "Welcome",
    components: {
        Icon,
        SendVerification
    },
}
</script>
