<template>
    <div class="container max-w-4xl mx-auto">
        <div class="flex items-center justify-center pt-12 p-4 sm:px-6 lg:px-8">
            <div class="max-w-md w-full space-y-8">
                <div>
                    <h1 class="text-center text-3xl font-extrabold text-gray-900 dark:text-white">
                        Passwort vergessen?
                    </h1>
                    <p class="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
                        {{ $addressingUser('Gib deine','Geben Sie Ihre') }} Mailadresse an und erstelle{{ $addressingUser('','n Sie') }} ein neues Passwort.
                    </p>
                </div>
                <form class="mt-8 space-y-6" action="#" method="POST" @submit="handleSubmit">
                    <div>
                        <label for="email-address" class="sr-only">Email-Adresse</label>
                        <div class="relative">
                            <div class="flex absolute z-10 inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <icon icon="envelope" classes="w-5 h-5 text-gray-500"></icon>
                            </div>
                            <input id="email-address" name="email" type="email" v-model="email" autofocus autocomplete="email" required class="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Email-Adresse">
                        </div>
                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">{{ $addressingUser('Du erhältst','Sie erhalten') }} einen Link an {{ $addressingUser('deine','Ihre') }} Email-Adresse, damit {{ $addressingUser('du dein','Sie Ihr') }} Passwort wiederherstellen {{ $addressingUser('kannst','können') }}.</p>
                    </div>
                    <button type="submit" class="group relative w-full flex btn-primary">
                        <span v-if="!processing" class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <icon icon="lock-closed" classes="h-5 w-5 text-brand-contrast group-hover:text-brand-500 contrast:text-orange-300 contrast:group-hover:text-orange-500"></icon>
                        </span>
                        <span v-else class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <Spinner classes="h-5 w-5"></Spinner>
                        </span>
                        Link anfordern
                    </button>
                    <p class="mt-4 text-center text-sm text-gray-600" v-if="!$store.getters['auth/isAuth']">
                        <router-link :to="{name: 'Login'}" class="font-medium link-intern">
                            Zurück zum Login
                        </router-link>
                    </p>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "../components/Icon";
import Spinner from "../components/Spinner.vue";

export default {
    name: "ForgotPassword",
    components: {Spinner, Icon},
    data() {
        return {
            email: "",
            processing: false
        }
    },
    methods: {
        /**
         *
         * @param e
         */
        handleSubmit(e) {
            e.preventDefault()
            if(this.email.length > 0) {
                this.processing = true;
                this.$axios.post(this.$config.sso.forgot_password_url, {
                    email: this.email
                })
                    .then(response => {
                        this.$store.dispatch("showToast",{msg:response.data.message,type:"success"});
                    })
                    .finally(() => {
                        this.processing = false;
                        this.email = "";
                    })
                    .catch(error => {
                        if (error.response) {
                            this.error = error.response.data.message;
                        }
                        else if (error.request) {
                            this.error = error.request;
                        }
                        else {
                            this.error = error.message;
                        }

                        this.$store.dispatch("showToast",{msg:this.error,type:"success"});
                    });
            }
        }
    },
}
</script>
