<template>
    <nav v-if="breadcrumb" class="flex px-4 py-3 text-gray-700 border-b border-gray-200 bg-gray-50 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
        <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="inline-flex items-center space-x-1 md:space-x-3 overflow-x-hidden">
            <template v-for="(item,index) in breadcrumb">
                <li v-if="index === 0 && item.to" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="inline-flex items-center">
                    <router-link :to="item.to" itemprop="item" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                        <icon v-if="index === 0" icon="home" classes="w-4 h-4 mr-2"></icon>
                        <span itemprop="name">{{ item.text }}</span>
                    </router-link>
                    <meta itemprop="position" content="1" />
                </li>
                <li v-else v-bind:aria-current="(index+1) === breadcrumb.length ? 'page' : null" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
                    <div class="flex items-center">
                        <icon icon="chevron-right" classes="w-6 h-6 text-gray-400"></icon>
                        <router-link v-if="item.to" :to="item.to" :itemid="$router.resolve({name:item.to.name}).href" itemscope itemtype="https://schema.org/WebPage" itemprop="item" class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                            <span itemprop="name">{{ item.text }}</span>
                        </router-link>
                        <h1 v-else-if="item.headline" itemprop="name" class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400 whitespace-nowrap truncate">
                            {{ item.headline }}
                        </h1>
                        <span v-else itemprop="name" class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400 whitespace-nowrap truncate">
                            {{ item.text }}
                        </span>
                        <meta itemprop="position" v-bind:content="(index+1)" />
                    </div>
                </li>
            </template>
        </ol>
    </nav>
</template>

<script>
import Icon from "./Icon";

export default {
    components: {
        Icon
    },
    computed: {
        breadcrumb() {
            if(this.$route.meta.breadcrumb === undefined) {
                return null;
            }
            else if(typeof this.$route.meta.breadcrumb === "function") {
                return this.$route.meta.breadcrumb.call(this,this.$route);
            }

            return this.$route.meta.breadcrumb;
        },
    },
};
</script>
