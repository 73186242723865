<template>
    <div class="container max-w-4xl mx-auto">
        <div class="flex items-center justify-center pt-8 py-4">
            <div class="max-w-md w-full space-y-8">
                <div>
                    <h1 class="text-center text-3xl font-extrabold text-gray-900 dark:text-white px-4 sm:px-6 lg:px-8">
                        Kostenlos registrieren
                    </h1>
                    <Advantages></Advantages>
                </div>
                <form class="mt-8 space-y-6 px-4 sm:px-6 lg:px-8" action="#" autocomplete="off" method="POST" @submit="handleSubmit">
                    <input type="hidden" name="remember" value="True">
                    <p v-if="$store.getters.getStat('games') > 0" class="relative pl-6 text-sm text-gray-600 dark:text-gray-400">
                        <icon icon="check-badge" classes="h-5 w-5 absolute top-0 left-0 text-gray-400 dark:text-gray-400"></icon>
                        {{ $addressingUser('Dein','Ihr') }} bisheriger Spielstand wird bei der Registrierung übernommen.
                    </p>
                    <div class="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="email-address" class="sr-only">Email-Adresse</label>
                            <input id="email-address" name="email" type="email" v-model="email" autofocus autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Email-Adresse">
                        </div>
                        <div>
                            <label for="password" class="sr-only">Passwort</label>
                            <input minlength="5" id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-brand-500 contrast:focus:ring-orange-500 focus:border-brand-500 contrast:focus:border-orange-500 focus:z-10 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Passwort">
                        </div>
                    </div>
                    <div>
                        <label for="toggle-notification" class="inline-flex relative mr-5 cursor-pointer">
                            <input type="checkbox" name="notification" id="toggle-notification" v-model="notification" class="sr-only peer">
                            <span class="flex-none w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brand-500 contrast:peer-checked:bg-orange-400"></span>
                            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Möchte{{ $addressingUser('st du','n Sie') }} per Mail über neue Rätsel oder Funktionen informiert werden?</span>
                        </label>
                    </div>
                    <div>
                        <button type="submit" class="group relative w-full flex btn-primary">
                            <span v-if="!processing" class="absolute left-0 inset-y-0 flex items-center pl-3">
                                <icon icon="lock-closed" classes="h-5 w-5 text-brand-contrast group-hover:text-brand-500 contrast:text-orange-300 contrast:group-hover:text-orange-500"></icon>
                            </span>
                            <span v-else class="absolute left-0 inset-y-0 flex items-center pl-3">
                                <Spinner classes="h-5 w-5"></Spinner>
                            </span>
                            Kostenlos registrieren
                        </button>
                        <p class="mt-4 text-center text-sm text-gray-600">{{ $addressingUser('Du bist','Sie sind') }} bereits registriert?
                            <router-link :to="{name: 'Login'}" class="font-medium link-intern">
                                Hier einloggen
                            </router-link>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import Advantages from "../components/Advantages"
import {buildRequest} from "../connector-request";
import Icon from "../components/Icon";
import Spinner from "../components/Spinner.vue";

export default {
    name: "Register",
    components: {
        Spinner,
        Icon,
        Advantages
    },
    data() {
        return {
            email: "",
            password: "",
            notification: false,
            processing: false
        }
    },
    watch: {
        '$store.getters["auth/isAuth"]' (status) {
            if(status) {
                router.replace({
                    name:"Home"
                });
            }
        },
    },
    methods: {
        ...mapActions({
            register: "auth/signup"
        }),
        handleSubmit(e) {
            e.preventDefault();
            const config = this.$store.getters.getConfig;

            if (!config.hasOwnProperty("identifier")) {
                return;
            }

            if (this.password.length > 0 && this.email.length > 0) {
                this.processing = true;
                axios.post(this.$config.sso.register_url, {
                    email: this.email,
                    password: this.password,
                    notification: this.notification,
                    stats: localStorage.getItem(config.identifier + "-user-stats"),
                    games: localStorage.getItem(config.identifier + "-user-games")
                },buildRequest(null,null,false))
                    .then(result => {
                        this.register(result.data).then(() => {
                            this.event("Register");
                            router.push({
                                name: "Welcome"
                            });
                        });
                    })
                    .finally(() => {
                        this.processing = false;
                    })
                    .catch(error => {
                        if (error.response) {
                            this.error = error.response.data.message;
                        }
                        else if (error.request) {
                            this.error = error.request;
                        }
                        else {
                            this.error = error.message;
                        }

                        this.$store.dispatch("showToast",{
                            msg: this.error
                        });
                    });
            }
        },
    },
    beforeRouteEnter() {
        if(store.getters["auth/isAuth"] || store.getters.getConfig.sso.custom || !store.getters.getConfig.common.with_authentication) {
            router.replace({
                name:"Home"
            });
        }
    },
}
</script>
