<template>
    <ul v-if="advantages" class="mt-2 py-4 px-4 space-y-2 sm:px-6 lg:px-8 bg-stone-50 border-t border-b border-slate-300 flex-none list-inside text-gray-800 dark:text-gray-400 dark:bg-gray-900 dark:border-none">
        <li class="relative pl-7 text-justify" v-for="advantage in advantages">
            <component v-if="advantage.icon" :is="heroIcons[advantage.icon + 'Icon']" class="h-5 w-5 absolute top-0 left-0" />
            <div class="inline" v-html="markdown(advantage.text)"></div>
        </li>
    </ul>
</template>

<script>
import * as heroIcons from "@publisher_node_modules/@heroicons/vue/24/outline"

export default {
    name: "Advantages",
    data() {
        return {
            advantages: this.$config.sso.registration_advantages ?? null,
            heroIcons: heroIcons
        }
    },
    methods: {
        markdown: function (markdown) {
            return this.markdownToHtml(markdown);
        }
    }
}
</script>