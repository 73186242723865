<template>
    <component area-hidden="true" :is="iconComponent()" :class="classes"></component>
</template>

<script>
import * as heroIconsOutline from "@publisher_node_modules/@heroicons/vue/24/outline"
import * as heroIconsSolid from "@publisher_node_modules/@heroicons/vue/24/solid"

export default {
    name: "Icon",
    data() {
        return {
            outline: heroIconsOutline,
            solid: heroIconsSolid
        }
    },
    props: {
        icon: {
            type: String,
            required: true
        },
        classes: {
            type: String,
            default: "w-6 h-6"
        },
        type: {
            type: String,
            default: "outline"
        }
    },
    methods: {
        /**
         *
         * @returns {*}
         */
        iconComponent() {
            const component = this.camelize(this.icon) + 'Icon';
            return this.type === 'solid' ? this.solid[component] : this.outline[component];
        },
        /**
         *
         * @param string
         * @returns {string}
         */
        ucfirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        /**
         *
         * @param string
         * @returns {*}
         */
        camelize(string) {
            return this.ucfirst(string.replace(/\W+(.)/g, function(match, chr) {
                return chr.toUpperCase();
            }));
        }
    }
}
</script>