<template>
    <a v-if="!config.content[type] && config.content[type + '_url']" :href="config.content[type + '_url']" :class="classes">{{ anchorText[type] }}</a>
    <router-link v-else-if="config.content[type]" :to="{name:type.charAt(0).toUpperCase() + type.slice(1)}" :class="classes">{{ anchorText[type] }}</router-link>
</template>

<script>
export default {
    name: "LegalLink",
    props: ["classes","type"],
    data() {
        return {
            config: this.$config,
            anchorText: {
                "imprint": "Impressum",
                "privacy": "Datenschutz"
            }
        }
    }
}
</script>