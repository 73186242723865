<template>
    <button v-if="hasFunc()" @click="show()" title="Datenschutz-Einstellungen öffnen">
        <icon icon="adjustments-horizontal"></icon>
    </button>
</template>

<script>
import Icon from "./Icon";

export default {
    name: "PrivacySetupIcon",
    components: {Icon},
    methods: {
        show() {
            this.showPrivacySetup();
        },
        hasFunc() {
            return this.hasPrivacyFunc();
        }
    }
}
</script>